import React from 'react';

import { Link } from 'react-router-dom';

import { ErpOrderStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { PurchaseOrderLineDTO } from '@recurrency/core-api-schema/dist/v2/purchaseOrders/index';
import { ColumnType } from 'antd/lib/table';

import { ButtonLink } from 'components/Links';
import { ErpOrderStatusBadge } from 'components/recipes/ErpOrderStatusBadge';
import { Table } from 'components/Table';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { truthy } from 'utils/boolean';
import { formatDate } from 'utils/formatting';
import { routes } from 'utils/routes';
import {
  sortableIdColumn,
  sortableNumberColumn,
  sortableStringColumn,
  sortableDollarWithCentsColumn,
} from 'utils/tables';
import { isTenantErpTypeP21 } from 'utils/tenants';

export const PurchaseOrderLinesTable = ({ lines }: { lines: PurchaseOrderLineDTO[] }) => {
  const { activeTenant } = useGlobalApp();
  const columns: ColumnType<PurchaseOrderLineDTO>[] = [
    sortableIdColumn({
      title: 'Item ID',
      dataIndex: 'itemCode',
      render: (id: string) => <Link to={routes.purchasing.itemDetails(id)}>{id}</Link>,
    }),
    sortableStringColumn({
      title: 'Item',
      dataIndex: 'itemName',
    }),
    sortableStringColumn({
      title: 'Status',
      dataIndex: 'status',
      render: (status: ErpOrderStatus) => <ErpOrderStatusBadge erpOrderStatus={status} />,
    }),
    sortableStringColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
    }),
    sortableNumberColumn({
      title: 'Qty Ordered',
      dataIndex: 'quantityOrdered',
    }),
    sortableNumberColumn({
      title: 'Qty Received',
      dataIndex: 'quantityReceived',
    }),
    sortableNumberColumn({
      title: 'Qty Open',
      dataIndex: 'quantityOpen',
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Price',
      dataIndex: 'unitPrice',
    }),
    sortableDollarWithCentsColumn({
      title: 'Ext. Price',
      dataIndex: 'lineTotal',
    }),
    sortableStringColumn({
      title: 'Due Date',
      dataIndex: 'dueDate',
      render: (date: string | undefined) => formatDate(date),
    }),
    isTenantErpTypeP21(activeTenant.erpType)
      ? sortableStringColumn({
          title: 'Required Date',
          dataIndex: 'requiredDate',
          render: (date: string | undefined) => formatDate(date),
        })
      : null,
    {
      render: ({ itemCode }: { itemCode: string }) => ButtonLink(routes.purchasing.itemDetails(itemCode)),
    },
  ].filter(truthy);

  return <Table columns={columns} data={lines} scroll={{ x: true }} />;
};
