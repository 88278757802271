import React from 'react';

import { css, cx } from '@emotion/css';

import { Modal } from 'components/Modal';

const defaultStyles = css`
  .ant-modal-content {
    width: 98vw;
    max-height: 95vh;
    margin: 20px 0;
    padding: 0 0 20px 0;
    overflow: auto;
  }
`;

export function ViewAllModal({
  title,
  content,
  wrapClassName,
  onClose,
  footer = null,
  width = '100vw',
  extraStyles = '',
}: {
  title?: React.ReactNode | string;
  content: React.ReactChild;
  /**
   * Additional class for div.ant-modal-wrap element to distinguish modals.
   * For clearing focused row by clicking outside behavior.
   */
  wrapClassName?: string;
  onClose: () => void;
  footer?: React.ReactNode;
  width?: string;
  extraStyles?: string;
}) {
  return (
    <Modal
      wrapClassName={wrapClassName}
      visible
      width={width}
      onCancel={onClose}
      title={title}
      footer={footer}
      bodyStyle={{ overflowY: 'scroll' }}
      className={cx(defaultStyles, extraStyles)}
    >
      {content}
    </Modal>
  );
}
