import React, { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { OrderCycleSource, SupplierTargetTypes } from '@recurrency/core-api-schema/dist/common/enums';
import { SupplierLocationSettingsDTO } from '@recurrency/core-api-schema/dist/purchasing/getSupplierLocationSettings';
import { Form, Radio, RadioChangeEvent, Typography } from 'antd';

import { TargetTypeSelectOptions } from 'pages/purchasing/utils';

import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { DividerLine } from 'components/DividerLine';
import { FlexSpace } from 'components/FlexSpace';
import { responsiveFormLayout } from 'components/FormItems';
import { Input } from 'components/Input';
import { SmallLoader } from 'components/Loaders';
import { Modal } from 'components/Modal';
import { PropertyListItem } from 'components/PropertyListItem';
import { RadioGroup } from 'components/Radio';
import { SupplierLocationSettingsAuditLog } from 'components/recipes/AuditLog/SupplierLocationSettingsAuditLog';
import { Select } from 'components/Select';
import { Tabs } from 'components/Tabs';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { EMPTY_VALUE_DASHES } from 'utils/formatting';
import { createSubmissionNotification } from 'utils/submissionNotification';

export interface PlanningSupplierLocationTargetSettings {
  supplierId: string;
  locationId: string;
  targetType: SupplierTargetTypes;
  targetValue: number;
  reviewCycleDays: number;
  orderCycleSource: OrderCycleSource;
  orderCycleDaysOverride?: number;
}

export interface SupplierLocationSettingsModalProps {
  supplierId: string;
  locationId: string;
  onClose: (newSettings?: PlanningSupplierLocationTargetSettings) => void;
  searchIndexReload?: () => void;
}
export function PlanningSupplierLocationSettingsModal({
  supplierId,
  locationId,
  onClose,
  searchIndexReload,
}: SupplierLocationSettingsModalProps) {
  const { activeTenant } = useGlobalApp();
  const [orderCycleSettingsForm] = Form.useForm<SupplierLocationSettingsDTO>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderCycleSource, setOrderCycleSource] = useState<OrderCycleSource>();

  const {
    data: settingsData,
    isLoading,
    setData: setSettingsData,
  } = useCoreApi(schemas.purchasing.getSupplierLocationSettings, {
    queryParams: { supplierId, locationId },
  });

  const clearValues = (data: Partial<SupplierLocationSettingsDTO>, source?: OrderCycleSource) => {
    if (source !== OrderCycleSource.AutoCalculateFromTarget) {
      data.targetValue = undefined;
      data.targetType = undefined;
    }

    if (source !== OrderCycleSource.ReviewCycleDays) {
      data.reviewCycleDays = undefined;
    }

    if (source !== OrderCycleSource.Override) {
      data.orderCycleDaysOverride = undefined;
    }

    return data;
  };

  useEffect(() => {
    if (settingsData) {
      const initialValues = clearValues({ ...settingsData }, settingsData.orderCycleSource);
      orderCycleSettingsForm.setFieldsValue({
        ...initialValues,
        targetType: initialValues.targetType !== SupplierTargetTypes.NotSet ? initialValues.targetType : undefined,
      });
      setOrderCycleSource(settingsData.orderCycleSource);
    }
  }, [settingsData, orderCycleSettingsForm]);

  const handleOrderCycleSourceChange = (e: RadioChangeEvent) => {
    const { value } = e.target;
    orderCycleSettingsForm.setFieldsValue(clearValues(orderCycleSettingsForm.getFieldsValue(), value));
    setOrderCycleSource(value);
  };

  async function handleSubmit(data: SupplierLocationSettingsDTO) {
    setIsSubmitting(true);
    const submitNotification = createSubmissionNotification({
      entityName: 'Order Cycle changes',
      submittingMessage: 'Updating Order Cycle',
      expectedWaitSeconds: 10,
      erpType: activeTenant.erpType,
    });

    try {
      const newSettings: PlanningSupplierLocationTargetSettings = {
        supplierId,
        locationId,
        targetType: data.targetType as SupplierTargetTypes,
        targetValue: Number(data.targetValue),
        reviewCycleDays: Number(data.reviewCycleDays),
        orderCycleSource: data.orderCycleSource as OrderCycleSource,
        orderCycleDaysOverride: data.orderCycleDaysOverride,
      };

      onClose(newSettings);
      await coreApiFetch(schemas.purchasing.patchSupplierLocationSettings, { bodyParams: newSettings });

      searchIndexReload?.();
      submitNotification.success({
        successMessage: `Order Cycle updated for Supplier #${supplierId} at Location #${locationId}`,
      });
      setSettingsData({ ...settingsData, ...newSettings });
    } catch (err) {
      submitNotification.error(err);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Modal
      visible
      title={`Set Order Cycle for Supplier #${supplierId} at Location #${locationId}`}
      onCancel={() => onClose()}
      centered
      width={900}
      footer={
        <>
          <Button key="cancel" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            form="supplierLocationSettingForm"
            htmlType="submit"
            disabled={isLoading}
            loading={isSubmitting}
          >
            Save
          </Button>
        </>
      }
    >
      {isLoading ? (
        <SmallLoader />
      ) : (
        <>
          <FlexSpace
            className={css`
              margin-bottom: 16px;
            `}
          >
            <div>
              Updating the order cycle with any of the options below will change the order cycle for all items for this
              supplier at the specified location. To learn more about Order Cycles click{' '}
              <a
                href="https://help.recurrency.com/hc/en-us/articles/26599606605851-Leveraging-Order-Cycle-in-Planning"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </div>
          </FlexSpace>

          <Container>
            <Form.Provider>
              <Form
                name="supplierLocationSettingForm"
                form={orderCycleSettingsForm}
                onFinish={handleSubmit}
                onError={console.error}
                onFinishFailed={console.error}
                {...responsiveFormLayout}
              >
                <Form.Item
                  name="orderCycleSource"
                  className={css`
                    > .ant-form-item-control {
                      min-width: 100%;
                    }
                    margin-bottom: 0;
                  `}
                >
                  <RadioGroup onChange={handleOrderCycleSourceChange}>
                    <FlexSpace direction="column" gap={20} fullWidth>
                      <Radio value={OrderCycleSource.AutoCalculateFromTarget}>
                        <FlexSpace gap={10} direction="column" fullWidth>
                          <div className={boldText}>Calculate using supplier purchasing target</div>
                          <Typography.Paragraph>
                            Using this option will take the current supplier purchasing target (e.g. dollars, volume)
                            and compare to the past year's worth of purchases (in the selected unit) to estimate how
                            frequently you'd purchase from the supplier on average. This target is typically used to
                            reflect a supplier’s pre-paid freight threshold, minimum order quantity, full container
                            volume, or other purchasing goal.
                          </Typography.Paragraph>
                        </FlexSpace>

                        <FlexSpace gap={1} direction="column" fullWidth>
                          <div className={optionWrapperCss}>
                            <div className={valuesWrapperCss}>
                              <PropertyListItem
                                label="Current Target Value"
                                value={
                                  settingsData?.targetType && settingsData?.targetType !== SupplierTargetTypes.NotSet
                                    ? settingsData?.targetValue
                                    : EMPTY_VALUE_DASHES
                                }
                              />
                              <PropertyListItem
                                label="New Target Value"
                                value={
                                  <Form.Item
                                    className={noMarginCss}
                                    name="targetValue"
                                    rules={[
                                      {
                                        required: orderCycleSource === OrderCycleSource.AutoCalculateFromTarget,
                                        message: 'Target value is required.',
                                      },
                                    ]}
                                  >
                                    <Input
                                      type="number"
                                      min={0}
                                      disabled={orderCycleSource !== OrderCycleSource.AutoCalculateFromTarget}
                                    />
                                  </Form.Item>
                                }
                              />
                            </div>

                            <div className={valuesWrapperCss}>
                              <PropertyListItem
                                label="Current Target Type"
                                value={
                                  settingsData?.targetType && settingsData?.targetType !== SupplierTargetTypes.NotSet
                                    ? settingsData.targetType.toUpperCase()
                                    : EMPTY_VALUE_DASHES
                                }
                              />
                              <PropertyListItem
                                label="New Target Type"
                                value={
                                  <Form.Item
                                    className={noMarginCss}
                                    name="targetType"
                                    rules={[
                                      {
                                        required: orderCycleSource === OrderCycleSource.AutoCalculateFromTarget,
                                        message: 'Target type is required.',
                                      },
                                    ]}
                                  >
                                    <Select
                                      options={TargetTypeSelectOptions}
                                      onClear={() => {}}
                                      allowClear
                                      disabled={orderCycleSource !== OrderCycleSource.AutoCalculateFromTarget}
                                    />
                                  </Form.Item>
                                }
                              />
                            </div>
                          </div>
                        </FlexSpace>
                      </Radio>

                      <Radio value={OrderCycleSource.ReviewCycleDays}>
                        <FlexSpace gap={10} direction="column" fullWidth>
                          <div className={boldText}>Calculate using supplier purchasing review cycle</div>
                          <Typography.Paragraph>
                            Using this option will use the current purchasing review cadence of the supplier to define
                            the order cycle. This is best used if you are purchasing every time you review the supplier.
                            Recurrency will prompt you to review purchases for suppliers based on the review schedule
                            you set.
                          </Typography.Paragraph>
                        </FlexSpace>

                        <div className={valuesWrapperCss}>
                          <PropertyListItem label="Current Review Cycle (days)" value={settingsData?.reviewCycleDays} />
                          <PropertyListItem
                            label="New Review Cycle (days)"
                            value={
                              <Form.Item
                                className={noMarginCss}
                                name="reviewCycleDays"
                                rules={[
                                  {
                                    required: orderCycleSource === OrderCycleSource.ReviewCycleDays,
                                    message: 'Review cycle days required.',
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  min={0}
                                  disabled={orderCycleSource !== OrderCycleSource.ReviewCycleDays}
                                />
                              </Form.Item>
                            }
                          />
                        </div>
                      </Radio>

                      <Radio value={OrderCycleSource.Override}>
                        <FlexSpace gap={10} direction="column" fullWidth>
                          <div className={boldText}>Override for this supplier and location (days)</div>
                          <div
                            className={css`
                              display: flex;
                              width: 100%;
                            `}
                          >
                            <Form.Item
                              className={css`
                                width: 100%;
                              `}
                              name="orderCycleDaysOverride"
                              rules={[
                                {
                                  required: orderCycleSource === OrderCycleSource.Override,
                                  message: 'Order cycle value is required.',
                                },
                              ]}
                            >
                              <Input type="number" min={0} disabled={orderCycleSource !== OrderCycleSource.Override} />
                            </Form.Item>
                          </div>
                        </FlexSpace>
                      </Radio>
                    </FlexSpace>
                  </RadioGroup>
                </Form.Item>
              </Form>
            </Form.Provider>
          </Container>
        </>
      )}
      <DividerLine />
      <Tabs
        tabs={[
          {
            header: 'Audit Log',
            infoTooltip: `All updates for Supplier #${supplierId} at Location #${locationId}`,
            content: <SupplierLocationSettingsAuditLog locationId={locationId} supplierId={supplierId} />,
          },
        ]}
      />
    </Modal>
  );
}

const optionWrapperCss = css`
  display: flex;
  gap: 20px;
  & > * {
    flex: 1;
  }
  width: 75%;
`;

const valuesWrapperCss = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 36%;
`;

const noMarginCss = css`
  margin: 0;
`;

const boldText = css`
  font-weight: bold;
`;
