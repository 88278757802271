import {
  FullReplenishmentPath,
  FullReplenishmentPathDTO,
} from '@recurrency/core-api-schema/dist/itemLocations/getItemLocations';
import { GetReplenishmentRulesLocationDTO } from '@recurrency/core-api-schema/dist/replenishmentRules/getReplenishmentRules';

export function buildTreeWithDownstream(
  node: FullReplenishmentPath,
  replenishmentPathData: FullReplenishmentPathDTO,
): GetReplenishmentRulesLocationDTO {
  if (!node.downstreamLocations?.length) {
    // This is the current location (last node), attach downstream tree here
    const children =
      replenishmentPathData?.downstreamTree?.map((dl) => ({
        locationId: dl.locationId,
        locationName: dl.locationName,
        children: dl.downstreamLocations ? transformDownstreamToChildren(dl.downstreamLocations) : [],
      })) || [];

    return {
      locationId: node.locationId,
      locationName: node.locationName,
      children,
    };
  }

  // Continue traversing down the tree
  return {
    locationId: node.locationId,
    locationName: node.locationName,
    children: [buildTreeWithDownstream(node.downstreamLocations[0], replenishmentPathData)],
  };
}

// Helper function to recursively transform downstream locations into children
function transformDownstreamToChildren(
  downstreamLocations: FullReplenishmentPath[],
): GetReplenishmentRulesLocationDTO[] {
  return downstreamLocations.map((location) => ({
    locationId: location.locationId,
    locationName: location.locationName,
    children: location.downstreamLocations ? transformDownstreamToChildren(location.downstreamLocations) : [],
  }));
}
