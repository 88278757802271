import React from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Menu } from 'antd';

import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';

export interface ResetDropdownMenuItem {
  title: string;
  onClick: () => void;
  hidden?: boolean;
}

interface ResetDropdownProps {
  menuItems: ResetDropdownMenuItem[];
  buttonText?: string;
  buttonSize?: 'small' | 'middle' | 'large';
}

export const ResetDropdown: React.FC<ResetDropdownProps> = ({
  menuItems,
  buttonText = 'Reset',
  buttonSize = 'small',
}) => (
  <Dropdown
    overlay={
      <Menu>
        {menuItems
          .filter((item) => !item.hidden)
          .map((item) => (
            <Menu.Item key={item.title} onClick={item.onClick}>
              {item.title}
            </Menu.Item>
          ))}
      </Menu>
    }
  >
    <Button size={buttonSize}>
      {buttonText} <DownOutlined />
    </Button>
  </Dropdown>
);
