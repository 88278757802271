import React from 'react';

import { css } from '@emotion/css';
import moment from 'moment';
import { theme } from 'theme';

import { FlexSpace } from 'components/FlexSpace';
import { ParsedInput, ParsedInputProps } from 'components/Input/ParsedInput';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { floatOrDefault } from 'utils/units';

export interface Override {
  note?: string;
  updatedBy?: string;
  updatedAt?: string;
  quantity?: number;
  baseQuantity?: number;
  uom?: string;
}

interface InputOverrideProps extends Omit<ParsedInputProps<number>, 'valueParser'> {
  override?: Override;
}

export const OverrideTooltip = ({ override }: { override?: Override }) => {
  const tooltipTitle =
    override && override.updatedBy ? (
      <>
        Overridden {override.baseQuantity ? `from ${override.baseQuantity}` : ''} to {override.quantity} {override.uom}{' '}
        by {override.updatedBy} on {moment(override.updatedAt).format('MMM D YYYY')}.<br />
        {override.note ? `Note: ${override.note}.` : ''}
      </>
    ) : null;

  return tooltipTitle ? (
    <span
      className={css`
        z-index: 1;
        margin-top: 3px;
      `}
    >
      <InfoTooltip title={tooltipTitle} useInfoIcon />
    </span>
  ) : null;
};

export const InputOverride = ({ override, value, ...props }: InputOverrideProps) => {
  const shouldShowInfoTooltip = override && override.updatedBy;

  return (
    <FlexSpace>
      <ParsedInput<number>
        size="small"
        value={value}
        // 0 implies value is exceptional and field gets disabled, allow user to enter decimals
        ignoreInputWhileEditing={['', '0']}
        valueParser={(val) => floatOrDefault(val, 0)}
        className={css`
          min-width: 70px;
          text-align: right;
          background-color: ${value !== override?.quantity ? theme.colors.primary[100] : 'default'};
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          // Set padding and margin when InfoTooltip is rendered
          ${shouldShowInfoTooltip
            ? css`
                margin-right: -35px;
                padding-right: 25px;
              `
            : ''}
        `}
        type="number"
        {...props}
      />
      <OverrideTooltip override={override} />
    </FlexSpace>
  );
};
