import React, { CSSProperties } from 'react';

import InfoCircleOutlined from '@ant-design/icons/lib/icons/InfoCircleOutlined';
import { css, cx } from '@emotion/css';
import { theme } from 'theme';

import { Tooltip } from 'components/Tooltip/Tooltip';

const defaultIconProps = { color: theme.colors.warning[500], marginRight: 8 };
export const IconTooltip = ({
  tooltipText,
  wrapText = false,
  Icon = InfoCircleOutlined,
  iconStyle,
}: {
  tooltipText: string;
  wrapText?: boolean;
  Icon?: React.FunctionComponent<{ style?: React.CSSProperties }>;
  iconStyle?: React.CSSProperties;
}) => {
  const whiteSpaceWrapper: CSSProperties = { whiteSpace: wrapText ? 'normal' : 'nowrap' };
  return (
    <span
      className={cx(
        `IconTooltipContainer`,
        css`
          margin-left: 6px;
          font-weight: bold;
        `,
      )}
    >
      <Tooltip
        title={tooltipText}
        className={css`
          cursor: help;
        `}
        overlayInnerStyle={whiteSpaceWrapper}
      >
        <Icon style={{ ...defaultIconProps, ...iconStyle }} />
      </Tooltip>
    </span>
  );
};
