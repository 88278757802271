import React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { GetReplenishmentRulesLocationDTO } from '@recurrency/core-api-schema/dist/replenishmentRules/getReplenishmentRules';
import { DataNode } from 'antd/lib/tree';

import { Alert } from 'components/Alert';
import { ActionButton } from 'components/Button/ActionButton';
import { Tree } from 'components/Tree';

import { BaseReplenishmentPathsTreeProps } from './EditReplenishmentPathsTree';

const TRUNCATE_LIMIT = 3;

export interface ViewReplenishmentPathsTreeProps extends BaseReplenishmentPathsTreeProps {
  replenishmentPathTree: GetReplenishmentRulesLocationDTO[];
  requirementLocationId?: string;
  onViewAll?: () => void;
  truncate?: boolean;
}

export function convertReplenishmentPathToTree(
  nodes: GetReplenishmentRulesLocationDTO[],
  options: ViewReplenishmentPathsTreeProps,
): DataNode[] {
  const { replenishmentPathTree, requirementLocationId, truncate, onViewAll } = options;

  return [...nodes].map((node) => {
    const isPurchaseLocation = replenishmentPathTree[0]?.locationId === node.locationId;
    const isRequirementLocation = requirementLocationId === node.locationId;

    const showViewAll = truncate && node.children && node.children.length > TRUNCATE_LIMIT;
    const displayedLocations = showViewAll ? node.children?.slice(0, TRUNCATE_LIMIT) : node.children;

    const baseNode = {
      key: `${node.locationId}: ${node.locationName}`,
      title: (
        <span
          className={css`
            padding-right: 20px;
            display: inline;
            position: relative;
            font-weight: ${isRequirementLocation ? 'bold' : 'normal'};
          `}
        >
          <>
            {node.locationId}: {node.locationName}
            {(() => {
              if (isPurchaseLocation && isRequirementLocation) {
                return <span> (Purchase & Requirement Location)</span>;
              }
              if (isRequirementLocation) {
                return <span> (Requirement Location)</span>;
              }
              if (isPurchaseLocation) {
                return <span> (Purchase Location)</span>;
              }
              return null;
            })()}
          </>
        </span>
      ),
    };

    if (showViewAll && node.children) {
      return {
        ...baseNode,
        children: [
          ...(displayedLocations ? convertReplenishmentPathToTree(displayedLocations, options) : []),
          {
            key: 'view-all',
            title: <ActionButton label={`View All (${node.children?.length} total)`} onClick={onViewAll} />,
          },
        ],
      };
    }

    return {
      ...baseNode,
      children:
        displayedLocations && displayedLocations.length > 0
          ? convertReplenishmentPathToTree(displayedLocations, options)
          : [],
    };
  });
}

export function ViewReplenishmentPathsTree({
  searchQuery,
  replenishmentPathTree,
  requirementLocationId,
  defaultExpandAll = false,
  showExpandCollapseButton = true,
  onViewAll,
  truncate = false,
}: ViewReplenishmentPathsTreeProps) {
  const treeData = convertReplenishmentPathToTree(replenishmentPathTree, {
    replenishmentPathTree,
    requirementLocationId,
    truncate,
    onViewAll,
  });

  const isSingleLocation =
    replenishmentPathTree.length === 1 &&
    (!replenishmentPathTree[0].children || replenishmentPathTree[0].children.length === 0);

  const treeContent = (
    <>
      {isSingleLocation && (
        <Alert
          message="This location purchases for itself and doesn't replenish any other locations."
          icon={<InfoCircleOutlined />}
          type="info"
          banner
          className={css`
            margin-bottom: 16px;
          `}
        />
      )}
      <Tree
        searchQuery={searchQuery}
        defaultExpandAll={defaultExpandAll}
        className={css`
          .ant-tree-treenode-disabled {
            display: none;
          }
          .ant-tree-node-content-wrapper::before {
            cursor: text !important;
          }
          .ant-tree-node-content-wrapper {
            cursor: text !important;
          }
        `}
        treeData={treeData}
        setTreeData={() => {}}
        showExpandCollapseButton={showExpandCollapseButton}
        draggable={false}
      />
    </>
  );

  return treeContent;
}
