import { useEffect } from 'react';

import { SpecialOrderFilterOptions, SupplierTargetTypes } from '@recurrency/core-api-schema/dist/common/enums';
import { PurchaseGroupWithTargetDTO } from '@recurrency/core-api-schema/dist/purchaseGroups/getPurchaseGroups';
import { PurchaseTargetDTO } from '@recurrency/core-api-schema/dist/purchasing/getPurchaseTargets';
import { TenantDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';
import { theme } from 'theme';

import { MultiSelectOption } from 'components/AsyncSelect/types';
import { SupplierLocationSettingsModal } from 'components/recipes/SupplierLocationSettingsModal';
import { SelectOption } from 'components/Select';

import { ExtendedUser } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { roundTo2Decimals } from 'utils/formatting';
import { LocalStorageKey, useLocalStorage } from 'utils/localStorage';
import { isObjEmpty, objFilterValues } from 'utils/object';
import { useHashState } from 'utils/routes';

import { SearchFrameHashState, SupplierLocationWTarget } from 'types/hash-state';

export const USAGE_COLOR = theme.colors.neutral[300];
export const INHERITED_COLOR = theme.colors.neutral[400];
export const FORECAST_COLOR = theme.colors.chart.sunsetPurple;
export const BASE_FORECAST_COLOR = theme.colors.chart.malibuOrange;

export const sharedFilters = [
  'location',
  'purchase_class',
  'primary_vendor',
  'primary_supplier',
  'item_group',
  'demand_pattern',
  'predictability_tag',
];
export const specialOrderFilterOptions: MultiSelectOption[] = [
  { label: 'POs With Special Orders', value: SpecialOrderFilterOptions.POsWithSpecialOrders },
  { label: 'POs Without Special Orders', value: SpecialOrderFilterOptions.POsWithoutSpecialOrders },
];

export enum ReplenishmentMethod {
  OPOQ = 'OP/OQ',
  MinMax = 'Min/Max',
  EOQ = 'EOQ',
  UpTo = 'Up To',
}

export function useDemandPlanningFilterSave() {
  const [hashState, updateHashState] = useHashState<SearchFrameHashState>();
  const [state, setState] = useLocalStorage(LocalStorageKey.Demand_Planning_Filters, hashState);
  // On load check if hash state is empty and if so, replace it
  useEffect(() => {
    if (isObjEmpty(hashState)) {
      updateHashState(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Every time hash state changes, update local storage by storing the saved filters and query
  useEffect(() => {
    setState({
      where: objFilterValues(hashState.where || {}, (_, key) => sharedFilters.includes(key)),
      query: hashState.query,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashState]);
}

/** discountPercent = ((originalCost - cost) / original) * 100 */
export const calcDiscountPercent = (originalCost: number, cost: number) =>
  originalCost === 0 ? 0 : roundTo2Decimals(((originalCost - cost) / originalCost) * 100);

export const shouldShowOpOqVariant = (
  activeTenant: TenantDTO,
  activeUser: ExtendedUser,
  replenishmentMethod: string | undefined,
) => replenishmentMethod === ReplenishmentMethod.OPOQ;

export function recordToSupplierLocationWTarget(record: PurchaseTargetDTO): SupplierLocationWTarget {
  return {
    supplierId: record.supplierId,
    supplierName: record.supplierName,
    locationId: record.purchaseLocationId,
    locationName: record.purchaseLocationName,
    vendorId: record.vendorId,
    vendorName: record.vendorName,
    // sapb1 tenants don't yet have target types, so default to dollars so lines page can be browsed
    targetValue: record.targetValue ?? 0,
    targetType: record.targetType ?? SupplierTargetTypes.Dollars,
  };
}

export function groupRecordToSupplierLocationsWTargets(
  group: PurchaseGroupWithTargetDTO & { purchaseTargets: PurchaseTargetDTO[] },
): SupplierLocationWTarget[] {
  const spokeSupplierLocations = group.purchaseTargets.map((target) => ({
    supplierId: group.supplierId,
    supplierName: group.supplierName,
    locationId: target.purchaseLocationId,
    locationName: target.purchaseLocationName,
    vendorId: group.vendorId ?? '',
    vendorName: group.vendorName ?? '',
    purchasingLocationId: group.purchaseLocationId,
    purchasingLocationName: group.purchaseLocationName,
    targetType: target.targetType || SupplierTargetTypes.Dollars,
    targetValue: target.targetValue || 0,
    groupName: group.groupName,
    groupId: group.groupId,
  }));

  const hubSupplierLocation = {
    supplierId: group.supplierId,
    supplierName: group.supplierName,
    locationId: group.purchaseLocationId,
    locationName: group.purchaseLocationName,
    vendorId: group.vendorId ?? '',
    vendorName: group.vendorName ?? '',
    purchasingLocationId: group.purchaseLocationId,
    purchasingLocationName: group.purchaseLocationName,
    targetType: group.targetType || SupplierTargetTypes.Dollars,
    targetValue: group.targetValue || 0,
    groupName: group.groupName,
    groupId: group.groupId,
  };

  return [hubSupplierLocation, ...spokeSupplierLocations];
}

export function getMatchingSupplierAndLocation(groupIds: string[], groups: PurchaseGroupWithTargetDTO[]) {
  const supplierLocationMap = new Map<string, string>();

  for (const groupId of groupIds) {
    const group = groups.find((g) => g.groupId === groupId);

    if (group) {
      // Create a unique key for each combination of supplierId and locationId
      const { supplierId } = group;
      const locationIds = [group.purchaseLocationId, ...(group.spokeLocations?.map((loc) => loc.foreignId) || [])];

      for (const locationId of locationIds) {
        const key = `${supplierId}:${locationId}`;

        if (supplierLocationMap.has(key)) {
          // If the key already exists, we have a match

          const locationName =
            group.purchaseLocationId === locationId
              ? group.purchaseLocationName
              : group.spokeLocations?.find((sl) => sl.foreignId === locationId)?.name;

          return {
            hasMatch: true,
            locationName,
            supplierName: group.supplierName,
            groupName1: group.groupName,
            groupName2: supplierLocationMap.get(key),
          };
        }
        // Otherwise, store the groupId with this unique key
        supplierLocationMap.set(key, group.groupName);
      }
    }
  }

  return { hasMatch: false };
  // return false;
}

export const TargetTypeLabels: Record<SupplierTargetTypes, string> = {
  [SupplierTargetTypes.Dollars]: 'Dollars',
  [SupplierTargetTypes.Units]: 'Units',
  [SupplierTargetTypes.Weight]: 'Weight',
  [SupplierTargetTypes.Volume]: 'Volume',
  [SupplierTargetTypes.NotSet]: 'None',
};

export const TargetTypesFilterOptions: MultiSelectOption[] = Object.entries(TargetTypeLabels).map(([value, label]) => ({
  label,
  value,
}));

export const TargetTypeSelectOptions: SelectOption[] = Object.entries(SupplierTargetTypes)
  .map(([key, value]) => ({
    value,
    label: key.toUpperCase(),
  }))
  .filter((option) => option.value !== SupplierTargetTypes.NotSet);

export const editButtonOnClick = async (supplierId: string, locationId: string, onChange: () => void) => {
  const newSettings = await showAsyncModal(SupplierLocationSettingsModal, {
    supplierId,
    locationId,
  });
  if (newSettings) {
    onChange();
  }
};
