import { TenantDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';

import {
  isTenantCWC,
  isTenantGALarson,
  isTenantGallagherTire,
  isTenantHeitek,
  isTenantRichardsSupply,
  isTenantSafeware,
  isTenantSeafoodSales,
  isTenantMiamiCorp,
  isTenantChariotPlumbingSupply,
  isTenantArnoldPackaging,
  isTenantEOH,
} from 'utils/tenants';

import { getConfig as getArnoldPackagingConfig } from './arnoldpackaging';
import { getConfig as getChariotPlumbingSupplyConfig } from './chariotplumbingsupply';
import { getConfig as getCwcConfig } from './cwc';
import { defaultConfig, TenantConfig } from './default';
import { getConfig as getEohConfig } from './eoh';
import { getConfig as getGAlarsonConfig } from './galarson';
import { getConfig as getGallagherTireConfig } from './gallagherTire';
import { getConfig as getHeitekConfig } from './heitek';
import { getConfig as getMiamiCorpConfig } from './miamicorp';
import { getConfig as getRichardsSupplyConfig } from './richardsSupply';
import { getConfig as getSafewareConfig } from './safeware';
import { getConfig as getSeafoodSalesConfig } from './seafoodsales';

export function getTenantConfiguration(activeTenant: TenantDTO): TenantConfig {
  if (isTenantArnoldPackaging(activeTenant.id)) {
    return getArnoldPackagingConfig();
  }
  if (isTenantHeitek(activeTenant.id)) {
    return getHeitekConfig();
  }
  if (isTenantCWC(activeTenant.id)) {
    return getCwcConfig();
  }
  if (isTenantEOH(activeTenant.id)) {
    return getEohConfig();
  }
  if (isTenantSafeware(activeTenant.id)) {
    return getSafewareConfig();
  }
  if (isTenantRichardsSupply(activeTenant.id)) {
    return getRichardsSupplyConfig();
  }
  if (isTenantGALarson(activeTenant.id)) {
    return getGAlarsonConfig();
  }
  if (isTenantGallagherTire(activeTenant.id)) {
    return getGallagherTireConfig();
  }
  if (isTenantSeafoodSales(activeTenant.id)) {
    return getSeafoodSalesConfig();
  }
  if (isTenantMiamiCorp(activeTenant.id)) {
    return getMiamiCorpConfig();
  }
  if (isTenantChariotPlumbingSupply(activeTenant.id)) {
    return getChariotPlumbingSupplyConfig();
  }
  return defaultConfig;
}
