import React, { useState } from 'react';

import { EnvironmentOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { SearchForecastDTO } from '@recurrency/core-api-schema/dist/search/getSearchForecasts';
import { Empty } from 'antd';
import moment, { Moment } from 'moment';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { ForecastChart, ForecastDataType } from 'components/Charts/ForecastChart';
import { FilterBarBox } from 'components/FilterBarBox';
import { SmallLoader } from 'components/Loaders';
import { Tabs } from 'components/Tabs';

import { useCoreApi } from 'hooks/useApi';

import { truthy } from 'utils/boolean';
import { DateFilter, getDefaultDateFilter } from 'utils/date';
import { splitIdNameStr, splitIfIdNameStr } from 'utils/formatting';

import { IdNameObj } from 'types/legacy-api';

import { DemandUsageLinesTable, UsageLineOverride } from './DemandUsageLinesTable';
import {
  ForecastOverrideTable,
  getMappedUsageAndForecastRecords,
  MappedUsageForecastRecord,
  RecordType,
} from './ForecastOverrideTable';
import { LastUpdatedMomentForecast } from './LastUpdatedMoment';
import { MonthlyUsageTable } from './MonthlyUsageTable';
import { getMergedForecastRecords } from './sidePane/sidePane.utils';

export interface InventoryUsageRecord {
  date: string;
  usage: number;
  inheritedUsage: number;
  unitOfMeasure: string;
}

export const InventoryUsageForecastView = ({
  forecastRecords,
  itemUid,
  locations,
  isLoading,
  onForecastOverridesUpdated,
}: {
  forecastRecords?: SearchForecastDTO[];
  itemUid: string;
  locations?: IdNameObj[];
  isLoading: boolean;
  onForecastOverridesUpdated: () => void;
}) => {
  const [filteredLocationIds, setFilteredLocationIds] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<string>();
  const [dateFilter, setDateFilter] = useState<DateFilter>(getDefaultDateFilter());
  const [forecastOverrideInputs, setForecastOverrideInputs] = useState<MappedUsageForecastRecord[]>([]);
  const [usageLineOverrideInputs, setUsageLineOverrideInputs] = useState<UsageLineOverride[]>([]);

  function onChangeUsageLine(usageLineOverride: UsageLineOverride) {
    setUsageLineOverrideInputs((prevInputs) => {
      const updatedInputs = [...prevInputs];
      const existingIndex = updatedInputs.findIndex((input) => input.usageId === usageLineOverride.usageId);

      if (existingIndex !== -1) {
        updatedInputs[existingIndex] = usageLineOverride;
      } else {
        updatedInputs.push(usageLineOverride);
      }

      return updatedInputs;
    });
  }

  const filteredHits = forecastRecords?.filter((hit) => {
    const hitLocationId = splitIdNameStr(hit.location).foreignId;
    return filteredLocationIds.length > 0 ? filteredLocationIds.includes(hitLocationId) : true;
  });

  const record = getMergedForecastRecords(filteredHits);

  const { data: forecastOverridesData } = useCoreApi(schemas.ml.getDemandForecastOverrides, {
    queryParams: {
      filter: {
        itemIds: [itemUid],
        ...(record && record?.location ? { locationIds: [splitIdNameStr(record?.location).foreignId] } : {}),
      },
    },
  });

  const handleMonthlyUsageLinesAction = (fromDate: Moment, toDate: Moment) => {
    setDateFilter({
      from: fromDate,
      to: toDate,
    });
    setActiveTab('usage-lines');
  };

  function handleChangeForecastOverride(updatedRecord: MappedUsageForecastRecord) {
    const updatedForecastOverrides = [...forecastOverrideInputs];
    const existingRecordIdx = forecastOverrideInputs.findIndex((r) => r.date === updatedRecord.date);

    if (existingRecordIdx > -1) {
      updatedForecastOverrides[existingRecordIdx] = updatedRecord;
    } else {
      updatedForecastOverrides.push(updatedRecord);
    }
    setForecastOverrideInputs(updatedForecastOverrides);
  }

  function handleClearForecastOverrides() {
    const clearedOverrides = usageAndForecastRecords
      .filter((r) => r.type === RecordType.Forecast && !!r.baseQuantity && r.baseQuantity !== r.quantity)
      .map((r) => ({ ...r, quantity: r.baseQuantity! }));
    setForecastOverrideInputs(clearedOverrides);
  }

  const forecastOverrides: MappedUsageForecastRecord[] = (forecastOverridesData?.items || []).map((item) => ({
    date: item.forecastDate,
    quantity: item.overrideQty,
    type: RecordType.Forecast,
    note: item.overrideNote,
    updatedBy: item.updatedByUserName,
    updatedAt: item.updatedAt,
  }));

  const usageAndForecastRecords = getMappedUsageAndForecastRecords(record, forecastOverrides);

  return (
    <>
      {locations && locations.length > 1 && (
        <FilterBarBox>
          <AsyncMultiSelect
            selectProps={{
              options: locations.map((l) => ({
                value: l.foreignId,
                label: l.name,
              })),
            }}
            label="Location"
            queryPlaceholder="Search locations"
            selectedValues={filteredLocationIds}
            onSelectedValuesChange={(values) => setFilteredLocationIds(values)}
            icon={<EnvironmentOutlined />}
          />
        </FilterBarBox>
      )}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '12px' }}>
        <LastUpdatedMomentForecast />
      </div>
      {isLoading || record ? (
        <>
          {record ? (
            <ForecastChart
              data={[
                ...record.historical_dates.map((_, i) => ({
                  date: moment(record.historical_dates[i]).valueOf(),
                  usage: record.historical_demand[i],
                  inherited: record.historical_inherited_demand[i],
                  type: ForecastDataType.Usage,
                  unitOfMeasure: record.unit_of_measure,
                })),
                ...record.forecast_dates.map((_, i) => ({
                  date: moment(record.forecast_dates[i]).valueOf(),
                  baseForecast: record.forecast_demand_base[i],
                  forecast:
                    forecastOverrideInputs.find((fo) => fo.date === record.forecast_dates[i])?.quantity ??
                    record.forecast_demand[i],
                  type: ForecastDataType.Forecast,
                  unitOfMeasure: record.unit_of_measure,
                })),
              ]}
            />
          ) : (
            <SmallLoader />
          )}
          {/* TODO: Add inheritance component once fixed in https://recurrency.atlassian.net/browse/PE-4295 */}
          <Tabs
            activeTab={activeTab}
            onChange={(index) => setActiveTab(index)}
            tabs={[
              {
                header: 'Usage & Forecast',
                content: (
                  <ForecastOverrideTable
                    isLoading={isLoading}
                    record={record}
                    usageAndForecastRecords={usageAndForecastRecords}
                    forecastOverrideInputs={forecastOverrideInputs}
                    onClearForecastOverrides={handleClearForecastOverrides}
                    onForecastChange={handleChangeForecastOverride}
                    onForecastOverridesUpdated={onForecastOverridesUpdated}
                  />
                ),
              },
              record
                ? {
                    header: 'Monthly Usage',
                    content: (
                      <MonthlyUsageTable
                        parentIsLoading={isLoading}
                        itemId={record.item_uid}
                        locationId={splitIdNameStr(record.location).foreignId}
                        onLinesActionClick={handleMonthlyUsageLinesAction}
                      />
                    ),
                  }
                : null,
              {
                header: 'Usage Lines',
                content: (
                  <DemandUsageLinesTable
                    itemUid={itemUid}
                    locationId={splitIfIdNameStr(record?.location)?.foreignId ?? ''}
                    dateFilter={dateFilter}
                    setDateFilter={setDateFilter}
                    usageLineOverrideInputs={usageLineOverrideInputs}
                    onChangeUsageLine={onChangeUsageLine}
                    onClearUsageLines={() => setUsageLineOverrideInputs([])}
                  />
                ),
              },
            ].filter(truthy)}
          />
        </>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};

export function getUsageTableRowsFromForecastRecord(record: SearchForecastDTO | undefined) {
  return record
    ? record.historical_dates
        .map((value, index) => ({
          date: value,
          usage: record.historical_demand[index],
          inheritedUsage: record.historical_inherited_demand[index],
          unitOfMeasure: record.unit_of_measure,
        }))
        .sort((a, b) => (a.date < b.date ? 1 : -1))
    : [];
}
