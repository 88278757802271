import React, { useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TransferThresholdSettings } from '@recurrency/core-api-schema/dist/common/enums';
import {
  LocationSettingsDTO,
  LocationSettingUpdate,
  UpdateLocationSettingsType,
} from '@recurrency/core-api-schema/dist/locations/locationSettings';
import { notification } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { useLocationsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { AsyncButton } from 'components/Button/AsyncButton';
import { Container } from 'components/Container';
import { FlexSpace } from 'components/FlexSpace';
import { FlexSpacer } from 'components/FlexSpacer';
import { PageHeader } from 'components/PageHeader';
import { Select } from 'components/Select';

import { coreApiFetch } from 'utils/api';
import { splitIdNameStr } from 'utils/formatting';
import { asKeyOf, typedColumn } from 'utils/tables';

const TransferThresholdSettingsLabels: Record<TransferThresholdSettings, string> = {
  [TransferThresholdSettings.AboveMax]: 'Above Max',
  [TransferThresholdSettings.AboveMin]: 'Above Min',
  [TransferThresholdSettings.NetStock]: 'Net Stock',
};

export const LocationSettingsPage = () => {
  const [changedSettings, setChangedSettings] = useState<LocationSettingsDTO[]>([]);

  const tableProps = useCoreApiTableProps({
    schema: schemas.locations.getLocationSettings,
  });

  const locationsSelectProps = useLocationsSelectProps({ sortByValueProp: true });

  // combine the data from coreApi and the changed settings
  const tableData = useMemo(() => {
    if (locationsSelectProps.options && tableProps.items) {
      // use the options from the locations select to go through all locations
      return locationsSelectProps.options?.map((option) => {
        // first see if there are any changed settings for this location
        const thresholdValue = changedSettings.find(
          (setting) => setting.locationId === splitIdNameStr(option.value).foreignId,
        )?.transferThreshold;
        // if not, use the value from the coreApi data, or default to net stock
        return {
          locationId: splitIdNameStr(option.value).foreignId,
          transferThreshold:
            thresholdValue ??
            (tableProps.items.find((item) => item.locationId === splitIdNameStr(option.value).foreignId)
              ?.transferThreshold ||
              TransferThresholdSettings.NetStock),
        } as LocationSettingsDTO;
      });
    }
    return [];
  }, [locationsSelectProps.options, tableProps.items, changedSettings]);

  // either update the changed row or add a new changed row
  const onChange = (locationId: string, transferThreshold: TransferThresholdSettings) => {
    const index = changedSettings.findIndex((setting) => setting.locationId === locationId);
    if (index === -1) {
      setChangedSettings((prev) => [...prev, { locationId, transferThreshold }]);
    } else {
      setChangedSettings((prev) => {
        const newSettings = [...prev];
        newSettings[index] = { ...newSettings[index], transferThreshold };
        return newSettings;
      });
    }
  };

  // only save the changed rows to the server
  const onSave = async () => {
    const updateSettings: LocationSettingUpdate[] = changedSettings.map((setting) => ({
      locationId: setting.locationId,
      updateValue: setting.transferThreshold,
    }));

    await coreApiFetch(schemas.locations.setLocationSettings, {
      pathParams: {
        updateType: UpdateLocationSettingsType.TransferThreshold,
      },
      bodyParams: {
        updates: updateSettings,
      },
    });

    notification.success({
      message: 'Settings saved',
    });
  };

  const lineItemColumns: ColumnType<LocationSettingsDTO>[] = [
    typedColumn({
      title: 'Location ID',
      dataIndex: asKeyOf<LocationSettingsDTO>('locationId'),
      render: (val) => (
        <div
          className={css`
            margin-right: 24px;
          `}
        >
          {locationsSelectProps.options?.find((option) => splitIdNameStr(option.value).foreignId === val)?.value ?? val}
        </div>
      ),
    }),
    typedColumn({
      title: 'Transfer Threshold',
      dataIndex: asKeyOf<LocationSettingsDTO>('transferThreshold'),
      render: (val, row: LocationSettingsDTO) => (
        <div
          className={css`
            margin-right: 24px;
          `}
        >
          <Select
            placeholder="Select a transfer threshold"
            className={css`
              width: 100%;
            `}
            options={Object.values(TransferThresholdSettings).map((settingKey: string) => ({
              label: TransferThresholdSettingsLabels[settingKey as TransferThresholdSettings],
              value: settingKey as TransferThresholdSettings,
            }))}
            value={val as TransferThresholdSettings}
            onChange={(newVal) => {
              onChange(row.locationId, newVal);
            }}
          />
        </div>
      ),
    }),
  ];

  return (
    <Container>
      <FlexSpace direction="row">
        <PageHeader title="Location Settings" />
        <FlexSpacer />
        <AsyncButton
          className={css`
            width: fit-content;
          `}
          type="primary"
          onClick={onSave}
          disabled={changedSettings.length === 0}
          spinnerPosition="absolute"
        >
          Save settings
        </AsyncButton>
      </FlexSpace>
      <AsyncTable
        tableProps={{ ...tableProps, items: tableData }}
        columns={lineItemColumns}
        pagination={false}
        size="small"
      />
    </Container>
  );
};
