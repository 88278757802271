import { useMemo } from 'react';

import { PurchaseTargetLineStatus, TransferTargetReviewMethods } from '@recurrency/core-api-schema/dist/common/enums';
import { PurchaseTargetLineDTO } from '@recurrency/core-api-schema/dist/purchasing/getPurchaseTargetLines';

import { MultiSelectOption } from 'components/AsyncSelect/types';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { arrUnique } from 'utils/array';
import { truthy } from 'utils/boolean';
import { formatIdNameObj, pluralize } from 'utils/formatting';
import { useHashState } from 'utils/routes';
import { isSpecialOrderPurchasingEnabled, showSpecialOrderWarning } from 'utils/tenantSettings';

import { PurchaseTargetLinesHashState } from 'types/hash-state';

import {
  GenericTargetLineDTO,
  GenericTargetLineRow,
  getIsGroupBuy,
  PurchasingType,
  PurchasingTypeConfig,
} from '../types';

export const allStatuses = Object.values(PurchaseTargetLineStatus);
export const nonStockStatuses = allStatuses.filter((s) => s !== PurchaseTargetLineStatus.Nonstock);

export function focusedRowClassName(record: GenericTargetLineRow, focusedLine?: GenericTargetLineRow) {
  return [
    'focusable-row',
    record.key === focusedLine?.key ? 'focused-row' : '',
    record.userQtyToOrder + additionalPurchasingQty(record) > 0 ? 'purchasing-row' : '',
  ]
    .filter(truthy)
    .join(' ');
}

export function getUniqueItemGroupOptions(targetLines: GenericTargetLineDTO[]) {
  const options: MultiSelectOption[] = arrUnique(
    targetLines.filter((tl) => !!tl.itemGroupId),
    'itemGroupId',
  ).map((tl) => ({
    value: tl.itemGroupId!,
    label: formatIdNameObj({ foreignId: tl.itemGroupId!, name: tl.itemGroupName ?? '' }),
  }));

  return options;
}

export function getTransferKey(record: PurchaseTargetLineDTO): string {
  return `${record.supplierId}|${record.locationId}|${record.itemId}`;
}

export const daysOfWeekDef = [
  { value: 0, label: 'Sun' },
  { value: 1, label: 'Mon' },
  { value: 2, label: 'Tue' },
  { value: 3, label: 'Wed' },
  { value: 4, label: 'Thu' },
  { value: 5, label: 'Fri' },
  { value: 6, label: 'Sat' },
];

export function reviewScheduleToReadable(
  cycleDays: number,
  scheduleDaysOfWeek: string,
  scheduleWeekCadence: number,
  method: TransferTargetReviewMethods,
  detailed?: boolean,
) {
  const daysOfWeekAbr = daysOfWeekDef.map((day) => day.label);
  if (method === TransferTargetReviewMethods.Cycle) {
    return `Every ${cycleDays} days`;
  }
  const daysOfWeek = scheduleDaysOfWeek.split(',');
  if (detailed) {
    const daysOfWeekNames = daysOfWeek.map((day) => daysOfWeekAbr[parseInt(day, 10)]).join(', ');
    return `${daysOfWeekNames} every ${pluralize(scheduleWeekCadence, 'week', 'weeks', scheduleWeekCadence !== 1)}`;
  }
  if (scheduleWeekCadence === 1) {
    return `${pluralize(daysOfWeek.length, 'day', 'days', true)} per week`;
  }
  return `Every ${scheduleWeekCadence} weeks`;
}

export const usePurchasingStatuses = () => {
  const { activeTenant, activeUser } = useGlobalApp();
  const [hashState] = useHashState<PurchaseTargetLinesHashState>();
  const isGroupBuy = hashState?.groupBuy;
  const shouldShowSpecialOrderWarning = useMemo(
    () => !isGroupBuy && showSpecialOrderWarning(activeTenant),
    [activeTenant, isGroupBuy],
  );
  const shouldCreateSpecialOrderLines = useMemo(
    () => !isGroupBuy && isSpecialOrderPurchasingEnabled(activeTenant, activeUser),
    [activeTenant, activeUser, isGroupBuy],
  );

  const filteredStatuses = useMemo(
    () => allStatuses.filter((s) => s !== PurchaseTargetLineStatus.Special || shouldCreateSpecialOrderLines),
    [shouldCreateSpecialOrderLines],
  );

  const defaultStatusFilters = filteredStatuses.filter((s) => s !== PurchaseTargetLineStatus.Nonstock);

  return {
    filteredStatuses,
    defaultStatusFilters,
    shouldCreateSpecialOrderLines,
    shouldShowSpecialOrderWarning,
  };
};

export const PurchasingTypes: Record<PurchasingType, PurchasingTypeConfig> = {
  [PurchasingType.SpecialOrder]: {
    condition: () => isSpecialOrderPurchasingEnabled() && !getIsGroupBuy(),
    qtyField: 'userQtyForSpecialOrders',
  },
};

/**
 * Purpose of this is to calculate the values for additional purchasing fields, ex: special orders, dropships
 * with respect to settings. Modify PurchasingTypes array above to add a new quantity field.
 *
 * @param line to get purchasing values from
 * @param purchasingTypeFilter optional array of purchasing types to filter by, if null returns everything
 * @returns Sum of purchasing fields that are not qtyToOrder
 */
export function additionalPurchasingQty(line: GenericTargetLineRow, purchasingTypeFilter: PurchasingType[] = []) {
  let validTypes = purchasingTypeFilter.length
    ? purchasingTypeFilter.map((type) => PurchasingTypes[type])
    : Object.values(PurchasingTypes);
  validTypes = validTypes.filter((type) => type.condition());
  return validTypes.reduce((acc, curr) => acc + (Number(line[curr.qtyField]) || 0), 0);
}
