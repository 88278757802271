import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { EntityType, ReplenishmentPathSource } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import {
  GetReplenishmentRulesLocationDTO,
  GetReplenishmentRulesRuleDTO,
} from '@recurrency/core-api-schema/dist/replenishmentRules/getReplenishmentRules';
import { Col, Collapse, Empty, Row, Tooltip } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { ColumnType } from 'antd/lib/table';
import { fuzzyFilter } from 'fuzzbunny';
import { useDebounce } from 'use-debounce';

import { Alert } from 'components/Alert';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { Button } from 'components/Button';
import { ActionButton } from 'components/Button/ActionButton';
import { AsyncButton } from 'components/Button/AsyncButton';
import { Card, CardHeader, CardSection } from 'components/Card';
import { Container } from 'components/Container';
import { FlexSpace } from 'components/FlexSpace';
import { ConfirmModal } from 'components/Modal/ConfirmModal';
import { PageHeader } from 'components/PageHeader';
import { SearchInput } from 'components/SearchInput';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';
import { EllipsisTruncatedLabel } from 'components/Typography/EllpsisTruncatedLabel';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { showAsyncModal } from 'utils/asyncModal';
import { truthy } from 'utils/boolean';
import { captureError } from 'utils/error';
import { capitalizeFirst } from 'utils/formatting';
import { loadActiveTenantSettings } from 'utils/globalAppLoaders';
import { LocalStorageKey, useLocalStorage } from 'utils/localStorage';
import { routes } from 'utils/routes';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { isValidReplenishmentRulesRequestDTO } from 'utils/validation';

import { EditReplenishmentPathsTree } from './EditReplenishmentPathsTree';
import { SaveReplenishmentRuleModal } from './modals/SaveReplenishmentRuleModal';

export const ReplenishmentPathsPage = () => {
  const [locationRule, setLocationRule] = useState<GetReplenishmentRulesRuleDTO>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);
  const [locationSearchQuery, setLocationSearchQuery] = useState<string>('');
  const { activeTenant } = useGlobalApp();

  const rulesTableProps = useCoreApiTableProps({
    schema: schemas.replenishmentRules.getReplenishmentRules,
  });

  let ruleTableItems = rulesTableProps.items.filter((r) => r.entityType !== EntityType.Location);
  if (searchQuery) {
    ruleTableItems = fuzzyFilter(ruleTableItems, debouncedSearchQuery, { fields: ['ruleName'] }).map(
      (result) => result.item,
    );
  }
  const nonLocationRulesTableProps = {
    ...rulesTableProps,
    items: ruleTableItems,
  };

  useEffect(() => {
    const locationRules = rulesTableProps.items.filter((r) => r.entityType === EntityType.Location);
    const newLocationRule: GetReplenishmentRulesRuleDTO | undefined =
      locationRules.length > 0 ? locationRules[0] : undefined;
    if (rulesTableProps.items) setLocationRule(newLocationRule);
  }, [rulesTableProps.items]);

  // Show alert if there are pending settings in the replenishment path source
  const [showPendingSettingsAlert, setShowPendingSettingsAlert] = useState(false);
  const [showDisabledSettingAlert, setShowDisabledSettingAlert] = useState(false);

  useEffect(() => {
    const loadSettingsAndUpdateAlertStatus = async () => {
      const { settings, pendingSettings } = await loadActiveTenantSettings();

      const hasPendingSettings = !!pendingSettings?.[TenantSettingKey.CalculationReplenishmentPathSource];

      const sourceIsErp =
        settings?.[TenantSettingKey.CalculationReplenishmentPathSource] === ReplenishmentPathSource.Erp ||
        pendingSettings[TenantSettingKey.CalculationReplenishmentPathSource] === ReplenishmentPathSource.Erp;

      const pendingSourceIsNotRecurrency =
        pendingSettings[TenantSettingKey.CalculationReplenishmentPathSource] !== ReplenishmentPathSource.Recurrency;

      setShowPendingSettingsAlert(hasPendingSettings);
      setShowDisabledSettingAlert(sourceIsErp && pendingSourceIsNotRecurrency);
    };
    loadSettingsAndUpdateAlertStatus().catch((err) => captureError(err));
  }, []);

  const [isExpanded, setIsExpanded] = useLocalStorage(LocalStorageKey.ReplenishmentPathPane_Expanded, true);

  const replenishmentPathTableColumns: Array<ColumnType<GetReplenishmentRulesRuleDTO>> = [
    {
      title: 'Name',
      dataIndex: 'ruleName',
      render: (ruleName) => <EllipsisTruncatedLabel maxWidth="35ch" label={ruleName} />,
    },
    {
      title: 'Type',
      dataIndex: 'entityType',
      render: (entityType) => capitalizeFirst(entityType),
    },
    {
      title: 'IDs',
      render: (_, rule) => {
        const joinedIds = (rule.entityType === EntityType.Item ? rule.entityDisplayIds : rule.entityIds)
          .filter(truthy)
          .join(', ');

        return <EllipsisTruncatedLabel maxWidth="20ch" label={joinedIds} />;
      },
    },
    {
      title: 'Actions',
      render: (_, rule) => (
        <FlexSpace gap={5}>
          <Tooltip title="Edit Rule">
            <Button
              key="edit-rule-button"
              size="small"
              onClick={() => openSaveReplenishmentRuleModal(rule)}
              spinnerPosition="absolute"
            >
              <EditOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Duplicate Rule">
            <Button
              key="duplicate-rule-button"
              size="small"
              onClick={() =>
                openSaveReplenishmentRuleModal({
                  ...rule,
                  ruleGroupId: undefined,
                  ruleName: `${rule.ruleName} - Copy`,
                })
              }
              spinnerPosition="absolute"
            >
              <CopyOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Delete Rule">
            <AsyncButton
              key="delete-rule-button"
              size="small"
              onClick={() => handleDeleteReplenishmentRule(rule)}
              spinnerPosition="absolute"
            >
              <DeleteOutlined />
            </AsyncButton>
          </Tooltip>
        </FlexSpace>
      ),
    },
  ];

  function setLocationReplenishmentPathTree(newReplenishmentPathTree: GetReplenishmentRulesLocationDTO[]) {
    const newLocationRule = {
      ...locationRule,
      replenishmentPathTree: newReplenishmentPathTree,
      entityIds: getChildrenLocationIds(newReplenishmentPathTree),
    } as GetReplenishmentRulesRuleDTO;

    setLocationRule(newLocationRule);
  }

  function getChildrenLocationIds(nodes: GetReplenishmentRulesLocationDTO[]) {
    const childrenIds: string[] = [];

    function collectChildrenIds(node: GetReplenishmentRulesLocationDTO) {
      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
          childrenIds.push(child.locationId);
          collectChildrenIds(child);
        });
      }
    }

    nodes.forEach((node) => collectChildrenIds(node));
    return childrenIds;
  }

  async function handleSubmitLocationRule() {
    const submitNotification = createSubmissionNotification({
      entityName: 'Replenishment Rule',
      submittingMessage: 'Saving Replenishment Rule',
      expectedWaitSeconds: 5,
    });

    try {
      if (!locationRule) {
        submitNotification.error(new Error('Invalid Replenishment Path Tree.'));
        return;
      }

      if (isValidReplenishmentRulesRequestDTO(locationRule) && locationRule.ruleGroupId) {
        // Updating existing location rule
        await coreApiFetch(schemas.replenishmentRules.updateReplenishmentRule, {
          bodyParams: locationRule,
          pathParams: { ruleGroupId: locationRule.ruleGroupId },
        });
      } else {
        // Creating new location rule
        const { entityType, entityIds, replenishmentPathTree } = locationRule;
        await coreApiFetch(schemas.replenishmentRules.createReplenishmentRule, {
          bodyParams: { ruleName: 'Location Rule', entityType, entityIds, replenishmentPathTree },
        });
      }

      submitNotification.success({ successMessage: 'Replenishment Rule saved', duration: 10 });
      rulesTableProps.reload();
      return;
    } catch (err) {
      submitNotification.error(err);
    }
  }

  async function openSaveReplenishmentRuleModal(rule?: GetReplenishmentRulesRuleDTO) {
    await showAsyncModal(SaveReplenishmentRuleModal, {
      initialFormValues: rule,
      defaultReplenishmentPathTree: !rule ? getDefaultReplenishmentPathTree() : undefined,
      onSubmit: rulesTableProps.reload,
      rulesData: rulesTableProps.items,
    });
  }

  function getDefaultReplenishmentPathTree() {
    const defaultPath: GetReplenishmentRulesLocationDTO[] = [];

    function flatten(item: GetReplenishmentRulesLocationDTO) {
      const { children, ...rest } = item;
      defaultPath.push({ ...rest, children: [] });
      if (children && children.length > 0) children.forEach(flatten);
    }

    locationRule?.replenishmentPathTree.forEach(flatten);
    return defaultPath;
  }

  async function handleDeleteReplenishmentRule(rule: GetReplenishmentRulesRuleDTO) {
    const { ruleGroupId, ruleName } = rule;
    const submitNotification = createSubmissionNotification({
      entityName: 'Replenishment Rule',
      expectedWaitSeconds: 5,
      submittingMessage: 'Deleting Replenishment Rule',
      fireInProgressNotification: false,
    });

    if (!ruleGroupId) submitNotification.error(new Error('Rule Group ID is undefined'));

    try {
      const didUserConfirm = await showAsyncModal(ConfirmModal, {
        title: `Delete rule "${ruleName}"?`,
        content: 'Are you sure you want to delete this rule? This action cannot be undone.',
        okText: 'Delete',
        okType: 'primary',
        width: 450,
      });

      if (!didUserConfirm) return;

      submitNotification.inProgress();
      await coreApiFetch(schemas.replenishmentRules.deleteReplenishmentRule, {
        pathParams: {
          ruleGroupId: ruleGroupId as string,
        },
      });
      submitNotification.success({ successMessage: 'Replenishment rule deleted' });
      rulesTableProps.reload();
    } catch (err) {
      submitNotification.error(err);
    }
  }

  return (
    <Container>
      {showPendingSettingsAlert && (
        <Alert
          className={css`
            margin-top: 24px;
          `}
          icon={<ExclamationCircleOutlined />}
          message="Updated replenishment path settings will update automatically overnight as it requires significant recalculations."
          type="info"
          banner
        />
      )}
      {showDisabledSettingAlert && (
        <Alert
          className={css`
            margin-top: 24px;
          `}
          icon={<ExclamationCircleOutlined />}
          message={
            <>
              Recurrency is currently using replenishment paths as set up in {activeTenant.erpType.toUpperCase()} and
              changes made here will not update in Planning. To define replenishment paths in Recurrency,{' '}
              <Link to={`${routes.settings.settings()}#setting=replenishment-path-source`}>
                change the setting here.
              </Link>
            </>
          }
          type="warning"
          banner
        />
      )}

      <div
        className={css`
          flex-grow: 1;
        `}
      >
        <PageHeader
          title={
            <InfoTooltip placement="bottom" title="Define replenishment paths for Hubs and Spokes within Recurrency">
              Replenishment Paths
            </InfoTooltip>
          }
        />
        <Collapse
          ghost
          onChange={() => setIsExpanded(!isExpanded)}
          defaultActiveKey={isExpanded ? ['replenishment-path-feature-description'] : []}
          className={css`
            padding-top: 0;
            padding-left: 0;
            padding-bottom: 10px;

            .ant-collapse-header {
              font-weight: bold;
            }
          `}
        >
          <CollapsePanel
            key="replenishment-path-feature-description"
            header="Recurrency allows you to define and manage your own replenishment paths with simple, yet powerful rules."
          >
            <p> To set up your replenishment path, follow these steps:</p>
            <ol>
              <li>
                What is your default replenishment path? This defines the standard way inventory moves through your
                locations. If there's an exception for a supplier or item, you can define it later using a rule. For
                example, if you have 5 locations and one is your distribution center, you can drag and drop the other
                locations beneath the hub location, marking them as spokes of the main location.
              </li>

              <li>
                What exceptions are there to your default path? For each exception, add a new rule that specifies the
                suppliers or items it affects and how the inventory should move in that case. For example, if you have 5
                locations with a normal distribution center, but you purchase from one supplier for each of your
                locations, you can define a rule for that.
              </li>
            </ol>

            <p>
              In order for Recurrency to determine the replenishment path for an item, Recurrency will check in order:
            </p>

            <ol>
              <li>If an item rule exists, use the rule to define the path</li>
              <li>Otherwise, if a supplier rule exists for that item, use that path</li>
              <li>If no rules exists, then Recurrency will use the default replenishment path</li>
            </ol>
          </CollapsePanel>
        </Collapse>
      </div>

      <Row gutter={18}>
        <Col span={8}>
          <Card>
            <CardHeader
              title="Default Replenishment Paths"
              description="Default replenishment paths for when there are no rules for an item or supplier"
              useTooltip
            />
            <CardSection>
              <SearchInput
                placeholder="Search locations by name"
                query={locationSearchQuery}
                onQueryChange={setLocationSearchQuery}
              />
            </CardSection>
            <CardSection>
              {locationRule ? (
                <EditReplenishmentPathsTree
                  searchQuery={locationSearchQuery}
                  replenishmentPathTree={locationRule.replenishmentPathTree}
                  setReplenishmentPathTree={setLocationReplenishmentPathTree}
                />
              ) : (
                <LoadingOutlined />
              )}
            </CardSection>
            <CardSection>
              <FlexSpace justify="flex-end">
                <AsyncButton onClick={handleSubmitLocationRule} type="primary">
                  Save Changes
                </AsyncButton>
              </FlexSpace>
            </CardSection>
          </Card>
        </Col>
        <Col span={16}>
          <Card>
            <CardHeader
              title="Replenishment Path Rules"
              description="Create and manage replenishment path rules that apply for specific suppliers or items. Item rules take precedence over supplier rules"
              useTooltip
              headerActions={
                <Button onClick={async () => openSaveReplenishmentRuleModal()} type="primary">
                  <PlusOutlined />
                  Add New Rule
                </Button>
              }
            />
            <CardSection
              className={css`
                display: flex;
                justify-content: flex-end;
              `}
            >
              <SearchInput placeholder="Search rules by name" query={searchQuery} onQueryChange={setSearchQuery} />
            </CardSection>
            <CardSection>
              <AsyncTable<GetReplenishmentRulesRuleDTO>
                tableProps={nonLocationRulesTableProps}
                columns={replenishmentPathTableColumns}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        <div>
                          No rules have been created. Click{' '}
                          <ActionButton
                            className={css`
                              display: inline;
                            `}
                            label="here"
                            onClick={() => openSaveReplenishmentRuleModal()}
                          />{' '}
                          to get started.
                        </div>
                      }
                    />
                  ),
                }}
              />
            </CardSection>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
