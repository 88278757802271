import React from 'react';

import { css } from '@emotion/css';
import { colors } from 'theme/colors';

export function DividerLine({
  marginTop = 20,
  marginBottom = 20,
  color = colors.neutral[300],
}: {
  marginTop?: number;
  marginBottom?: number;
  color?: string;
}) {
  return (
    <div
      className={css`
        margin: ${marginTop}px 0 ${marginBottom}px 0;
        border-top: 1px solid ${color};
      `}
    />
  );
}
