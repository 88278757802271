import React, { useMemo } from 'react';

import { IdNameObj } from '@recurrency/core-api-schema/dist/purchaseOrders/getPurchaseOrderPdfDetails';
import { Checkbox } from 'antd';

import { Button } from 'components/Button';
import { FilterBarBox } from 'components/FilterBarBox';
import { SplitPage } from 'components/SplitPage';
import { Table } from 'components/Table';

import { showAsyncModal } from 'utils/asyncModal';
import { formatNumber, formatUSD, joinIdNameObj } from 'utils/formatting';
import { useHashState } from 'utils/routes';
import { PersistedColumn } from 'utils/tableAndSidePaneSettings/types';
import { asKeyOf, sortDirections } from 'utils/tables';

import { TransferTargetLinesHashState, TransferTargetSummary, UpdatedTransferLine } from 'types/hash-state';

import { CarrierCell } from '../../PurchaseTargetsPage/PurchaseTargetLinesPage/PurchasingFinalizePage/PurchasingFinalizeStepCarrierCell';
import { getTransferInputKey } from '../utils';
import { TransferTargetLinesModal } from './TransferTargetLinesModal';

export function TransferTargetLinesFinalize({
  transferSummaries,
  isLoading,
  lines,
}: {
  transferSummaries: TransferTargetSummary[];
  isLoading: boolean;
  lines: UpdatedTransferLine[];
}) {
  const [hashState, setHashState] = useHashState<TransferTargetLinesHashState>();
  const { transferInputByID = {} } = hashState;
  const approvedRowCount = useMemo(
    () => transferSummaries.filter((summary) => transferInputByID[getTransferInputKey(summary)]?.approved).length,
    [transferInputByID, transferSummaries],
  );

  const handleBulkApproveChange = (checked: boolean) => {
    const newTransferSummariesById = { ...transferInputByID };
    transferSummaries.forEach((transferSummary) => {
      newTransferSummariesById[getTransferInputKey(transferSummary)] = {
        ...transferSummary,
        approved: checked,
      };
    });
    setHashState({ transferInputByID: newTransferSummariesById });
  };

  const handleSingleApproveChange = (record: TransferTargetSummary, checked: boolean) => {
    const newTransferSummariesById = { ...transferInputByID };
    newTransferSummariesById[getTransferInputKey(record)] = { ...record, approved: checked };
    setHashState({ transferInputByID: newTransferSummariesById });
  };

  const handleCarrierChange = (record: TransferTargetSummary, carrier?: IdNameObj) => {
    const newTransferSummariesById = { ...transferInputByID };
    newTransferSummariesById[getTransferInputKey(record)] = { ...record, carrier };
    setHashState({ transferInputByID: newTransferSummariesById });
  };

  const tableColumns: PersistedColumn<TransferTargetSummary>[] = [
    {
      title: 'Source Location',
      dataIndex: asKeyOf<TransferTargetSummary>('sourceLocation'),
      settingKey: 'sourceLocation',
      required: true,
      render: (sourceLocation: IdNameObj) => joinIdNameObj(sourceLocation),
    },
    {
      title: 'Destination Location',
      dataIndex: asKeyOf<TransferTargetSummary>('destinationLocation'),
      settingKey: 'destinationLocation',
      required: true,
      render: (destinationLocation: IdNameObj) => joinIdNameObj(destinationLocation),
    },
    {
      title: 'Transfer Days',
      dataIndex: asKeyOf<TransferTargetSummary>('transferDays'),
      settingKey: 'transferDays',
      optional: true,
      render: (transferDays: number) => formatNumber(transferDays),
    },
    {
      title: 'Total Lines',
      dataIndex: asKeyOf<TransferTargetSummary>('totalLines'),
      settingKey: 'totalLines',
      render: (totalLines: number) => formatNumber(totalLines),
    },
    {
      title: 'Current ($)',
      dataIndex: asKeyOf<TransferTargetSummary>('totalCost'),
      align: 'right' as const,
      settingKey: 'totalCost',
      render: (currentValue: number) => formatUSD(currentValue),
    },
    {
      title: 'Current Weight',
      dataIndex: asKeyOf<TransferTargetSummary>('totalWeight'),
      settingKey: 'totalWeight',
      optional: true,
      render: (currentWeight: number) => formatNumber(currentWeight),
    },
    {
      title: 'Current Volume',
      dataIndex: asKeyOf<TransferTargetSummary>('totalVolume'),
      settingKey: 'totalVolume',
      optional: true,
      render: (currentVolume: number) => formatNumber(currentVolume),
    },
    {
      title: () => (
        <div>
          Approved
          <Checkbox
            style={{ marginLeft: 8 }}
            checked={approvedRowCount > 0 && approvedRowCount === transferSummaries.length}
            indeterminate={approvedRowCount > 0 && approvedRowCount !== transferSummaries.length}
            onChange={(e) => handleBulkApproveChange(e.target.checked)}
          />
        </div>
      ),
      dataIndex: 'approved',
      settingKey: 'approved',
      sortDirections,
      render: (approved, record) => (
        <Checkbox checked={approved} onChange={(e) => handleSingleApproveChange(record, e.target.checked)} />
      ),
    },
    {
      title: 'Carrier',
      dataIndex: 'carrier',
      settingKey: 'carrier',
      width: `250px`,
      sortDirections,
      render: (carrier, record) => (
        <CarrierCell value={carrier} onChange={(newCarrier) => handleCarrierChange(record, newCarrier)} />
      ),
    },
    {
      title: 'Actions',
      width: '100px',
      align: 'left',
      settingKey: 'actions',
      render: (record: TransferTargetSummary) => (
        <Button
          size="small"
          onClick={async () => {
            await showAsyncModal(TransferTargetLinesModal, {
              isLoading,
              transferSummaries,
              lines,
              record,
            });
          }}
        >
          Lines
        </Button>
      ),
    },
  ];

  return (
    <SplitPage
      left={
        <div>
          <FilterBarBox />
          <Table columns={tableColumns} data={transferSummaries} verticalAlign="middle" />
        </div>
      }
      // TODO @brunno-recurrency - Implement the right side transfer overview panel
      right={null}
    />
  );
}
