import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';

import { usePromise, UsePromiseResponse } from 'hooks/usePromise';

import { objOmitKeys } from 'utils/object';

import { SearchIndexItemPartial, SearchIndexShipToPartial, SearchIndexCustomerPartial } from 'types/search-collections';

import { StatefulPromise } from '../statefulPromise';
import { addAccessFilters } from './accessFiltersByIndex';
import {
  getAllRecordsInPostgresCollection,
  searchPostgresCollection,
  searchPostgresCollectionForFacetValues,
} from './postgres';
import { SearchResponse, SearchRequest, FacetValues, GetAllRecordsRequest } from './types';
import {
  getAllRecordsInTypesenseCollection,
  multiSearchTypesenseCollection,
  searchTypesenseCollectionForFacetValues,
} from './typesense';

// Use PG for DPPA pages
const shouldUsePostgres = (indexName: SearchIndexName) =>
  indexName === SearchIndexName.Forecasts ||
  indexName === SearchIndexName.Planning ||
  indexName === SearchIndexName.InventoryStatus ||
  indexName === SearchIndexName.ShipTos ||
  indexName === SearchIndexName.TransferOrders;

/**
 * Search an index returning hits and facet counts, with filters, sorting and pagination
 */
export async function searchIndex<ObjectT>(searchRequest: SearchRequest): Promise<SearchResponse<ObjectT>> {
  if (shouldUsePostgres(searchRequest.indexName)) {
    searchRequest = addAccessFilters(searchRequest);
    return searchPostgresCollection(searchRequest);
  }

  const searchRequests: SearchRequest[] = [searchRequest];

  // handle proper facet counting when there are filters
  // so the filtered field includes all fields, not just the ones that are selected
  // this is the same behaviour as algolia and typesense instant search
  const multipleQueryFacetFieldNames: string[] = [/** first query is always the full query */ ''];
  if (searchRequest.facetFields?.length) {
    if (searchRequest.filters) {
      for (const filterField of Object.keys(searchRequest.filters)) {
        if (searchRequest.facetFields.includes(filterField)) {
          searchRequests.push({
            indexName: searchRequest.indexName,
            facetFields: [filterField],
            filters: objOmitKeys(searchRequest.filters, filterField),
            query: searchRequest.query,
            hitsPerPage: 0,
            page: 0,
            fieldsToRetrieve: [],
          });
          multipleQueryFacetFieldNames.push(filterField);
        }
      }
    }
  }

  const results = await multiSearchIndex(searchRequests);

  // merge the facet counts with the first result that includes all the facet counts
  const firstResult = results[0];
  for (let resultIdx = 1; resultIdx < results.length; ++resultIdx) {
    const fieldName = multipleQueryFacetFieldNames[resultIdx];
    if (
      // fieldName could be a numeric filter, check it's a value filter
      searchRequest.filters?.[fieldName] !== undefined &&
      results[resultIdx].facets?.[fieldName] !== undefined
    ) {
      firstResult.facets = firstResult.facets ?? {};
      firstResult.facets[fieldName] = results[resultIdx].facets[fieldName];
    }
  }

  return firstResult as unknown as SearchResponse<ObjectT>;
}

export function multiSearchIndex(searchRequests: SearchRequest[]): Promise<Array<SearchResponse<Any>>> {
  searchRequests = searchRequests.map(addAccessFilters);
  return multiSearchTypesenseCollection(searchRequests);
}

/**
 * Search for facet values.
 * Used when number of values > 100, and user needs to search for more
 */
export function searchIndexForFacetValues(
  facetField: string,
  facetQuery: string,
  searchRequest: SearchRequest,
): Promise<FacetValues> {
  searchRequest = addAccessFilters(searchRequest);

  if (shouldUsePostgres(searchRequest.indexName)) {
    return searchPostgresCollectionForFacetValues(facetField, facetQuery, searchRequest);
  }

  // omit filter on facetField so we get all values
  if (searchRequest.filters?.[facetField]) {
    delete searchRequest.filters[facetField];
  }
  return searchTypesenseCollectionForFacetValues(facetField, facetQuery, searchRequest);
}

/**
 * Get all records from index, optionally with a filter - for export to xls
 */
export function getAllRecordsFromSearchIndex<RecordT>(request: GetAllRecordsRequest): Promise<RecordT[]> {
  request = addAccessFilters(request);

  if (shouldUsePostgres(request.indexName)) {
    return getAllRecordsInPostgresCollection(request);
  }

  return getAllRecordsInTypesenseCollection(request);
}

export function useSearchIndex<ObjectT>(options: SearchRequest): UsePromiseResponse<SearchResponse<ObjectT>, Error> {
  const result = usePromise(() => searchIndex<ObjectT>(options), [options]);
  return result;
}

// maintain a lookup of itemId -> search item
// so we don't hit the search index twice for the same item
export const searchIndexItemByIdCache = new Map<string, StatefulPromise<SearchIndexItemPartial>>();
export async function getSearchIndexItemByItemId(itemId: string): Promise<SearchIndexItemPartial> {
  if (!searchIndexItemByIdCache.has(itemId)) {
    searchIndexItemByIdCache.set(
      itemId,
      new StatefulPromise(
        searchIndex<SearchIndexItemPartial>({
          indexName: SearchIndexName.Items,
          filters: { item_id: [itemId] },
          fieldsToRetrieve: [
            'item_id',
            'inv_mast_uid',
            'item_desc',
            'extended_desc',
            'buyable_location_ids',
            'sellable_location_ids',
            'stockable_location_ids',
            'is_assembly',
            'uom',
            'lots_assignable',
            'customer_part_ids',
            'customer_ids_with_cpn',
            'net_weight',
          ],
        }).then((searchIndexResponse) => {
          const item = searchIndexResponse.hits.find((hit) => hit.item_id === itemId);
          if (!item) {
            throw new Error(`itemId:'${itemId}' not found in items collection`);
          }
          return item;
        }),
      ),
    );
  }
  // value is always defined because we set it above
  return searchIndexItemByIdCache.get(itemId)!.promise;
}

// maintain a lookup of shipToId -> search ship-to
// so we don't hit the search index twice for the same ship-to
export const searchIndexShipToByIdCache = new Map<string, StatefulPromise<SearchIndexShipToPartial>>();
export async function getSearchIndexShipToByShipToId(
  shipToId: string,
  companyId: string,
  customerId: string,
): Promise<SearchIndexShipToPartial> {
  const objectId = `${shipToId}|${companyId}|${customerId}`;
  if (!searchIndexShipToByIdCache.has(objectId)) {
    searchIndexShipToByIdCache.set(
      objectId,
      new StatefulPromise(
        searchIndex<SearchIndexShipToPartial>({
          indexName: SearchIndexName.ShipTos,
          filters: { ship_to_id: [shipToId], company_id: [companyId], customer_id: [customerId] },
          query: shipToId,
          fieldsToRetrieve: [
            'customer_id',
            'company_id',
            'ship_to_id',
            'ship_to_name',
            'ship_to_address',
            'ship_to_phone',
          ],
        }).then((searchIndexResponse) => {
          const shipTo = searchIndexResponse.hits.find(
            (hit) => hit.ship_to_id === shipToId && hit.company_id === companyId,
          );
          if (!shipTo) {
            throw new Error(`objectId:'${objectId}' not found in ship_tos collection`);
          }
          return shipTo;
        }),
      ),
    );
  }
  // value is always defined because we set it above
  return searchIndexShipToByIdCache.get(objectId)!.promise;
}

// maintain a lookup of customerId -> search customer
// so we don't hit the search index twice for the same customer
export const searchIndexCustomerByIdCache = new Map<string, StatefulPromise<SearchIndexCustomerPartial>>();
export async function getSearchIndexCustomerByCustomerId(
  customerId: string,
  companyId: string,
): Promise<SearchIndexCustomerPartial> {
  const objectId = `${customerId}|${companyId}`;
  if (!searchIndexCustomerByIdCache.has(objectId)) {
    searchIndexCustomerByIdCache.set(
      objectId,
      new StatefulPromise(
        searchIndex<SearchIndexCustomerPartial>({
          indexName: SearchIndexName.Customers,
          filters: { id: [objectId] },
          fieldsToRetrieve: [
            'id',
            'company_id',
            'company',
            'customer_id',
            'customer_name',
            'dept_dimension',
            'market_dimension',
            'terms_desc',
          ],
        }).then((searchIndexResponse) => {
          const customer = searchIndexResponse.hits.find((hit) => hit.id === objectId);
          if (!customer) {
            throw new Error(`objectId:'${objectId}' not found in customers collection`);
          }
          return customer;
        }),
      ),
    );
  }
  // value is always defined because we set it above
  return searchIndexCustomerByIdCache.get(objectId)!.promise;
}

export async function getSearchIndexItemExistsByItemId(itemId: string): Promise<boolean> {
  return searchIndex<SearchIndexItemPartial>({
    indexName: SearchIndexName.Items,
    filters: { item_id: [itemId] },
    fieldsToRetrieve: ['item_id'],
  }).then((searchIndexResponse) => {
    const item = searchIndexResponse.hits.find((hit) => hit.item_id.toLowerCase() === itemId.toLowerCase());
    return item !== undefined;
  });
}
