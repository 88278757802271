import React, { useRef } from 'react';

import { RecurrencyRole } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingsFields } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { SearchPlanningDTO } from '@recurrency/core-api-schema/dist/search/getSearchPlanning';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { Grid } from 'antd';

import { getPlanningColumns, getPlanningValueFacets } from 'pages/planning/utils';

import { LastUpdatedMomentForecast } from 'components/recipes/LastUpdatedMoment';
import { SearchFrame } from 'components/recipes/SearchFrame';
import { SearchFrameContext } from 'components/recipes/SearchFrame/types';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { truthy } from 'utils/boolean';
import { useHashState } from 'utils/routes';
import { ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';
import { asKeyOf } from 'utils/tables';

import { SearchFrameHashState } from 'types/hash-state';

import { ReplenishmentMethod, useDemandPlanningFilterSave } from '../utils';
import { SparseStockGridModal } from './modals/SparseStockGridModal';
import { PlanningSearchFrameHeader } from './PlanningSearchFrameHeader';
import { PlanningSidePane } from './PlanningSidePane';

export const PlanningPage = () => {
  const { activeTenant, activeUser } = useGlobalApp();
  const [hashState] = useHashState<SearchFrameHashState>();
  const searchFrameContextRef = useRef<SearchFrameContext<SearchPlanningDTO>>();
  useDemandPlanningFilterSave();

  const showOpOqVariant = !!hashState.where?.recurrency_replenishment_method?.includes(ReplenishmentMethod.OPOQ);
  const isDesktopView: boolean = Grid.useBreakpoint()?.md ?? false;

  const sparseItemGridRoles = [
    RecurrencyRole.TenantAdmin,
    ...(TenantSettingsFields['calculation.sparseMonthsStock'].roles || []),
  ];

  const columns = getPlanningColumns({
    activeTenant,
    activeUser,
    isOnboarding: false,
    isOpOqVariant: showOpOqVariant,
    searchIndexReload: searchFrameContextRef.current?.searchIndexReload,
    isDesktopView,
    openSparseItemGrid: !activeTenant.tenantUser.recurrencyRoles.some((role) => sparseItemGridRoles.includes(role.name))
      ? () => showAsyncModal(SparseStockGridModal, {})
      : undefined,
  }).filter(truthy);

  const valueFacets = getPlanningValueFacets({
    activeTenant,
    activeUser,
    isOnboarding: false,
  });

  return (
    <SearchFrame<SearchPlanningDTO>
      title={<InfoTooltip title="Used for setting mins, maxes, lead times, and safety stock">Planning</InfoTooltip>}
      subtitle={<LastUpdatedMomentForecast includeForecastPrefix />}
      indexName={SearchIndexName.Planning}
      queryPlaceholder="Search by item"
      columns={columns}
      defaultSortBy={{ field: asKeyOf<SearchPlanningDTO>('forecast_confidence'), order: 'desc' }}
      valueFacets={valueFacets.filter(truthy)}
      sidePane={(props) => <PlanningSidePane {...props} isOnboardingVariant={false} />}
      tableColumnsSettingKey={showOpOqVariant ? ViewSettingKey.PlanningTable : ViewSettingKey.PlanningTableOPOQ}
      tableRowSelection="selectAll"
      contextRef={searchFrameContextRef}
      headerActions={(searchFrameContext) => (
        <PlanningSearchFrameHeader
          valueFacets={valueFacets}
          showOpOqVariant={showOpOqVariant}
          searchFrameContext={searchFrameContext}
        />
      )}
    />
  );
};
