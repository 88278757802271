import React, { useEffect } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { Drawer } from 'antd';

import { useCoreApi } from 'hooks/useApi';

import { CloseableModalProps } from 'utils/asyncModal';

import { BetaTag } from '../BetaTag';
import { FlexSpace } from '../FlexSpace';
import { CenteredError, SmallLoader } from '../Loaders';

export interface InventiveDrawerProps extends CloseableModalProps<void> {
  visible: boolean;
}

export const InventiveDrawer = ({ onClose, visible }: InventiveDrawerProps) => {
  const { data, isLoading, error } = useCoreApi(schemas.inventive.getInventiveAuthorizedUrl);

  useEffect(() => {
    // See https://madeinventive.notion.site/Embedding-step-by-step-guide-8543016ccbc34f0b9056efa2efc67c80
    if (!data?.tokens) return () => {};

    const handleMessage = (event: MessageEvent) => {
      const iframe = document.getElementById('inventiveIframe') as HTMLIFrameElement;
      if (!iframe) return;

      const targetOrigin = new URL(data.url).origin;
      if (event.origin !== targetOrigin) {
        // ignore messages from other origins
        return;
      }
      const { type } = event.data;
      if (type !== 'embed_content_ready') {
        // ignore messages with other types
        return;
      }
      const embedTokensMsg = {
        type: 'embed_tokens',
        tokens: data.tokens,
        scopeToken: data.scopeToken,
      };
      iframe.contentWindow?.postMessage(embedTokensMsg, targetOrigin);
      window.removeEventListener('message', handleMessage);
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [data]);

  return (
    <Drawer
      title={
        <FlexSpace gap={2}>
          <strong>Recurrency Copilot</strong> <BetaTag />
        </FlexSpace>
      }
      placement="right"
      width={800}
      onClose={() => onClose()}
      visible={visible}
      className={css`
        .ant-drawer-body {
          padding: 0;
        }
      `}
    >
      {isLoading ? (
        <SmallLoader />
      ) : error ? (
        <CenteredError error={error} />
      ) : (
        <iframe
          title="inventive"
          id="inventiveIframe"
          src={data?.url}
          width="100%"
          height="99%"
          className={css`
            border: 0;
          `}
          allow="clipboard-write"
        />
      )}
    </Drawer>
  );
};
