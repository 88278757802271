import React from 'react';

import { DashboardOutlined } from '@ant-design/icons';

import { usePurchasingStatuses } from 'pages/purchasing/PurchaseTargetsPage/PurchaseTargetLinesPage/utils';

import { AsyncMultiSelect } from '../../AsyncSelect/AsyncMultiSelect';

export type StatusMultiSelectProps = {
  selectedValues: string[];
  onSelectedValuesChange: (newVal: string[]) => void;
};

export function PurchaseStatusMultiSelect({ selectedValues, onSelectedValuesChange }: StatusMultiSelectProps) {
  const { filteredStatuses } = usePurchasingStatuses();
  return (
    <AsyncMultiSelect
      label="Status"
      icon={<DashboardOutlined />}
      selectedValues={selectedValues}
      onSelectedValuesChange={onSelectedValuesChange}
      selectProps={{ options: filteredStatuses.map((status) => ({ label: status, value: status })) }}
    />
  );
}
