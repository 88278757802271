import React from 'react';

import { css } from '@emotion/css';
import { fontWeights } from 'theme/typography';

import { LabelValueMiniTable } from 'components/recipes/sidePane/LabelValueMiniTable';

import { formatNumber, formatUSD, joinIdNameObj } from 'utils/formatting';

import { TransferTargetSummary } from 'types/hash-state';

export function TOSummaryPanelContent({ targetSummaries }: { targetSummaries: TransferTargetSummary[] }) {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        gap: 12px;
      `}
    >
      {targetSummaries.map((targetSummary, idx) => (
        <div key={idx}>
          <div
            className={css`
              font-weight: ${fontWeights.bold};
            `}
          >
            {joinIdNameObj(targetSummary.sourceLocation)}
            <br />
            {joinIdNameObj(targetSummary.destinationLocation)}
          </div>
          <LabelValueMiniTable
            properties={[
              {
                label: 'Total Weight',
                value: formatNumber(targetSummary.totalWeight, 2),
              },
              {
                label: 'Total Volume',
                value: formatNumber(targetSummary.totalVolume, 2),
              },
              {
                label: 'Total Cost',
                value: formatUSD(targetSummary.totalCost, true),
              },
              { label: 'Total Lines', value: targetSummary.totalLines },
            ]}
          />
        </div>
      ))}
    </div>
  );
}
