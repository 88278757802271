import { schemas } from '@recurrency/core-api-schema';
import { UpdateItemLocationInfoType } from '@recurrency/core-api-schema/dist/purchasing/postUpdateItemLocationInfo';

import { getGlobalAppState } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { showAsyncModal } from 'utils/asyncModal';
import { splitIdNameStr } from 'utils/formatting';
import { routes } from 'utils/routes';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { track, TrackEvent } from 'utils/track';

import { FlagItemLocationModal } from './FlagItemLocationModal';
import { MultiModalSelection, getPlanningListSelectionFromFilterSelection } from './utils';

export async function changeRecordFlagStatus(selection: MultiModalSelection, flagged: boolean): Promise<boolean> {
  const { activeTenant } = getGlobalAppState();

  // confirm with user before applying action
  const confirmData = await showAsyncModal(FlagItemLocationModal, {
    selection,
    flagged,
  });
  if (!confirmData) {
    return false;
  }

  const flagReason = confirmData.comment;
  const submitNotification = createSubmissionNotification({
    entityName: 'Flag status',
    submittingMessage: 'Changing flag status',
    expectedWaitSeconds: 30,
    erpType: activeTenant.erpType,
    fireInProgressNotification: false,
  });

  try {
    submitNotification.inProgress({
      description: () => `Update will be applied in background. You can continue using Recurrency.`,
    });

    if (selection.type === 'filter') {
      selection = await getPlanningListSelectionFromFilterSelection(selection);
    }

    await coreApiFetch(schemas.purchasing.postUpdateItemLocationInfo, {
      pathParams: {
        updateType: UpdateItemLocationInfoType.Flag,
      },
      bodyParams: {
        updates: selection.items.map((record) => ({
          itemUid: record.item_uid,
          locationId: splitIdNameStr(record.location).foreignId,
          flagged,
          flagReason,
        })),
      },
    });

    submitNotification.success({
      successMessage: 'Successfully changed flag status',
      duration: 10,
    });

    // track event so we can pipe from Segment -> {MP, Slack} for notifications
    for (const record of selection.items) {
      const location = splitIdNameStr(record.location);

      track(TrackEvent.DemandPlanning_Record_Flag, {
        itemUid: record.item_uid,
        itemId: record.item_id,
        itemName: record.item_name || '',
        locationId: location.foreignId,
        locationName: location.name,
        flagged,
        flagReason,
        recordUrl:
          window.location.origin +
          routes.demandPlanning.planning({
            where: {
              location: [record.location],
            },
            query: record.item_id,
          }),
      });
    }
  } catch (err) {
    submitNotification.error(err);
  }

  return true;
}
