import { DemandForecastOverrideUpdate } from '@recurrency/core-api-schema/dist/ml/updateDemandForecastOverrides';

import { truthy } from 'utils/boolean';

export interface ForecastDiff {
  oldValue: number;
  newValue: number;
}

// These are the columns that in the csv that are not the dates, if you add another, please add to the keycount variable below
export const INPUT_KEY_COUNT = 3;
export const NOTE_COLUMN_INDEX = 2;
export interface ForecastInputFields {
  item: string;
  location: string;
  note: string;
}

export interface ForecastDiffObject extends ForecastInputFields {
  itemUid: string;
  maxChangeCost: number;
  maxChangePercentage: number;
  changes: ForecastDiff[];
}

export function GenerateUpdates(dates: string[], diffs: ForecastDiffObject[]): DemandForecastOverrideUpdate[] {
  return diffs
    .map((singleDiff: ForecastDiffObject) => {
      // each singleDiff is an item/location pair, and the changes is the array of differences
      const { itemUid, location, note, changes } = singleDiff;
      return changes
        .map(({ oldValue, newValue }, index) => {
          const valueChange = newValue - oldValue;
          return valueChange === 0
            ? undefined
            : {
                itemId: itemUid,
                locationId: location,
                // The index of this pair is the same as the index of the date in the diff.dates array
                date: dates[index],
                quantity: newValue,
                note,
              };
        })
        .filter(truthy);
    })
    .flat();
}
