import React, { useState } from 'react';

import { schemas } from '@recurrency/core-api-schema';
import { P21SafetyStockType } from '@recurrency/core-api-schema/dist/common/enums';
import { UpdateItemLocationInfoType } from '@recurrency/core-api-schema/dist/purchasing/postUpdateItemLocationInfo';
import { Radio } from 'antd';

import { Button } from 'components/Button';
import { DividerLine } from 'components/DividerLine';
import { FlexSpace } from 'components/FlexSpace';
import { Modal } from 'components/Modal';
import { PropertyListItem } from 'components/PropertyListItem';
import { RadioGroup } from 'components/Radio';
import { ItemLocationSettingsAuditLog } from 'components/recipes/AuditLog/ItemLocationSettingsAuditLog';
import { Tabs } from 'components/Tabs';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { pluralize, splitIdNameStr } from 'utils/formatting';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { PlanningModalType, track, TrackEvent } from 'utils/track';

import { MultiUpdateReplenishmentSettingsTable } from './MultiUpdateReplenishmentSettingsTable';
import { PositiveNumberInput } from './PositiveNumberInput';
import { getPlanningListSelectionFromFilterSelection, MultiModalSelection, PlanningBulkUpdateToFields } from './utils';

export enum ChangeType {
  Default = 'default',
  Override = 'override',
}

export function MultiSafetyStockModal({
  selection,
  onClose,
  searchIndexReload,
}: {
  selection: MultiModalSelection;
  onClose: (isSubmit: boolean) => void;
  searchIndexReload?: () => void;
}) {
  const { activeTenant } = useGlobalApp();
  const singleRecord = selection.type === 'list' && selection.items.length === 1 ? selection.items[0] : undefined;
  const [safetyStock, setSafetyStock] = useState<number | undefined>(() =>
    singleRecord?.safety_stock_type === P21SafetyStockType.overrideDays ? singleRecord.safety_stock_value : undefined,
  );
  const [changeType, setChangeType] = useState<ChangeType>(
    safetyStock === undefined ? ChangeType.Default : ChangeType.Override,
  );

  const updateChangeType = (newChangeType: ChangeType) => {
    // clear value when changeType is changed
    setSafetyStock(undefined);
    setChangeType(newChangeType);
  };

  const validateInputs = () => !(!safetyStock || safetyStock >= 0);

  const handleSafetyStockUpdate = async () => {
    onClose(true);

    const submitNotification = createSubmissionNotification({
      entityName: 'Safety stock changes',
      submittingMessage: 'Updating Safety stock changes',
      // 30 sec + 30 sec per 5k records
      expectedWaitSeconds: 30 + Math.round(selection.count / 5_000) * 30,
      erpType: activeTenant.erpType,
    });
    try {
      if (selection.type === 'filter') {
        selection = await getPlanningListSelectionFromFilterSelection(selection);
      }

      await coreApiFetch(schemas.purchasing.postUpdateItemLocationInfo, {
        pathParams: {
          updateType: UpdateItemLocationInfoType.SafetyStock,
        },
        bodyParams: {
          updates: selection.items.map((record) => ({
            itemUid: record.item_uid,
            locationId: splitIdNameStr(record.location).foreignId,
            safetyStockDays: safetyStock ?? null,
          })),
        },
      });

      track(TrackEvent.DemandPlanning_MinMaxInfo_Submission, {
        modalType: PlanningModalType.MultiSafetyStock,
        itemCount: selection.count,
      });
      searchIndexReload?.();
      submitNotification.success({
        successMessage: `Successfully updated safety ${pluralize(selection.count, 'stock', 'stocks')}`,
        duration: 10,
      });
    } catch (err) {
      submitNotification.error(err);
    }
  };

  return (
    <Modal
      visible
      title={
        singleRecord
          ? `Update Safety Stock for Item #${singleRecord.item_id} at Location ${singleRecord.location}`
          : `Update Safety Stock for ${pluralize(selection.count, 'Item', 'Items', true)}`
      }
      onCancel={() => onClose(false)}
      destroyOnClose
      footer={
        <>
          <Button onClick={() => onClose(false)}>Cancel</Button>
          <Button onClick={handleSafetyStockUpdate} type="primary" disabled={validateInputs()}>
            Update
          </Button>
        </>
      }
      width={900}
    >
      <FlexSpace direction="column" gap={16}>
        <RadioGroup value={changeType} onChange={({ target: { value } }) => updateChangeType(value)}>
          <FlexSpace direction="column" gap={8}>
            <Radio value={ChangeType.Default}>
              Default <InfoTooltip title="Reset to default safety stock" />
            </Radio>
            <Radio value={ChangeType.Override}>
              <FlexSpace direction="column" gap={8}>
                <div>
                  Override <InfoTooltip title="Fixed safety stock override set in Recurrency" />
                </div>
                <PropertyListItem
                  label="Safety Stock (days)"
                  value={
                    <PositiveNumberInput
                      disabled={changeType === ChangeType.Default}
                      onChange={(inputValue?: number) => {
                        // Set safetyStock to undefined if the input is empty, otherwise convert it to a number
                        setSafetyStock(inputValue);
                      }}
                      value={safetyStock}
                    />
                  }
                />
              </FlexSpace>
            </Radio>
          </FlexSpace>
        </RadioGroup>
      </FlexSpace>
      <DividerLine />
      <MultiUpdateReplenishmentSettingsTable
        selection={selection}
        bulkUpdate={{
          type: 'safetyStock',
          safetyStock,
        }}
      />
      {singleRecord && (
        <>
          <DividerLine marginTop={42} />
          <Tabs
            tabs={[
              {
                header: 'Audit Log',
                infoTooltip: `Safety stock updates for Item #${singleRecord.item_id} at Location #${singleRecord.location}`,
                content: (
                  <ItemLocationSettingsAuditLog
                    itemUid={singleRecord.item_uid}
                    locationId={splitIdNameStr(singleRecord.location).foreignId}
                    fields={PlanningBulkUpdateToFields.safetyStock}
                  />
                ),
              },
            ]}
          />
        </>
      )}
    </Modal>
  );
}
