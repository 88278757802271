import React, { useMemo } from 'react';

import { Link } from 'react-router-dom';

import {
  AppstoreOutlined,
  BuildOutlined,
  DeploymentUnitOutlined,
  DownOutlined,
  EnvironmentOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/css';
import { IntegratedErps, TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { schema } from '@recurrency/core-api-schema/dist/companies';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { ColumnType } from 'antd/lib/table';
import { colors } from 'theme/colors';

import { Button } from 'components/Button';
import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { Counter } from 'components/Counter';
import { ButtonLink } from 'components/Links';
import { NewItemButton } from 'components/recipes/NewItemButton';
import { NewQuoteButton } from 'components/recipes/NewQuoteButton';
import { SearchFrame, ValueFacet } from 'components/recipes/SearchFrame';
import { Tooltip } from 'components/Tooltip';

import { useCoreApi, useLegacyApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { truthy } from 'utils/boolean';
import { formatNumber, formatUSD, joinIdNameObj } from 'utils/formatting';
import { getLocalStorageItem, LocalStorageKey, removeLocalStorageItem, useLocalStorage } from 'utils/localStorage';
import { shouldHideCostAndGm, shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { useHashState } from 'utils/routes';
import { sortableNumberColumn, sortableStringColumn } from 'utils/tables';
import { priceUnitConverter, qtyUnitConverter } from 'utils/units';

import { SearchFrameHashState } from 'types/hash-state';
import { SalesRepLocationResponse } from 'types/legacy-api';
import { SearchIndexItem } from 'types/search-collections';

import { getTenantConfiguration } from '../../utils/tenantConf/tenantConf';

export const ItemListPage = ({ detailsPageLinkFn }: { detailsPageLinkFn: (id: string) => string }) => {
  const [hashState] = useHashState<SearchFrameHashState>();
  const { activeTenant, activeUser, activeCompanyIds, userAllowedCompanies } = useGlobalApp();
  const { data: salesRepLocationData } = useLegacyApi<SalesRepLocationResponse>('/v3/salesrep');

  const allCompanyData = useCoreApi(schema.getCompanies, {
    queryParams: { allCompanies: true },
  });
  /** only show qty available if there's one or no companies
   * this is because the qty is an aggregation of all companies and users may only be able to view a subset of these
   */
  const showCompanyQty = allCompanyData.data ? allCompanyData.data.items.length < 2 : false;

  // show stock and MAC (or standard cost) for salesrep's default location
  // locationId -1 implies user is admin and has no default location
  const salesRepLocation =
    salesRepLocationData && salesRepLocationData.locationId !== -1
      ? `${salesRepLocationData.locationId}: ${salesRepLocationData.locationName}`
      : '';

  const stockLocationsToCompare = hashState.where?.in_stock_locations || [];
  if (salesRepLocation && !stockLocationsToCompare.includes(salesRepLocation)) {
    stockLocationsToCompare.unshift(salesRepLocation);
  }

  const hideCostAndGM = shouldHideCostAndGm(activeTenant, activeUser);

  const showStandardCostOverMAC = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.SalesItemAvailabilityWithStandardCost,
  );

  const [selectedItemIdNameObjs, setSelectedItemIdNameObjs] = useLocalStorage(
    LocalStorageKey.SelectedItemsForNewQuote,
    [],
  );
  const selectedItemIds = useMemo(() => selectedItemIdNameObjs.map((item) => item.foreignId), [selectedItemIdNameObjs]);
  const newQuoteBtnEnabled = selectedItemIdNameObjs.length > 0;

  const handleTableItemSelect = (selectedItem: SearchIndexItem, selected: boolean) => {
    if (selected && !selectedItemIds.includes(selectedItem.item_id)) {
      setSelectedItemIdNameObjs([
        ...selectedItemIdNameObjs,
        { foreignId: selectedItem.item_id, name: selectedItem.item_desc },
      ]);
    } else if (!selected && selectedItemIds.includes(selectedItem.item_id)) {
      setSelectedItemIdNameObjs(selectedItemIdNameObjs.filter((item) => item.foreignId !== selectedItem.item_id));
    }
  };

  const columns: (ColumnType<SearchIndexItem> | null)[] = [
    {
      title: 'ID',
      dataIndex: 'item_id',
      render: (id: string, item: SearchIndexItem) => (
        <Link to={detailsPageLinkFn(id)}>
          {id} {item.is_assembly ? <DeploymentUnitOutlined /> : null}
        </Link>
      ),
    },
    sortableStringColumn({
      title: 'Item',
      dataIndex: 'item_desc',
      sorter: true,
    }),
    {
      title: 'Description',
      dataIndex: 'extended_desc',
    },
    ...(hideCostAndGM ? [] : stockLocationsToCompare).map((locationIdNameStr) => ({
      title: `${locationIdNameStr} ${showStandardCostOverMAC ? 'Cost' : 'MAC'}`,
      dataIndex: showStandardCostOverMAC ? 'standard_cost_by_location' : 'mac_by_location',
      render: (costByLocation: Obj<number>, item: SearchIndexItem) =>
        formatUSD(priceUnitConverter(costByLocation ? costByLocation[locationIdNameStr] : 0, item.unit_size), true),
      align: 'right' as const,
    })),
    ...stockLocationsToCompare.map((locationIdNameStr) => ({
      title: `${locationIdNameStr} Stock`,
      dataIndex: 'stock_by_location',
      render: (stockByLocation: Obj<number>, item: SearchIndexItem) =>
        formatNumber(qtyUnitConverter(stockByLocation[locationIdNameStr], item.unit_size)),
      align: 'right' as const,
    })),
    showCompanyQty
      ? sortableNumberColumn({
          title: 'Company Qty Available',
          dataIndex: 'stock',
          render: (companyStock: number, item: SearchIndexItem) =>
            formatNumber(qtyUnitConverter(companyStock, item.unit_size)),
          sorter: true,
          align: 'right' as const,
          defaultSortOrder: 'descend',
        })
      : null,
    {
      title: 'UOM',
      dataIndex: 'uom',
    },
    {
      title: '',
      dataIndex: 'item_id',
      render: (itemId: string) => ButtonLink(detailsPageLinkFn(itemId)),
    },
  ];

  const valueFacets: (ValueFacet<SearchIndexItem> | null)[] = [
    activeTenant.erpType === IntegratedErps.P21
      ? {
          title: 'Type',
          field: 'is_assembly',
          labelFormatFn: (value) => (value === 'true' ? 'Assembly' : 'Non-Assembly'),
          icon: <BuildOutlined />,
        }
      : null,
    getTenantConfiguration(activeTenant).itemsListPage.hideProductGroupFilter
      ? null
      : {
          title: 'Product Group',
          field: 'pg',
          queryPlaceholder: 'Search product groups',
          icon: <AppstoreOutlined />,
        },
    {
      title: 'In-Stock Location',
      field: 'in_stock_locations',
      queryPlaceholder: 'Search locations',
      icon: <EnvironmentOutlined />,
      triggerButton: (
        <ConditionalWrapper
          condition={!truthy(hashState.where?.in_stock_locations?.length)}
          wrapper={(children) => (
            <Tooltip
              trigger="hover"
              title="Shows only items in-stock at selected locations"
              slow
              mouseLeaveDelay={0}
              mouseEnterDelay={0}
            >
              {children}
            </Tooltip>
          )}
        >
          <Button
            size="small"
            className={css`
              &.ant-btn {
                border-color: ${colors.neutral[300]};
                z-index: 4;
                &:focus,
                &:hover {
                  border-color: ${colors.primary[500]};
                  color: inherit;
                }
              }
            `}
          >
            <div
              className={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 6px;
              `}
            >
              <div
                className={css`
                  display: flex;
                  align-items: center;
                  gap: 6px;
                `}
              >
                <EnvironmentOutlined />
                In-Stock Locations
                <Counter
                  value={hashState.where?.in_stock_locations?.length ?? 0}
                  selected={(hashState.where?.in_stock_locations?.length || [].length) > 0}
                  variant="primary"
                />
              </div>
              <DownOutlined style={{ color: colors.neutral[400], fontSize: '12px' }} />
            </div>
          </Button>
        </ConditionalWrapper>
      ),
      // Set static list of options to only those locations that user is allowed to access.
      // Since 'Out of Stock' is a virtual location shared between companies and locations,
      // filtering in_stock_locations doesn't work.
      options:
        userAllowedCompanies.length > 0
          ? [
              ...userAllowedCompanies
                .filter((c) => (activeCompanyIds ? activeCompanyIds.includes(c.companyId) : true))
                .flatMap((c) => c.locations.map((l) => `${l.locationId}: ${l.locationName}`)),
              'Out of stock',
            ].map((locationIdNameStr) => ({ value: locationIdNameStr, label: locationIdNameStr }))
          : undefined,
    },
    activeTenant.erpType === IntegratedErps.P21
      ? {
          title: 'Supplier',
          field: 'suppliers',
          queryPlaceholder: 'Search suppliers',
          icon: <ShopOutlined />,
        }
      : null,
  ];

  return (
    <SearchFrame<SearchIndexItem>
      title="Inventory"
      indexName={SearchIndexName.Items}
      queryPlaceholder="Search items by ID, description, product group, or keywords"
      columns={columns.filter(truthy)}
      valueFacets={valueFacets.filter(truthy)}
      headerActions={
        <>
          {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateQuote) && (
            <Tooltip
              title={
                newQuoteBtnEnabled
                  ? `Selected Items:\n${selectedItemIdNameObjs
                      .map((item, idx) => `  ${idx + 1}. ${joinIdNameObj(item)}`)
                      .join('\n')}`
                  : "Select at least one item to enable 'New Quote' button"
              }
              overlayInnerStyle={{ whiteSpace: 'pre-wrap' }}
            >
              <div>
                <NewQuoteButton
                  props={{
                    onClick: () => {
                      removeLocalStorageItem(LocalStorageKey.SelectedItemsForNewQuote);
                    },
                    disabled: !newQuoteBtnEnabled,
                  }}
                  hashState={{ items: getLocalStorageItem(LocalStorageKey.SelectedItemsForNewQuote) || undefined }}
                />
              </div>
            </Tooltip>
          )}
          {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SalesCreateItem) && (
            <NewItemButton type="primary" />
          )}
          {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SalesCreateAssemblyItem) && (
            <NewItemButton type="primary" assemblyItem />
          )}
        </>
      }
      tableRowKey="item_id"
      tableRowSelection={
        shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateQuote)
          ? {
              selectedRowKeys: selectedItemIds,
              onSelect: handleTableItemSelect,
              hideSelectAll: true,
              onSelectNone: () => setSelectedItemIdNameObjs([]),
            }
          : undefined
      }
    />
  );
};
