import React, { useEffect, useMemo, useState } from 'react';

import {
  AppstoreOutlined,
  DashboardOutlined,
  EnvironmentOutlined,
  ExpandAltOutlined,
  ShopOutlined,
  ShrinkOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/css';
import { SupplierTargetTypes } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { Switch } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { colors } from 'theme/colors';

import { usePurchasingStatuses } from 'pages/purchasing/PurchaseTargetsPage/PurchaseTargetLinesPage/utils';

import { AsyncSelect } from 'components/AsyncSelect';
import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { useStaticListSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { Button } from 'components/Button';
import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpace } from 'components/FlexSpace';
import { FlexSpacer } from 'components/FlexSpacer';
import { EditTargetActionButton } from 'components/recipes/EditTargetActionButton';
import { PurchaseTargetGroupNameToolTip } from 'components/recipes/PurchaseTargetGroupNameToolTip';
import {
  SupplierLocationSettingsModal,
  SupplierLocationTargetSettings,
} from 'components/recipes/SupplierLocationSettingsModal';
import { ResultCount } from 'components/ResultCount';
import { Table } from 'components/Table';
import { TargetProgressBar } from 'components/TargetProgressBar';
import { Tooltip } from 'components/Tooltip';

import { arrUnique } from 'utils/array';
import { showAsyncModal } from 'utils/asyncModal';
import { truthy } from 'utils/boolean';
import { capitalize, formatNumber, formatUSD, joinIfIdNameObj, splitIdNameStr } from 'utils/formatting';
import { useHashState } from 'utils/routes';
import { sortDirections, typedColumn } from 'utils/tables';
import { getTenantSetting } from 'utils/tenantSettings';

import {
  ForeignIdNameStr,
  PurchaseGroupHashState,
  PurchaseTargetLinesHashState,
  PurchasingLineInput,
  SupplierLocationWTarget,
} from 'types/hash-state';
import { IdNameObj } from 'types/legacy-api';

import { GenericTargetLineDTO, GroupedPOSummary, POSummary } from '../../types';
import {
  getGroupedPOSummaries,
  getGroupedPOSummaryKey,
  getHubAndSpokePOSummaries,
  getMergedPurchaseLines,
  getPOSummaries,
  getSupplierLocationsForGroupedPOSummary,
} from '../../utils';
import { PurchaseAutoTargetFillTrimModal } from '../PurchaseAutoTargetFillTrimModal';
import { PurchaseTargetLinesModal } from '../PurchaseTargetLinesModal';
import { formatTargetValue } from '../sidePane/POSummariesPanelContent';

export enum POSummaryStatus {
  All = 'All',
  FilledToTarget = 'Filled to Target',
  NotFilledToTarget = 'Not Filled to Target',
}

export function PurchasingOrdersStepPOSection({
  supplierLocations,
  targetLines,
  purchasingLinesById,
  groupBuy,
  isPurchasingHubAndSpoke,
  isPlanningHubAndSpoke,
  purchaseGroups,
  onPurchasingLinesChange,
  onSupplierLocationsChange,
  onGroupBuyChange,
}: {
  supplierLocations: SupplierLocationWTarget[];
  targetLines: GenericTargetLineDTO[];
  purchasingLinesById: Obj<PurchasingLineInput>;
  groupBuy: boolean;
  isPurchasingHubAndSpoke: boolean;
  isPlanningHubAndSpoke: boolean;
  purchaseGroups?: PurchaseGroupHashState[];
  onPurchasingLinesChange: (purchasingLinesById: Obj<PurchasingLineInput>) => void;
  onSupplierLocationsChange: (supplierLocations: SupplierLocationWTarget[]) => void;
  onGroupBuyChange: (groupBuy: boolean) => void;
}) {
  const [expandedRowKeys, setExpandedRowKeys] = useState<Obj<boolean>>({});
  const [statusFilter, setStatusFilter] = useState<POSummaryStatus>(POSummaryStatus.All);
  const [locationIdsFilter, setLocationIdsFilter] = useState<string[]>([]);
  const [supplierIdsFilter, setSupplierIdsFilter] = useState<string[]>([]);
  const [purchaseGroupsIdsFilter, setPurchaseGroupsIdsFilter] = useState<string[]>([]);
  const [hashState, updateHashState] = useHashState<PurchaseTargetLinesHashState>();
  const uniqueLocations = arrUnique(supplierLocations.map((sl) => `${sl.locationId}: ${sl.locationName}`));
  const uniqueSuppliers = arrUnique(supplierLocations.map((sl) => `${sl.supplierId}: ${sl.supplierName}`));
  // allow group buy if multiple locations and dfpa group buy feature flag is enabled
  const allLines = useMemo(
    () => getMergedPurchaseLines(targetLines, purchasingLinesById),
    [purchasingLinesById, targetLines],
  );
  const { shouldCreateSpecialOrderLines } = usePurchasingStatuses();
  const poSummaries = useMemo(
    () =>
      isPurchasingHubAndSpoke && purchaseGroups
        ? getHubAndSpokePOSummaries(allLines, supplierLocations, purchaseGroups)
        : getPOSummaries(allLines, supplierLocations, shouldCreateSpecialOrderLines),
    [allLines, isPurchasingHubAndSpoke, purchaseGroups, supplierLocations, shouldCreateSpecialOrderLines],
  );

  const filteredPOSummaries = useMemo(
    () =>
      poSummaries.filter(
        (poSummary) =>
          (locationIdsFilter.length === 0 || locationIdsFilter.includes(poSummary.purchasingLocation.foreignId)) &&
          (supplierIdsFilter.length === 0 || supplierIdsFilter.includes(poSummary.supplier.foreignId)) &&
          (statusFilter === POSummaryStatus.All ||
            (statusFilter === POSummaryStatus.FilledToTarget &&
              poSummary.targetType !== SupplierTargetTypes.NotSet &&
              poSummary.currentValue >= poSummary.targetValue) ||
            (statusFilter === POSummaryStatus.NotFilledToTarget &&
              poSummary.targetType !== SupplierTargetTypes.NotSet &&
              poSummary.currentValue < poSummary.targetValue)) &&
          (purchaseGroupsIdsFilter.length === 0 ||
            (poSummary.groupId != null && purchaseGroupsIdsFilter.includes(poSummary.groupId))),
      ),
    [locationIdsFilter, poSummaries, purchaseGroupsIdsFilter, statusFilter, supplierIdsFilter],
  );

  const groupedPOSummaries: POSummary[] =
    groupBuy && !isPurchasingHubAndSpoke ? getGroupedPOSummaries(filteredPOSummaries) : filteredPOSummaries;

  const handleSupplierLocationTargetChange = (newTarget: SupplierLocationTargetSettings) => {
    const newSupplierLocations = [...supplierLocations];
    const supplierLocation = newSupplierLocations.find(
      (sl) => sl.supplierId === newTarget.supplierId && sl.locationId === newTarget.locationId,
    )!;
    supplierLocation.targetType = newTarget.targetType;
    supplierLocation.targetValue = newTarget.targetValue;
    onSupplierLocationsChange(newSupplierLocations);
  };

  const editButtonOnClick = async (supplierId: string, locationId: string) => {
    const newSettings = await showAsyncModal(SupplierLocationSettingsModal, {
      supplierId,
      locationId,
    });
    if (newSettings) {
      handleSupplierLocationTargetChange(newSettings);
    }
  };

  const handlePurchasingLocationChange = (record: GroupedPOSummary, newPurchasingLocation: IdNameObj) => {
    const newSupplierLocations = [...supplierLocations];
    // if group parent, update all the children to the new purchasing location
    const newSupplierLocationsToUpdate = getSupplierLocationsForGroupedPOSummary(
      newSupplierLocations,
      record,
      isPurchasingHubAndSpoke,
    );
    for (const sl of newSupplierLocationsToUpdate) {
      sl.purchasingLocationId = newPurchasingLocation.foreignId;
      sl.purchasingLocationName = newPurchasingLocation.name;
    }
    if (isPurchasingHubAndSpoke) {
      for (const line of targetLines) {
        if (line.purchaseLocationId === record.purchasingLocation.foreignId) {
          line.purchaseLocationId = newPurchasingLocation.foreignId;
          line.purchaseLocationName = newPurchasingLocation.name;
        }
      }
      const updateGroups = hashState.purchaseGroups?.map((pg) => {
        if (pg.groupName === record.groupName) {
          pg.purchaseLocationId = newPurchasingLocation.foreignId;
          pg.purchaseLocationName = newPurchasingLocation.name;
        }
        return pg;
      });
      updateHashState({ purchaseGroups: updateGroups });
    }

    // expand the group with purchasing location change
    record.purchasingLocation = newPurchasingLocation;
    setExpandedRowKeys({ ...expandedRowKeys, [getGroupedPOSummaryKey(record)]: true });
    onSupplierLocationsChange(newSupplierLocations);

    // Update purchase location filter
    const uniquePurchaseLocationIds = arrUnique(supplierLocations.map((sl) => sl.purchasingLocationId));
    setLocationIdsFilter(locationIdsFilter.filter((locationId) => uniquePurchaseLocationIds.includes(locationId)));
  };

  useEffect(() => {
    // expand all the groups, if group buy is enabled and there are no expanded rows
    if (groupBuy && groupedPOSummaries.length > 0 && Object.keys(expandedRowKeys).length === 0) {
      const newExpandedKeys = Object.fromEntries(groupedPOSummaries.map((po) => [getGroupedPOSummaryKey(po), true]));
      setExpandedRowKeys(newExpandedKeys);
    }
  }, [groupBuy, expandedRowKeys, groupedPOSummaries]);

  const tableColumns: Array<ColumnType<GroupedPOSummary> | null> = [
    typedColumn({
      title: 'Supplier',
      dataIndex: 'supplier',
      width: `300px`,
      sortDirections,
      render: (supplier, record) => (
        <div
          // display inline-block so group indentation indents the whole block
          // color children gray, to differentiate from parent style
          className={
            groupBuy
              ? css`
                  display: inline-block;
                  color: ${groupedPOSummaries.includes(record) ? 'inherit' : colors.neutral[500]};
                `
              : undefined
          }
        >
          <div>{supplier.foreignId}</div>
          <div>{supplier.name}</div>
        </div>
      ),
    }),
    isPurchasingHubAndSpoke
      ? typedColumn({
          title: 'Group Name',
          dataIndex: 'groupName',
          width: `200px`,
          sortDirections,
          render: (name, record) => {
            const purchaseGroup = hashState.purchaseGroups?.find((pg) => pg.groupName === name);
            return purchaseGroup != null && name != null ? (
              <PurchaseTargetGroupNameToolTip
                groupName={name}
                purchaseLocationId={record.purchasingLocation.foreignId}
                purchaseLocationName={record.purchasingLocation.name}
                spokeLocations={purchaseGroup.spokeLocations}
              />
            ) : (
              <div>{name}</div>
            );
          },
        })
      : null,
    typedColumn({
      title: 'Purchasing Location',
      dataIndex: 'purchasingLocation',
      width: `250px`,
      sortDirections,
      render: (purchasingLocation, record) =>
        groupBuy ? (
          <PurchasingLocationCell
            value={purchasingLocation}
            onChange={(newPuchasingLocation) => handlePurchasingLocationChange(record, newPuchasingLocation)}
            // only allow selection of locations for the supplier
            locations={supplierLocations
              .filter((sl) => sl.supplierId === record.supplier.foreignId && sl.groupName === record.groupName)
              .map((sl) => `${sl.locationId}: ${sl.locationName}`)}
          />
        ) : (
          <div>
            <div>{purchasingLocation.foreignId}</div>
            <div>{purchasingLocation.name}</div>
          </div>
        ),
    }),
    groupBuy && !isPurchasingHubAndSpoke
      ? typedColumn({
          title: 'Requirement Location',
          dataIndex: 'requirementLocation',
          width: `250px`,
          sortDirections,
          render: (requirementLocation) =>
            requirementLocation ? (
              <div>
                <div>{requirementLocation.foreignId}</div>
                <div>{requirementLocation.name}</div>
              </div>
            ) : null,
        })
      : null,
    {
      title: 'Target',
      render: (record: GroupedPOSummary) => (
        <div>
          <div>
            <EditTargetActionButton
              value={
                <SupplierLocationTargetContent
                  targetValue={record.targetValue}
                  targetType={record.targetType}
                  currentValue={record.currentValue}
                />
              }
              onClick={() => editButtonOnClick(record.supplier.foreignId, record.purchasingLocation.foreignId)}
            />
          </div>
          <div>
            {record.targetValue ? <TargetProgressBar percent={record.currentValue / record.targetValue} /> : null}
          </div>
        </div>
      ),
    },
    typedColumn({
      title: 'Total Lines',
      dataIndex: 'totalLines',
      sortDirections,
      align: 'right',
      render: (totalLines) => formatNumber(totalLines),
    }),
    typedColumn({
      title: 'Total Cost',
      dataIndex: 'totalPOCost',
      sortDirections,
      align: 'right',
      render: (totalPOCost) => formatUSD(totalPOCost, true),
    }),
    {
      title: 'Actions',
      width: '100px',
      align: 'left',
      render: (record: GroupedPOSummary) => (
        <FlexSpace>
          <Button
            size="small"
            onClick={async () => {
              const editedPurchaseLines = await showAsyncModal(PurchaseTargetLinesModal, {
                supplierLocations: getSupplierLocationsForGroupedPOSummary(
                  supplierLocations,
                  record,
                  isPurchasingHubAndSpoke,
                ),
                targetLines,
                purchasingLinesById,
                isPurchasingHubAndSpoke,
                isPlanningHubAndSpoke,
              });
              if (editedPurchaseLines) {
                onPurchasingLinesChange(editedPurchaseLines);
              }
            }}
          >
            Lines
          </Button>
          {
            // if in group buy and not hub and spoke, show button on parent row (e.g has children) only
            (groupBuy && !isPurchasingHubAndSpoke ? record.children != null && record.children.length > 0 : true) ? (
              <ConditionalWrapper
                condition={!record.targetValue}
                wrapper={(children: React.ReactElement) => (
                  <Tooltip placement="topRight" title="Only available for weight, volume, unit or dollar targets">
                    <div>{children}</div>
                  </Tooltip>
                )}
              >
                <Button
                  size="small"
                  onClick={async () => {
                    const editedPurchaseLines = await showAsyncModal(PurchaseAutoTargetFillTrimModal, {
                      supplierLocations: getSupplierLocationsForGroupedPOSummary(
                        supplierLocations,
                        record,
                        isPurchasingHubAndSpoke,
                      ),
                      targetLines,
                      purchasingLinesById,
                      isPurchasingHubAndSpoke,
                      isPlanningHubAndSpoke,
                      currentValue: record.currentValue,
                      targetValue: record.targetValue,
                      targetType: record.targetType,
                    });
                    if (editedPurchaseLines) {
                      onPurchasingLinesChange(editedPurchaseLines);
                    }
                  }}
                  disabled={!record.targetValue}
                  icon={record.currentValue > record.targetValue ? <ShrinkOutlined /> : <ExpandAltOutlined />}
                >
                  {record.currentValue > record.targetValue ? 'Auto Trim' : 'Auto Fill'}
                </Button>
              </ConditionalWrapper>
            ) : null
          }
        </FlexSpace>
      ),
    },
  ];

  return (
    <div>
      <FilterBarBox>
        {getTenantSetting(TenantSettingKey.FeatureGroupBuy) &&
        uniqueLocations.length > 1 &&
        !isPurchasingHubAndSpoke ? (
          <>
            <span>Group buy</span>
            <Switch checked={groupBuy} onChange={(checked) => onGroupBuyChange(checked)} />
            <span
              className={css`
                margin-left: 8px;
              `}
            >
              Filter
            </span>
          </>
        ) : null}

        <AsyncMultiSelect
          label="Status"
          mode="single"
          icon={<DashboardOutlined />}
          selectedValues={[statusFilter]}
          onSelectedValuesChange={(values) => setStatusFilter(values[0] as POSummaryStatus)}
          selectProps={{ options: Object.values(POSummaryStatus).map((status) => ({ label: status, value: status })) }}
        />
        {uniqueLocations.length > 1 ? (
          <AsyncMultiSelect
            label={`${isPurchasingHubAndSpoke ? 'Purchase ' : ''}Location`}
            icon={<EnvironmentOutlined />}
            queryPlaceholder="Search locations"
            selectedValues={locationIdsFilter}
            onSelectedValuesChange={(values) => setLocationIdsFilter(values)}
            selectProps={{
              options: arrUnique(
                supplierLocations
                  .filter((sl) => (isPurchasingHubAndSpoke ? sl.purchasingLocationId === sl.locationId : true))
                  .map((sl) => ({
                    label: `${sl.locationId}: ${sl.locationName}`,
                    value: sl.locationId,
                  })),
                'value',
              ),
            }}
          />
        ) : null}
        {uniqueSuppliers.length > 1 ? (
          <AsyncMultiSelect
            label="Supplier"
            icon={<ShopOutlined />}
            queryPlaceholder="Search suppliers"
            selectedValues={supplierIdsFilter}
            onSelectedValuesChange={(values) => setSupplierIdsFilter(values)}
            selectProps={{
              options: arrUnique(
                supplierLocations.map((sl) => ({
                  label: `${sl.supplierId}: ${sl.supplierName}`,
                  value: sl.supplierId,
                })),
                'value',
              ),
            }}
          />
        ) : null}
        {isPurchasingHubAndSpoke && hashState.purchaseGroups != null && hashState.purchaseGroups.length > 1 && (
          <AsyncMultiSelect
            label="Purchase Group"
            icon={<AppstoreOutlined />}
            selectedValues={purchaseGroupsIdsFilter}
            queryPlaceholder="Search purchase groups"
            onSelectedValuesChange={(values: string[]) => setPurchaseGroupsIdsFilter(values)}
            selectProps={{
              options: hashState.purchaseGroups.map((pg) => ({
                label: pg.groupName,
                value: pg.groupId,
              })),
            }}
          />
        )}
        <FlexSpacer />
        <ResultCount count={filteredPOSummaries.length} />
      </FilterBarBox>
      <Table
        columns={tableColumns.filter(truthy)}
        data={groupedPOSummaries}
        verticalAlign="middle"
        rowKey={getGroupedPOSummaryKey}
        expandable={{
          expandedRowKeys: Object.entries(expandedRowKeys)
            .filter(([, expanded]) => expanded)
            .map(([key]) => key),
          onExpand: (expanded, record) =>
            setExpandedRowKeys({ ...expandedRowKeys, [getGroupedPOSummaryKey(record)]: expanded }),
          indentSize: 12,
        }}
      />
    </div>
  );
}

function PurchasingLocationCell({
  value,
  onChange,
  locations,
}: {
  value?: IdNameObj;
  onChange: (newValue: IdNameObj) => void;
  locations: ForeignIdNameStr[];
}) {
  return (
    <AsyncSelect
      className={css`
        width: 100%;
      `}
      selectProps={useStaticListSelectProps({
        options: locations.map((l) => ({ value: l })),
      })}
      entityPlural="location"
      value={joinIfIdNameObj(value)}
      onSelect={(newValue: string) => onChange(splitIdNameStr(newValue))}
      dropdownMatchSelectWidth={550}
    />
  );
}

interface SupplierLocationTargetContentProps {
  targetValue: number;
  targetType: SupplierTargetTypes;
  currentValue: number;
}

function SupplierLocationTargetContent({ targetValue, targetType, currentValue }: SupplierLocationTargetContentProps) {
  if (!targetValue) {
    return <span>No target set</span>;
  }

  return (
    <span>
      {formatTargetValue(targetType, currentValue)} of target {formatTargetValue(targetType, targetValue)} (
      {capitalize(targetType)})
    </span>
  );
}
