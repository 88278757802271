import React from 'react';

import { Link } from 'react-router-dom';

import {
  AppstoreOutlined,
  CalculatorOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  CodeOutlined,
  DashboardOutlined,
  DownOutlined,
  EnvironmentOutlined,
  FlagOutlined,
  MonitorOutlined,
  ReconciliationOutlined,
  ShopOutlined,
  StockOutlined,
  StopOutlined,
  TagOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/css';
import {
  RecurrencyReplenishmentMethod,
  P21SafetyStockType,
  P21LeadTimeSource,
  TenantFeatureFlag,
} from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { SearchPlanningDTO } from '@recurrency/core-api-schema/dist/search/getSearchPlanning';
import { TenantDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';
import { Menu } from 'antd';
import { theme } from 'theme';

import { changeRecordFlagStatus } from 'pages/purchasing/PlanningPage/modals/FlagItemStatusUpdater';
import { changeRecordExcludedStatus } from 'pages/purchasing/PlanningPage/modals/IncludeExcludeStatusUpdater';
import { MultiLeadTimeModal } from 'pages/purchasing/PlanningPage/modals/MultiLeadTimeModal';
import { MultiSafetyStockModal } from 'pages/purchasing/PlanningPage/modals/MultiSafetyStockModal';
import { RecommendedMaxPopover } from 'pages/purchasing/PlanningPage/modals/RecommendedMaxPopover';
import { RecommendedMinPopover } from 'pages/purchasing/PlanningPage/modals/RecommendedMinPopover';
import { UpdateReplenishmentSettingsModal } from 'pages/purchasing/PlanningPage/modals/UpdateReplenishmentSettingsModal';
import {
  leadTimeSourceNames,
  MultiModalSelection,
  safetyStockSourceNames,
} from 'pages/purchasing/PlanningPage/modals/utils';
import { ReplenishmentPathPopover } from 'pages/purchasing/PlanningPage/ReplenishmentPathPopover';
import { demandPatternOptions, demandPredictabilityOptions } from 'pages/purchasing/PlanningPage/utils';
import { ReplenishmentMethod } from 'pages/purchasing/utils';

import { Button } from 'components/Button';
import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { Dropdown } from 'components/Dropdown';
import { FlexSpace } from 'components/FlexSpace';
import { ValueFacet } from 'components/recipes/SearchFrame';
import { FacetRenderType } from 'components/recipes/SearchFrame/types';
import { BadgeStatus, labelByStatus, StatusBadge } from 'components/recipes/StatusBadge';
import { Tooltip } from 'components/Tooltip';
import { DemandPatternTooltip } from 'components/Tooltip/DemandPatternTooltip';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';
import { EllipsisTruncatedLabel } from 'components/Typography/EllpsisTruncatedLabel';

import { ExtendedUser } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { truthy } from 'utils/boolean';
import { formatDate, formatNumber, formatUSD } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';
import { PersistedColumn } from 'utils/tableAndSidePaneSettings/types';
import {
  asKeyOf,
  numberColumn,
  sortableChangePercentColumn,
  sortableDateColumn,
  sortableNumberColumn,
  sortDirections,
} from 'utils/tables';
import { isTenantErpTypeNetSuite, isTenantErpTypeP21 } from 'utils/tenants';
import { getTenantSetting, isHubAndSpokeEnabled } from 'utils/tenantSettings';

export const getPlanningColumns = (config: {
  activeTenant: TenantDTO;
  activeUser: ExtendedUser;
  isOnboarding: boolean;
  isOpOqVariant: boolean;
  searchIndexReload: (() => void) | undefined;
  openSparseItemGrid?: () => void;
  isDesktopView?: boolean;
}): (PersistedColumn<SearchPlanningDTO> | null)[] => {
  const isPlanningHubAndSpoke = isHubAndSpokeEnabled();
  const isP21 = isTenantErpTypeP21(config.activeTenant.erpType);
  const shouldAllowExcludedUntil = shouldShowFeatureFlag(
    config.activeTenant,
    config.activeUser,
    TenantFeatureFlag.FeaturePlanningExcludedUntil,
  );
  const shouldShowItemFlagging = shouldShowFeatureFlag(
    config.activeTenant,
    config.activeUser,
    TenantFeatureFlag.FeaturePlanningFlagging,
  );
  return [
    {
      title: 'Item',
      settingKey: 'itemId',
      dataIndex: asKeyOf<SearchPlanningDTO>('item_id'),
      fixed: 'left',
      sorter: true,
      sortDirections,
      required: true,
      render: (_: string, record) => (
        <div>
          <Link to={routes.purchasing.itemDetails(record.item_id)}>{record.item_id}</Link>
          <div>{record.item_name}</div>
        </div>
      ),
      exportValue: {
        'Item ID': asKeyOf<SearchPlanningDTO>('item_id'),
        'Item Name': asKeyOf<SearchPlanningDTO>('item_name'),
      },
    },
    {
      title: `${isPlanningHubAndSpoke ? 'Requirement ' : ''}Location`,
      dataIndex: asKeyOf<SearchPlanningDTO>('location'),
      settingKey: 'location',
      fixed: config.isDesktopView ? 'left' : undefined,
      sorter: isPlanningHubAndSpoke,
      required: true,
    },
    isPlanningHubAndSpoke
      ? {
          title: <InfoTooltip title="The location where this item is sourced from">Replenishment Location</InfoTooltip>,
          dataIndex: asKeyOf<SearchPlanningDTO>('replenishment_location'),
          settingKey: 'replenishmentLocation',
          fixed: config.isDesktopView ? 'left' : undefined,
          sorter: true,
          render: (replenishmentLocation, record) => (
            <ConditionalWrapper
              condition={
                !!record.replenishment_location &&
                record.location !== record.replenishment_location &&
                record.replenishment_location.trim() !== ':'
              }
              wrapper={(children) => (
                <ReplenishmentPathPopover
                  itemId={record.item_uid}
                  location={record.location}
                  supplier={record.primary_supplier}
                >
                  {children}
                </ReplenishmentPathPopover>
              )}
            >
              {replenishmentLocation}
            </ConditionalWrapper>
          ),
        }
      : null,
    getTenantSetting(TenantSettingKey.UiShowShortCode) ?? false
      ? {
          title: 'Short Code',
          settingKey: 'shortCode',
          dataIndex: asKeyOf<SearchPlanningDTO>('short_code'),
        }
      : null,
    isP21
      ? {
          title: 'ABC Class',
          settingKey: 'purchaseClass',
          dataIndex: asKeyOf<SearchPlanningDTO>('purchase_class'),
          sorter: true,
          sortDirections,
        }
      : null,
    config.isOnboarding
      ? {
          title: 'Current Replenishment Method',
          settingKey: 'replenishmentMethod',
          dataIndex: asKeyOf<SearchPlanningDTO>(isP21 ? 'replenishment_method' : 'recurrency_replenishment_method'),
        }
      : null,
    config.isOnboarding
      ? {
          title: <b>Recommended</b>,
          settingKey: 'recommended',
          children: [
            {
              exportTitle: 'Recommended Minimum',
              title: 'Min',
              dataIndex: asKeyOf<SearchPlanningDTO>('recommended_min'),
              align: 'center',
              sorter: true,
              render: (recommendedMin, record) => (
                <div
                  className={css`
                    display: inline-flex;
                  `}
                >
                  {formatNumber(recommendedMin)}
                  <RecommendedMinPopover record={record} />
                </div>
              ),
            },
            {
              exportTitle: 'Recommended Maximum',
              title: 'Max',
              dataIndex: asKeyOf<SearchPlanningDTO>('recommended_max'),
              align: 'center',
              sorter: true,
              render: (recommendedMax, record) => (
                <div
                  className={css`
                    display: inline-flex;
                  `}
                >
                  {formatNumber(recommendedMax)} <RecommendedMaxPopover record={record} />
                </div>
              ),
            },
          ],
        }
      : null,
    !config.isOnboarding
      ? {
          title: <b>{config.isOpOqVariant ? 'Order Point' : 'Minimum'}</b>,
          settingKey: 'minimum',
          children: [
            sortableNumberColumn({
              exportTitle: `Current ${config.isOpOqVariant ? 'Order Point' : 'Minimum'}`,
              title: 'Current',
              settingKey: 'currentMinimum',
              dataIndex: asKeyOf<SearchPlanningDTO>('current_min'),
              sorter: true,
              render: isP21 ? eoqUpToValueWithTooltip : (value) => formatNumber(value),
            }),
            getPlanningRecommendedMinColumn(config),
            sortableChangePercentColumn({
              exportTitle: `${config.isOpOqVariant ? 'Order Point' : 'Minimum'} ∆%`,
              title: '∆%',
              settingKey: 'minimumDelta',
              dataIndex: asKeyOf<SearchPlanningDTO>('min_delta'),
              sorter: true,
            }),
          ],
        }
      : null,
    !config.isOnboarding
      ? config.isOpOqVariant
        ? {
            title: <b>Order Quantity</b>,
            settingKey: 'orderQuantity',
            exportTitle: 'Order Quantity',
            dataIndex: asKeyOf<SearchPlanningDTO>('current_max'),
            sorter: true,
            sortDirections,
          }
        : {
            title: <b>Maximum</b>,
            settingKey: 'maximum',
            children: [
              sortableNumberColumn({
                exportTitle: 'Current Maximum',
                title: 'Current',
                settingKey: 'currentMaximum',
                dataIndex: asKeyOf<SearchPlanningDTO>('current_max'),
                sorter: true,
                render: isP21 ? eoqUpToValueWithTooltip : (value) => formatNumber(value),
              }),
              getPlanningRecommendedMaxColumn(),
              sortableChangePercentColumn({
                exportTitle: 'Maximum ∆%',
                title: '∆%',
                settingKey: 'maximumDelta',
                dataIndex: asKeyOf<SearchPlanningDTO>('max_delta'),
                sorter: true,
              }),
            ],
          }
      : null,
    {
      title: 'UOM',
      settingKey: 'uom',
      dataIndex: asKeyOf<SearchPlanningDTO>('unit_of_measure'),
    },
    {
      title: <b>Demand</b>,
      settingKey: 'demand',
      children: [
        {
          title: (
            <InfoTooltip title="Demand patterns group items based on consistency of orders and variance in demand quantity to forecast in the optimal manner">
              Pattern
            </InfoTooltip>
          ),
          exportTitle: 'Demand Pattern',
          dataIndex: asKeyOf<SearchPlanningDTO>('demand_pattern'),
          render: (demandPattern) => <DemandPatternTooltip demandPattern={demandPattern} />,
        },
        // using forecast_confidence as field for sorting, but showing predictability_tag in UI
        {
          title: (
            <InfoTooltip title="Estimated predictability of an item based on historical trends. Higher predictability leads to more accurate forecasts">
              Predictability
            </InfoTooltip>
          ),
          exportTitle: 'Demand Predictability',
          dataIndex: asKeyOf<SearchPlanningDTO>('forecast_confidence'),
          defaultSortOrder: 'descend' as const,
          sorter: true,
          sortDirections,
          render: (_, record) => record.predictability_tag,
          exportValue: asKeyOf<SearchPlanningDTO>('predictability_tag'),
        },
      ],
    },
    sortableDateColumn({
      title: 'Last Updated',
      settingKey: 'lastUpdated',
      dataIndex: asKeyOf<SearchPlanningDTO>('last_updated_at'),
      sorter: true,
      optional: config.isOnboarding,
    }),
    {
      title: 'Status',
      settingKey: 'planningStatus',
      dataIndex: asKeyOf<SearchPlanningDTO>('planning_status'),
      render: (status: BadgeStatus, record) =>
        config.isOnboarding ? (
          <StatusBadge status={BadgeStatus.Review} labelText="Needs Setup" />
        ) : (
          <StatusBadge
            status={status}
            labelText={
              // override labels for Exclude until date
              record.excluded && record.excluded_until ? (
                <>
                  <InfoTooltip title={`Excluded until ${formatDate(record.excluded_until)}`}>Excluded</InfoTooltip>
                </>
              ) : undefined
            }
          />
        ),
    },
    {
      title: 'Actions',
      settingKey: 'actions',
      render: (_, record) => (
        <div
          className={css`
            display: flex;
            flex-direction: row;
          `}
        >
          {config.isOnboarding && (
            <Button
              type="primary"
              size="small"
              className={css`
                margin-right: 4px;
              `}
              onClick={() => {
                showAsyncModal(UpdateReplenishmentSettingsModal, {
                  record,
                  isOnboardingVariant: true,
                  searchIndexReload: config.searchIndexReload,
                });
              }}
            >
              <ToolOutlined /> Set Up
            </Button>
          )}

          <Dropdown
            overlay={
              <Menu>
                {!config.isOnboarding && (
                  <Menu.Item
                    key="minMax"
                    icon={<CalculatorOutlined />}
                    onClick={() => {
                      showAsyncModal(UpdateReplenishmentSettingsModal, {
                        record,
                        isOnboardingVariant: false,
                        searchIndexReload: config.searchIndexReload,
                      });
                    }}
                  >
                    {config.isOpOqVariant ? 'OP/OQ' : 'Min/Max'}
                  </Menu.Item>
                )}
                <Menu.Item
                  key="safetyStock"
                  icon={<ReconciliationOutlined />}
                  onClick={() => {
                    showAsyncModal(MultiSafetyStockModal, {
                      selection: { type: 'list', items: [record], count: 1 },
                      searchIndexReload: config.searchIndexReload,
                    });
                  }}
                >
                  Safety Stock
                </Menu.Item>
                <Menu.Item
                  key="leadTime"
                  icon={<CalendarOutlined />}
                  onClick={() => {
                    showAsyncModal(MultiLeadTimeModal, {
                      selection: { type: 'list', items: [record], count: 1 },
                      searchIndexReload: config.searchIndexReload,
                    });
                  }}
                >
                  Lead Time
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                  key="exclude"
                  icon={record.excluded ? <CheckCircleOutlined /> : <StopOutlined />}
                  onClick={async () => {
                    const shouldExclude = !record.excluded;
                    const selection: MultiModalSelection = {
                      type: 'list',
                      items: [record],
                      count: 1,
                    };
                    const didChange = await changeRecordExcludedStatus(selection, shouldExclude);
                    if (didChange && config.searchIndexReload) config.searchIndexReload();
                  }}
                >
                  {record.excluded ? 'Include' : 'Exclude'}
                </Menu.Item>
                {shouldShowItemFlagging && (
                  <Menu.Item
                    key="flag"
                    icon={<FlagOutlined />}
                    onClick={async () => {
                      const shouldFlag = !record.flagged;
                      const selection: MultiModalSelection = {
                        type: 'list',
                        items: [record],
                        count: 1,
                      };
                      const didChange = await changeRecordFlagStatus(selection, shouldFlag);
                      if (didChange && config.searchIndexReload) config.searchIndexReload();
                    }}
                  >
                    {record.planning_status === BadgeStatus.Flagged ? 'Resolve Flag' : 'Flag'}
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            <Button
              size="small"
              className={css`
                width: 50px;
              `}
            >
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      ),
    },
    // optional columns
    {
      title: 'Product Group',
      settingKey: 'itemGroup',
      dataIndex: asKeyOf<SearchPlanningDTO>('item_group'),
      optional: true,
    },
    {
      title: 'Safety Stock (Days)',
      settingKey: 'safetyStockDays',
      ...numberColumn({ dataIndex: asKeyOf<SearchPlanningDTO>('safety_stock_value') }),
      sorter: true,
      optional: true,
    },
    {
      title: 'Safety Stock Source',
      settingKey: 'safetyStockSource',
      dataIndex: asKeyOf<SearchPlanningDTO>('safety_stock_type'),
      render: (safetyStockType: P21SafetyStockType) => safetyStockSourceNames[safetyStockType],
      exportValue: (safetyStockType: P21SafetyStockType) => safetyStockSourceNames[safetyStockType],
      optional: true,
    },
    {
      title: 'Lead Time (Days)',
      settingKey: 'leadTimeDays',
      ...numberColumn({ dataIndex: asKeyOf<SearchPlanningDTO>('lead_time') }),
      sorter: true,
      optional: true,
    },
    {
      title: 'Lead Time Source',
      settingKey: 'leadTimesSource',
      dataIndex: asKeyOf<SearchPlanningDTO>('lead_time_source'),
      render: (leadTimeSource: P21LeadTimeSource) => leadTimeSourceNames[leadTimeSource],
      exportValue: (leadTimeSource: P21LeadTimeSource) => leadTimeSourceNames[leadTimeSource],
      optional: true,
    },
    {
      title: 'Order Cycle (Days)',
      settingKey: 'orderCycleDays',
      ...numberColumn({ dataIndex: asKeyOf<SearchPlanningDTO>('order_cycle_days') }),
      sorter: true,
      optional: true,
    },
    {
      title: 'Unit Cost',
      settingKey: 'unitCost',
      dataIndex: asKeyOf<SearchPlanningDTO>('unit_cost'),
      render: (amt) => formatUSD(amt, true),
      sorter: true,
      optional: true,
    },
    {
      title: 'Primary Supplier',
      settingKey: 'primarySupplier',
      dataIndex: asKeyOf<SearchPlanningDTO>('primary_supplier'),
      optional: true,
    },
    shouldAllowExcludedUntil
      ? {
          title: 'Excluded Until',
          settingKey: 'excludedUntil',
          dataIndex: asKeyOf<SearchPlanningDTO>('excluded_until'),
          render: (excludedUntil: string) => formatDate(excludedUntil),
          sorter: true,
          optional: true,
        }
      : null,
    {
      title: 'Item Name',
      settingKey: 'itemName',
      dataIndex: asKeyOf<SearchPlanningDTO>('item_name'),
      sorter: true,
      sortDirections,
      optional: true,
    },
    shouldShowItemFlagging
      ? {
          title: 'Flag Reason',
          settingKey: 'flagReason',
          dataIndex: asKeyOf<SearchPlanningDTO>('flag_reason'),
          render: (flagReason: string | undefined) =>
            flagReason ? <EllipsisTruncatedLabel label={flagReason} maxWidth="30ch" /> : '',
          sorter: true,
          optional: true,
        }
      : null,
  ];
};

export const getPlanningValueFacets = (config: {
  activeTenant: TenantDTO;
  activeUser: ExtendedUser;
  isOnboarding: boolean;
}): (ValueFacet<SearchPlanningDTO> | null)[] => {
  const isPlanningHubAndSpoke = isHubAndSpokeEnabled();
  const isOnboardingEnabled = getTenantSetting(TenantSettingKey.FeatureOnboardingPage) ?? false;
  const isP21 = isTenantErpTypeP21(config.activeTenant.erpType);
  const isNetSuite = isTenantErpTypeNetSuite(config.activeTenant.erpType);
  const shouldShowItemFlagging = shouldShowFeatureFlag(
    config.activeTenant,
    config.activeUser,
    TenantFeatureFlag.FeaturePlanningFlagging,
  );
  return [
    config.isOnboarding
      ? {
          hidden: true,
          field: asKeyOf<SearchPlanningDTO>('recurrency_replenishment_method'),
          defaultValue: RecurrencyReplenishmentMethod.Unsupported,
        }
      : {
          title: 'Status',
          field: asKeyOf<SearchPlanningDTO>('planning_status'),
          icon: <DashboardOutlined />,
          options: [
            {
              label: labelByStatus[BadgeStatus.Updated],
              value: BadgeStatus.Updated as string,
            },
            {
              label: labelByStatus[BadgeStatus.Review],
              value: BadgeStatus.Review as string,
            },
            {
              label: labelByStatus[BadgeStatus.Excluded],
              value: BadgeStatus.Excluded as string,
            },
            shouldShowItemFlagging
              ? {
                  label: labelByStatus[BadgeStatus.Flagged],
                  value: BadgeStatus.Flagged as string,
                }
              : null,
          ].filter(truthy),
        },
    {
      title: `${isPlanningHubAndSpoke ? 'Requirement ' : ''}Location`,
      field: asKeyOf<SearchPlanningDTO>('location'),
      queryPlaceholder: `Search ${isPlanningHubAndSpoke ? 'requirement ' : ''}locations`,
      icon: <EnvironmentOutlined />,
    },
    isPlanningHubAndSpoke
      ? {
          title: 'Replenishment Location',
          field: asKeyOf<SearchPlanningDTO>('replenishment_location'),
          queryPlaceholder: 'Search replenishment locations',
          icon: <EnvironmentOutlined />,
        }
      : null,
    isP21
      ? {
          title: 'ABC Class',
          field: asKeyOf<SearchPlanningDTO>('purchase_class'),
          sortBy: 'alpha',
          icon: <TagOutlined />,
        }
      : null,
    {
      title: 'Primary Supplier',
      field: asKeyOf<SearchPlanningDTO>('primary_supplier'),
      queryPlaceholder: 'Search primary suppliers',
      icon: <ShopOutlined />,
    },
    isP21
      ? {
          title: 'Vendor',
          field: asKeyOf<SearchPlanningDTO>('primary_vendor'),
          queryPlaceholder: 'Search primary vendors',
          icon: <ShopOutlined />,
        }
      : null,
    !isNetSuite
      ? {
          title: 'Product Group',
          field: asKeyOf<SearchPlanningDTO>('item_group'),
          queryPlaceholder: 'Search product groups',
          icon: <AppstoreOutlined />,
        }
      : null,
    getTenantSetting(TenantSettingKey.UiShowShortCode)
      ? {
          title: 'Short Code',
          field: asKeyOf<SearchPlanningDTO>('short_code'),
          queryPlaceholder: 'Search short codes',
          icon: <CodeOutlined />,
        }
      : null,
    getTenantSetting(TenantSettingKey.UiShowBuyerFilter)
      ? {
          title: 'Buyer',
          field: asKeyOf<SearchPlanningDTO>('buyer'),
          queryPlaceholder: 'Search buyers',
          icon: <UserOutlined />,
        }
      : null,
    {
      title: 'Demand Pattern',
      field: asKeyOf<SearchPlanningDTO>('demand_pattern'),
      icon: <StockOutlined />,
      options: demandPatternOptions,
      sortBy: 'none',
    },
    {
      title: 'Predictability',
      field: asKeyOf<SearchPlanningDTO>('predictability_tag'),
      icon: <MonitorOutlined />,
      options: demandPredictabilityOptions,
      sortBy: 'none',
    },
    !config.isOnboarding ? getReplenishmentMethodFacetSettings(isOnboardingEnabled, isP21) : null,
  ];
};

const eoqUpToValueWithTooltip = (value: number, record: SearchPlanningDTO) => (
  <FlexSpace
    alignItems="start"
    gap={0}
    className={css`
      height: 32px;
      justify-content: flex-end;
    `}
  >
    {formatNumber(value)}
    {(record.replenishment_method === ReplenishmentMethod.EOQ ||
      record.replenishment_method === ReplenishmentMethod.UpTo) && (
      <Tooltip
        title={`Min and Max are not used in system as this item is using the ${record.replenishment_method} replenishment method`}
        placement="right"
      >
        <div
          className={css`
            position: relative;
          `}
        >
          <span
            className={css`
              color: ${theme.colors.malibu[600]};
              top: 1px;
              right: 1px;
              margin-left: 2px;
            `}
          >
            *
          </span>
        </div>
      </Tooltip>
    )}
  </FlexSpace>
);

const getPlanningRecommendedMinColumn = (config: {
  isOpOqVariant: boolean;
  openSparseItemGrid?: () => void;
}): PersistedColumn<SearchPlanningDTO> => ({
  exportTitle: `Recommended ${config.isOpOqVariant ? 'Order Point' : 'Minimum'}`,
  title: 'Recommended',
  settingKey: 'recommendedMinimum',
  dataIndex: asKeyOf<SearchPlanningDTO>('recommended_min'),
  align: 'right',
  sorter: true,
  render: (recommendedMin, record) => (
    <div
      className={css`
        display: inline-flex;
      `}
    >
      {formatNumber(recommendedMin)}
      <RecommendedMinPopover
        record={record}
        showOpOqVariant={config.isOpOqVariant}
        openSparseItemGrid={config.openSparseItemGrid}
      />
    </div>
  ),
});

const getPlanningRecommendedMaxColumn = (): PersistedColumn<SearchPlanningDTO> => ({
  exportTitle: 'Recommended Maximum',
  settingKey: 'recommendedMaximum',
  title: `Recommended`,
  dataIndex: asKeyOf<SearchPlanningDTO>('recommended_max'),
  align: 'right',
  sorter: true,
  render: (recommendedMax, record) => (
    <div
      className={css`
        display: inline-flex;
      `}
    >
      {formatNumber(recommendedMax)} <RecommendedMaxPopover record={record} />
    </div>
  ),
});

const getReplenishmentMethodFacetSettings = (isOnboardingEnabled: boolean, shouldShowOpOq?: boolean) => {
  const minMaxOptionValue = isOnboardingEnabled
    ? RecurrencyReplenishmentMethod.MinMax
    : [RecurrencyReplenishmentMethod.MinMax, RecurrencyReplenishmentMethod.Unsupported];

  if (shouldShowOpOq) {
    return {
      title: 'Replenishment Method',
      renderType: FacetRenderType.Pill,
      field: asKeyOf<SearchPlanningDTO>('recurrency_replenishment_method'),
      defaultValue: minMaxOptionValue,
      options: [
        {
          label: RecurrencyReplenishmentMethod.MinMax,
          value: minMaxOptionValue,
        },
        {
          label: RecurrencyReplenishmentMethod.OPOQ,
          value: RecurrencyReplenishmentMethod.OPOQ,
        },
      ],
    };
  }

  return {
    field: asKeyOf<SearchPlanningDTO>('recurrency_replenishment_method'),
    defaultValue: minMaxOptionValue,
    hidden: true,
  };
};
