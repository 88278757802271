import React, { useState } from 'react';

import { css } from '@emotion/css';
import { colors } from 'theme/colors';

import { FlexSpace } from 'components/FlexSpace';
import { Input } from 'components/Input/Input';

import { ItemLocationSettingChangeModal } from './ItemLocationSettingChangeModal';
import { MultiModalSelection } from './utils';

export function FlagItemLocationModal({
  selection,
  flagged,
  onClose,
}: {
  selection: MultiModalSelection;
  flagged: boolean;
  onClose: (confirmData?: { comment: string }) => void;
}) {
  const [comment, setComment] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(true);
  return (
    <ItemLocationSettingChangeModal
      selection={selection}
      set={flagged}
      bulkUpdateType={{ type: 'flagged', flagged, comment }}
      description={`Status will be set as
        ${
          flagged
            ? `Flagged, and will not be set as Needs Review in the future until the flag is resolved.`
            : 'Needs Review or OK in the future.'
        }`}
      customization={
        <FlexSpace
          gap={4}
          direction="column"
          className={css`
            margin-top: 16px;
          `}
        >
          <span
            className={css`
              font-weight: bold;
            `}
          >
            Comment
          </span>
          <Input
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Please describe why this is being changed."
            maxLength={500}
          />
          {!isValid && (
            <span
              className={css`
                color: ${colors.danger[500]};
              `}
            >
              A comment is required
            </span>
          )}
        </FlexSpace>
      }
      // A comment is required for this to be able to submit
      validator={() => {
        const isValid = !!comment;

        setIsValid(isValid);

        return isValid;
      }}
      onSubmit={() => onClose({ comment })}
      onClose={() => onClose()}
    />
  );
}
