import React, { useEffect, useMemo, useState } from 'react';

import { AppstoreOutlined, DashboardOutlined, EnvironmentOutlined, ShopOutlined } from '@ant-design/icons';
import { PurchaseTargetLineStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { ISODateStr } from '@recurrency/core-api-schema/dist/common/types';
import { AllocatedOrderLinePayload } from '@recurrency/core-api-schema/dist/purchaseOrders/createPurchaseOrder';
import { Radio } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import { fuzzyFilter } from 'fuzzbunny';
import { useDebounce } from 'use-debounce/lib';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { ResetDropdown, ResetDropdownMenuItem } from 'components/Dropdown/ResetDropdown';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpacer } from 'components/FlexSpacer';
import { RadioGroup } from 'components/Radio';
import { ProductGroupMultiSelect } from 'components/recipes/select/ProductGroupMultiSelect';
import { ResultCount } from 'components/ResultCount';
import { SearchInput } from 'components/SearchInput';
import { SplitPage } from 'components/SplitPage';

import { useEventListener } from 'hooks/useEventListener';

import { arrUnique } from 'utils/array';
import { formatIdNameObj } from 'utils/formatting';
import { useHashState } from 'utils/routes';
import { getTenantSetting } from 'utils/tenantSettings';

import {
  PurchaseTargetLinesHashState,
  PurchasingLineInput,
  SupplierLocationWTarget,
  PurchasingLineTransfer,
  PurchaseGroupHashState,
} from 'types/hash-state';

import { GenericTargetLineDTO, GenericTargetLineRow, PurchaseTargetLineRow } from '../types';
import { getHubAndSpokePOSummaries, getLineStatusCounts, getMergedPurchaseLines, getPOSummaries } from '../utils';
import { PurchaseTargetLinesTableColumns } from './PurchaseTargetLinesTableColumns';
import { ItemDetailsSidePane } from './sidePane/ItemDetailsSidePane';
import { POSummarySidePaneSidePane } from './sidePane/POSummarySidePane';
import { additionalPurchasingQty, getUniqueItemGroupOptions, usePurchasingStatuses } from './utils';

enum TargetLinesFilter {
  All,
  Transferable,
  Purchasing,
}

export function PurchaseTargetLinesTable({
  supplierLocations,
  targetLines,
  purchasingLinesById,
  linesModalMode = false,
  onPurchasingLinesChange,
  onMinMaxChange,
  isPurchasingHubAndSpoke,
  isPlanningHubAndSpoke,
  purchaseGroups,
}: {
  supplierLocations: SupplierLocationWTarget[];
  targetLines: GenericTargetLineDTO[];
  purchasingLinesById: Obj<PurchasingLineInput>;
  linesModalMode?: boolean;
  onPurchasingLinesChange: (purchasingLinesById: Obj<PurchasingLineInput>) => void;
  onMinMaxChange?: () => void;
  isPurchasingHubAndSpoke?: boolean;
  isPlanningHubAndSpoke?: boolean;
  purchaseGroups?: PurchaseGroupHashState[];
}) {
  const [hashState, updateHashState] = useHashState<PurchaseTargetLinesHashState>();
  const [focusedLine, setFocusedLine] = useState<GenericTargetLineRow | undefined>();
  const [locationIdsFilter, setLocationIdsFilter] = useState<string[]>([]);
  const [supplierIdsFilter, setSupplierIdsFilter] = useState<string[]>([]);
  const [itemGroupIdsFilter, setItemGroupIdsFilter] = useState<string[]>([]);
  const [purchaseGroupsIdsFilter, setPurchaseGroupsIdsFilter] = useState<string[]>([]);
  const [replenishmentLocationIdsFilter, setReplenishmentLocationIdsFilter] = useState<string[]>([]);
  const [linesFilter, setLinesFilter] = useState<TargetLinesFilter>(
    linesModalMode ? TargetLinesFilter.Purchasing : TargetLinesFilter.All,
  );
  const [searchQuery, setSearchQuery] = useState(hashState.query || '');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);
  const [sortField, setSortField] = useState('status');
  const [sortDir, setSortDir] = useState<SortOrder>('descend');
  const { shouldCreateSpecialOrderLines, defaultStatusFilters, filteredStatuses } = usePurchasingStatuses();
  const statusesFilter = hashState.statuses ?? defaultStatusFilters;
  const purchasingCreateTransferFlagEnabled = getTenantSetting(TenantSettingKey.FeatureCreateTransfers);
  // weight and volume target show extra 'Unit Weight/Volume', 'Ext Weight/Volume' columns.
  const targetType = supplierLocations[0]?.targetType;
  const uniqueLocations = arrUnique(
    supplierLocations.map((sl) => formatIdNameObj({ foreignId: sl.locationId, name: sl.locationName })),
  );
  const purchaseLocations = supplierLocations.filter((l) => l.locationId === l.purchasingLocationId);
  const uniqueSuppliers = arrUnique(
    supplierLocations.map((sl) => formatIdNameObj({ foreignId: sl.supplierId, name: sl.supplierName })),
  );

  const allLines = useMemo(
    () => getMergedPurchaseLines(targetLines, purchasingLinesById),
    [purchasingLinesById, targetLines],
  );
  const poSummaries = useMemo(
    () =>
      isPurchasingHubAndSpoke && purchaseGroups
        ? getHubAndSpokePOSummaries(allLines, supplierLocations, purchaseGroups)
        : getPOSummaries(allLines, supplierLocations, shouldCreateSpecialOrderLines),
    [allLines, isPurchasingHubAndSpoke, supplierLocations, purchaseGroups, shouldCreateSpecialOrderLines],
  );

  const filteredLines = useMemo(() => {
    const lines = fuzzyFilter(
      allLines.filter(
        (line) =>
          (linesFilter === TargetLinesFilter.Purchasing
            ? line.userQtyToOrder > 0 || additionalPurchasingQty(line) > 0
            : true) &&
          (linesFilter === TargetLinesFilter.Transferable ? line.potentialTransfer : true) &&
          (statusesFilter.length === 0 || statusesFilter.includes(line.status)) &&
          (supplierIdsFilter.length === 0 || supplierIdsFilter.includes(line.supplierId)) &&
          (itemGroupIdsFilter.length === 0 || itemGroupIdsFilter.includes(line.itemGroupId ?? '-')) &&
          (locationIdsFilter.length === 0 ||
            locationIdsFilter.includes(isPurchasingHubAndSpoke ? line.purchaseLocationId! : line.locationId!)) &&
          (purchaseGroupsIdsFilter.length === 0 ||
            (line.groupId != null && purchaseGroupsIdsFilter.includes(line.groupId))) &&
          (replenishmentLocationIdsFilter.length === 0 ||
            (line.replenishmentLocationId && replenishmentLocationIdsFilter.includes(line.replenishmentLocationId))),
      ),
      debouncedSearchQuery,
      {
        fields: ['itemId', 'itemName'],
      },
    ).map((i) => i.item);

    sortTargetLineRows(lines, sortField, sortDir, filteredStatuses, shouldCreateSpecialOrderLines);
    return lines;
  }, [
    allLines,
    debouncedSearchQuery,
    sortField,
    sortDir,
    linesFilter,
    statusesFilter,
    supplierIdsFilter,
    itemGroupIdsFilter,
    locationIdsFilter,
    purchaseGroupsIdsFilter,
    isPurchasingHubAndSpoke,
    filteredStatuses,
    shouldCreateSpecialOrderLines,
    replenishmentLocationIdsFilter,
  ]);

  const lineStatusCounts = useMemo(() => getLineStatusCounts(filteredLines), [filteredLines]);
  const purchasingLines = useMemo(
    () => allLines.filter((line) => line.userQtyToOrder > 0 || additionalPurchasingQty(line) > 0),
    [allLines],
  );
  const transferableLines = useMemo(() => allLines.filter((line) => line.potentialTransfer), [allLines]);

  useEffect(() => {
    updateHashState({ query: debouncedSearchQuery });
  }, [debouncedSearchQuery, updateHashState]);

  useEffect(() => {
    // if targetLines are reloaded, remember focused line item
    if (focusedLine) {
      setFocusedLine(allLines.find((line) => line.key === focusedLine.key));
    }
  }, [allLines]); // eslint-disable-line react-hooks/exhaustive-deps

  // clear focused line item when clicking outside of lines table and side pane
  useEventListener(
    document.body,
    'click',
    (ev) => {
      /**  DatePicker mounts to <body> so it registers as an "outside click", causing sidepanel and table to re-render,
       * impeding datepicker functionality. If the click is in datepicker dropdown, exit early. */
      const isDatePickerClick = (ev.target as HTMLElement).closest('.ant-picker-dropdown') !== null;
      if (isDatePickerClick) return;

      const lineInfoEls = Array.from(
        document.querySelectorAll('.ant-table, div.SidePane, div.ant-modal-wrap:not(.purchase-target-lines-modal)'),
      );
      if (lineInfoEls.every((el) => !el.contains(ev.target as Node))) {
        setFocusedLine(undefined);
      }
    },
    {
      // evaluate at capture (instead of bubbling phase) so if a component stops event propagation
      // we still know where the user clicked
      capture: true,
    },
  );

  const handleQtyToOrderChange = (value: number, line: GenericTargetLineRow) => {
    const lineInput = purchasingLinesById[line.key];
    if (value === 0 && lineInput) {
      onPurchasingLinesChange({ ...purchasingLinesById, [line.key]: { ...lineInput, qtyToOrder: undefined } });
    } else if (lineInput?.qtyToOrder !== value) {
      onPurchasingLinesChange({ ...purchasingLinesById, [line.key]: { ...lineInput, qtyToOrder: value } });
    }
  };

  /**
   * Handles the selection of special orders for a line
   * @param line - The target line to update.
   * @param userQtyForSpecialOrders - The current quantity for special orders for the line.
   * @param orderEntries - The linked sales order lines to select from.
   */
  const handleSpecialOrderSelection = (
    line: PurchaseTargetLineRow,
    updatedQtyForSpecialOrders: number,
    orderEntries: AllocatedOrderLinePayload[] | undefined,
  ) => {
    onPurchasingLinesChange({
      ...purchasingLinesById,
      [line.key]: {
        ...purchasingLinesById[line.key],
        qtyForSpecialOrders: updatedQtyForSpecialOrders,
        specialOrderLinks: orderEntries,
      },
    });
  };

  const handleTransferUpdate = (newTransfers: PurchasingLineTransfer[], line: PurchaseTargetLineRow) => {
    const existingLine = purchasingLinesById[line.key];

    // Calculate prevTotalQtyToTransfer from existingLine.transfers
    const prevTotalQtyToTransfer = existingLine?.transfers
      ? existingLine.transfers.reduce((total, transfer) => total + transfer.qtyToTransfer, 0)
      : 0;

    const newTotalQtyToTransfer = newTransfers.reduce((total, transfer) => total + transfer.qtyToTransfer, 0);
    const qtyToOrderAdjustment = prevTotalQtyToTransfer - newTotalQtyToTransfer;

    // Default currentQtyToOrder to 0 if existingLine.qtyToOrder is undefined
    const currentQtyToOrder = existingLine?.qtyToOrder ?? 0;

    if (existingLine) {
      const updatedQtyToOrder = Math.max(currentQtyToOrder + qtyToOrderAdjustment, 0);
      onPurchasingLinesChange({
        ...purchasingLinesById,
        [line.key]: {
          ...existingLine,
          qtyToOrder: updatedQtyToOrder,
          // Set transfers to undefined if newTransfers is empty, otherwise update with newTransfers
          transfers: newTransfers.length > 0 ? newTransfers : undefined,
        },
      });
    } else if (newTransfers.length === 0) {
      onPurchasingLinesChange({ ...purchasingLinesById, [line.key]: { transfers: undefined } });
    } else if (newTransfers.length > 0) {
      // For non recommended to order items, we still want to update newTransfers
      onPurchasingLinesChange({ ...purchasingLinesById, [line.key]: { transfers: newTransfers } });
    }
  };

  const handleUnitCostChange = (value: number, line: GenericTargetLineRow) => {
    const lineInput = purchasingLinesById[line.key];
    // unit cost editing is only allowed if qtyToOrder > 0, hence lineInput should always be defined
    if (lineInput && lineInput.unitCost !== value) {
      onPurchasingLinesChange({ ...purchasingLinesById, [line.key]: { ...lineInput, unitCost: value } });
    }
  };

  const handleRequiredDateChange = (value: ISODateStr | undefined, line: GenericTargetLineRow) => {
    const lineInput = purchasingLinesById[line.key];

    if (lineInput && lineInput.requiredDate !== value) {
      onPurchasingLinesChange({ ...purchasingLinesById, [line.key]: { ...lineInput, requiredDate: value } });
    }
  };

  const getClearedLinesById = (clearTransfersOnly = false, clearPurchasing = false, clearSpecialsOnly = false) => {
    // clear out any lines matching selection of supplierLocations
    const clearedPurchasingLinesById = { ...purchasingLinesById };
    for (const lineKey of Object.keys(clearedPurchasingLinesById)) {
      // lineKey's first part is the group ID for hub and spoke items
      const [lineGroupId] = lineKey.split('.');
      const [lineSupplierId, lineLocationId] = lineKey.split('.');

      for (const supplierLocation of supplierLocations) {
        if (
          (supplierLocation.supplierId === lineSupplierId && supplierLocation.locationId === lineLocationId) ||
          (isPurchasingHubAndSpoke && supplierLocation.groupId === lineGroupId)
        ) {
          if (clearTransfersOnly) {
            clearedPurchasingLinesById[lineKey].transfers = [];
            break;
          }
          if (clearSpecialsOnly) {
            clearedPurchasingLinesById[lineKey].qtyForSpecialOrders = 0;
            clearedPurchasingLinesById[lineKey].specialOrderLinks = [];
            break;
          }

          const hasTransfers = (clearedPurchasingLinesById[lineKey].transfers || []).length > 0;
          if (clearPurchasing && hasTransfers) {
            clearedPurchasingLinesById[lineKey].qtyToOrder = 0;
            clearedPurchasingLinesById[lineKey].qtyForSpecialOrders = 0;
            clearedPurchasingLinesById[lineKey].specialOrderLinks = [];
            break;
          }

          delete clearedPurchasingLinesById[lineKey];
          break;
        }
      }
    }
    return clearedPurchasingLinesById;
  };

  const handleClearAllLinesClick = () => {
    onPurchasingLinesChange(getClearedLinesById());
  };

  const handleClearPurchasingLinesClick = () => {
    onPurchasingLinesChange(
      getClearedLinesById(/* Clear transfers only */ false, /* Clear purchasing including specials */ true),
    );
  };
  const handleClearTransferLinesClick = () => {
    onPurchasingLinesChange(
      getClearedLinesById(
        /* Clear transfers only */ true,
        /* Clear purchasing including specials */ false,
        /* Clear specials only */ false,
      ),
    );
  };
  const handleClearAllSpecialsClick = () => {
    onPurchasingLinesChange(
      getClearedLinesById(
        /* Clear transfers only */ false,
        /* Clear purchasing including specials */ false,
        /* Clear specials only */ true,
      ),
    );
  };

  const handleResetLinesToRecommendedClick = () => {
    const newPurchasingLinesById = getClearedLinesById();
    for (const line of allLines) {
      for (const supplierLocation of supplierLocations) {
        if (
          ((line.qtyToOrder > 0 || line.qtyForSpecialOrders > 0) &&
            supplierLocation.supplierId === line.supplierId &&
            supplierLocation.locationId === line.locationId) ||
          (isPurchasingHubAndSpoke && supplierLocation.groupId === line.groupId)
        ) {
          newPurchasingLinesById[line.key] = {
            qtyToOrder: line.qtyToOrder,
            qtyForSpecialOrders: line.qtyForSpecialOrders,
            specialOrderLinks: [],
          };
        }
      }
    }
    onPurchasingLinesChange(newPurchasingLinesById);
  };

  const resetLinesDropdownMenuItems: ResetDropdownMenuItem[] = [
    {
      title: 'Clear All Purchasing',
      onClick: handleClearPurchasingLinesClick,
    },
    {
      title: 'Clear All Transfers',
      onClick: handleClearTransferLinesClick,
      hidden: isPurchasingHubAndSpoke || !purchasingCreateTransferFlagEnabled,
    },
    {
      title: 'Clear All Specials',
      onClick: handleClearAllSpecialsClick,
      hidden: !shouldCreateSpecialOrderLines,
    },
    {
      title: 'Clear All',
      onClick: handleClearAllLinesClick,
      hidden: isPurchasingHubAndSpoke,
    },
    {
      title: 'Reset All to Recommended',
      onClick: handleResetLinesToRecommendedClick,
    },
  ];

  return (
    <>
      <FilterBarBox dividerLine>
        <RadioGroup
          value={linesFilter}
          onChange={({ target: { value } }) => {
            setLinesFilter(value);
          }}
        >
          <Radio.Button value={TargetLinesFilter.All}>
            All <b>({allLines.length})</b>
          </Radio.Button>
          {getTenantSetting(TenantSettingKey.FeatureCreateTransfers) && !isPurchasingHubAndSpoke && (
            <Radio.Button value={TargetLinesFilter.Transferable}>
              Transferable <b>({transferableLines.length})</b>
            </Radio.Button>
          )}
          <Radio.Button value={TargetLinesFilter.Purchasing}>
            Purchasing <b>({purchasingLines.length})</b>
          </Radio.Button>
        </RadioGroup>
        <AsyncMultiSelect
          label="Status"
          icon={<DashboardOutlined />}
          selectedValues={statusesFilter}
          onSelectedValuesChange={(values) => updateHashState({ statuses: values as PurchaseTargetLineStatus[] })}
          selectProps={{ options: filteredStatuses.map((status) => ({ label: status, value: status })) }}
        />
        {uniqueLocations.length > 1 || (isPurchasingHubAndSpoke && purchaseLocations.length > 1) ? (
          <AsyncMultiSelect
            label={`${isPurchasingHubAndSpoke ? 'Purchase ' : ''}Location`}
            icon={<EnvironmentOutlined />}
            queryPlaceholder="Search locations"
            selectedValues={locationIdsFilter}
            onSelectedValuesChange={(values) => setLocationIdsFilter(values)}
            selectProps={{
              options: arrUnique(
                (isPurchasingHubAndSpoke ? purchaseLocations : supplierLocations).map((sl) => ({
                  label: `${sl.locationId}: ${sl.locationName}`,
                  value: sl.locationId,
                })),
                'value',
              ),
            }}
          />
        ) : null}
        {uniqueSuppliers.length > 1 ? (
          <AsyncMultiSelect
            label="Supplier"
            icon={<ShopOutlined />}
            queryPlaceholder="Search suppliers"
            selectedValues={supplierIdsFilter}
            onSelectedValuesChange={(values) => setSupplierIdsFilter(values)}
            selectProps={{
              options: arrUnique(
                supplierLocations.map((sl) => ({
                  label: `${sl.supplierId}: ${sl.supplierName}`,
                  value: sl.supplierId,
                })),
                'value',
              ),
            }}
          />
        ) : null}

        <ProductGroupMultiSelect
          selectedValues={itemGroupIdsFilter}
          onSelectedValuesChange={(values: string[]) => setItemGroupIdsFilter(values)}
          selectProps={{
            options: getUniqueItemGroupOptions(targetLines),
          }}
        />
        {isPurchasingHubAndSpoke && purchaseGroups && purchaseGroups.length > 1 && (
          <AsyncMultiSelect
            label="Purchase Group"
            icon={<AppstoreOutlined />}
            selectedValues={purchaseGroupsIdsFilter}
            queryPlaceholder="Search purchase groups"
            onSelectedValuesChange={(values: string[]) => setPurchaseGroupsIdsFilter(values)}
            selectProps={{
              options: purchaseGroups.map((pg) => ({
                label: pg.groupName,
                value: pg.groupId,
              })),
            }}
          />
        )}
        {isPlanningHubAndSpoke && (
          <AsyncMultiSelect
            label="Replenishment Location"
            icon={<EnvironmentOutlined />}
            selectedValues={replenishmentLocationIdsFilter}
            queryPlaceholder="Search replenishment locations"
            onSelectedValuesChange={(values: string[]) => setReplenishmentLocationIdsFilter(values)}
            selectProps={{
              options: arrUnique(
                allLines
                  .filter(
                    (line): line is typeof line & { replenishmentLocationId: string } =>
                      !!line.replenishmentLocationId && !!line.replenishmentLocationName,
                  )
                  .map((line) => ({
                    label: `${line.replenishmentLocationId}: ${line.replenishmentLocationName}`,
                    value: line.replenishmentLocationId,
                  })),
                'value',
              ),
            }}
          />
        )}
        <FlexSpacer />
        <ResetDropdown menuItems={resetLinesDropdownMenuItems} />
        <SearchInput
          query={searchQuery}
          onQueryChange={(value) => setSearchQuery(value)}
          placeholder="Search Item ID / Description"
        />
        <ResultCount count={filteredLines.length} />
      </FilterBarBox>
      <SplitPage
        left={
          <PurchaseTargetLinesTableColumns
            isPurchasingHubAndSpoke={isPurchasingHubAndSpoke}
            isPlanningHubAndSpoke={isPlanningHubAndSpoke}
            filteredLines={filteredLines}
            focusedLine={focusedLine}
            uniqueSuppliers={uniqueSuppliers}
            uniqueLocations={uniqueLocations}
            targetType={targetType}
            setSortDir={setSortDir}
            setSortField={setSortField}
            setFocusedLine={setFocusedLine}
            handleQtyToOrderChange={handleQtyToOrderChange}
            getTotalUserTransfersQty={getTotalUserTransfersQty}
            handleTransferUpdate={handleTransferUpdate}
            handleUnitCostChange={handleUnitCostChange}
            handleRequiredDateChange={handleRequiredDateChange}
            handleSpecialOrderSelection={handleSpecialOrderSelection}
          />
        }
        right={
          focusedLine ? (
            <ItemDetailsSidePane
              isPurchasingHubAndSpoke={isPurchasingHubAndSpoke}
              purchaseGroup={purchaseGroups?.find((pg) => pg.groupId === focusedLine.groupId)}
              itemInfo={focusedLine}
              allowMinMaxEdit={!!onMinMaxChange}
              onMinMaxChange={onMinMaxChange}
            />
          ) : (
            <POSummarySidePaneSidePane
              lineStatusCounts={lineStatusCounts}
              poSummaries={poSummaries}
              uniqueSuppliers={uniqueSuppliers}
            />
          )
        }
      />
    </>
  );
}

// we use a custom sorting function in multi location mode
// because we need to sort by multiple fields to preserve order of items and locations.
function sortTargetLineRows(
  targetLineRows: GenericTargetLineRow[],
  sortField: string,
  sortDir: SortOrder | undefined,
  filteredStatuses: PurchaseTargetLineStatus[],
  shouldCreateSpecialOrderLines: boolean,
): GenericTargetLineRow[] {
  // early exit if nothing to sort
  if (targetLineRows.length === 0) return targetLineRows;

  const sortDirMultiplier = sortDir === 'descend' ? -1 : 1;
  const sortFn = getSortFunctionForSoftField(
    sortField,
    targetLineRows[0],
    filteredStatuses,
    shouldCreateSpecialOrderLines,
  );

  // sort by field, then itemId asc, then locationId asc
  return targetLineRows.sort((a, b) => {
    const sortCompare = sortFn(a, b) * sortDirMultiplier;
    if (sortCompare !== 0) return sortCompare;

    const itemIdCompare = a.itemId.localeCompare(b.itemId);
    if (itemIdCompare !== 0) return itemIdCompare;

    if (a.locationId && b.locationId) {
      const locationIdCompare = a.locationId.localeCompare(b.locationId);
      if (locationIdCompare !== 0) return locationIdCompare;
    }

    return 0;
  });
}

function getSortFunctionForSoftField(
  sortField: string,
  firstPurchaseRow: GenericTargetLineRow,
  filteredStatuses: PurchaseTargetLineStatus[],
  shouldCreateSpecialOrderLines: boolean,
): (a: GenericTargetLineRow, b: GenericTargetLineRow) => number {
  // extCost is a computed field so we need to sort by (userQtyToOrder * unitCost)
  if (sortField === 'extCost') {
    return (a, b) =>
      (a.userQtyToOrder + additionalPurchasingQty(a)) * a.userUnitCost -
      (b.userQtyToOrder + additionalPurchasingQty(b)) * b.userUnitCost;
  }
  // userTransfers is a computed field so we need to sort by sum of all the transfer lines
  if (sortField === 'userTransfers') {
    return (a, b) => getTotalUserTransfersQty(a.userTransfers) - getTotalUserTransfersQty(b.userTransfers);
  }
  if (sortField === 'status') {
    return (a, b) => {
      const totalQtyA = a.qtyToOrder + (shouldCreateSpecialOrderLines ? a.qtyForSpecialOrders : 0);
      const totalQtyB = b.qtyToOrder + (shouldCreateSpecialOrderLines ? b.qtyForSpecialOrders : 0);
      // Primary sort: qtyToOrder > 0, and special lines if enabled
      const bHasQty = totalQtyB > 0 ? 1 : 0;
      const aHasQty = totalQtyA > 0 ? 1 : 0;
      const qtyCompare = aHasQty - bHasQty;
      // If qtyToOrder comparison yields different results, use that
      if (qtyCompare !== 0) return qtyCompare;

      // multiply index by -1 so that lower index status results in a higher value
      const secondaryCompare = filteredStatuses.indexOf(a.status) * -1 - filteredStatuses.indexOf(b.status) * -1;
      if (secondaryCompare !== 0) return secondaryCompare;

      return totalQtyA - totalQtyB;
    };
  }
  const sortKey = sortField as keyof GenericTargetLineRow; // casting as typed field to appease typescript
  return typeof firstPurchaseRow[sortKey] === 'number'
    ? (a, b) => (a[sortKey] as number) - (b[sortKey] as number)
    : (a, b) => ((a[sortKey] as string) || '').localeCompare(b[sortKey] as string);
}

function getTotalUserTransfersQty(transfers?: PurchasingLineTransfer[]): number {
  return (transfers || []).reduce((total, transfer) => total + transfer.qtyToTransfer, 0);
}
