import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import {
  AimOutlined,
  DashboardOutlined,
  EnvironmentOutlined,
  ExceptionOutlined,
  RightOutlined,
  ShopOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import {
  PurchaseTargetStatus,
  SpecialOrderFilterOptions,
  SupplierTargetTypes,
  TargetGroupType,
} from '@recurrency/core-api-schema/dist/common/enums';
import { PurchaseTargetDTO } from '@recurrency/core-api-schema/dist/purchasing/getPurchaseTargets';
import { Radio } from 'antd';
import { theme } from 'theme';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { MultiSelectOption } from 'components/AsyncSelect/types';
import { convertToMultiSelectProps } from 'components/AsyncSelect/useAsyncMultiSelectProps';
import {
  useLocationsSelectProps,
  usePurchasingBuyersSelectProps,
  useSuppliersSelectProps,
} from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { Button } from 'components/Button';
import { ActionButton } from 'components/Button/ActionButton';
import { Container } from 'components/Container';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpace } from 'components/FlexSpace';
import { FlexSpacer } from 'components/FlexSpacer';
import { PageHeader } from 'components/PageHeader';
import { RadioGroup } from 'components/Radio/RadioGroup';
import { ColumnChooserSection } from 'components/recipes/ColumnChooserSection';
import { EditTargetActionButton } from 'components/recipes/EditTargetActionButton';
import { StatusBadge } from 'components/recipes/StatusBadge';
import { ResultCount } from 'components/ResultCount';
import { SelectionCountAndClear } from 'components/SelectionCountAndClear';
import { IconTooltip } from 'components/Tooltip/IconTooltip';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { truthy } from 'utils/boolean';
import { formatNumber, formatPercent, formatUSD } from 'utils/formatting';
import { objPickKeys } from 'utils/object';
import { routes, useHashState } from 'utils/routes';
import { PersistedColumn, ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';
import { useUserViewSettingsState } from 'utils/tableAndSidePaneSettings/useUserViewSettingsState';
import {
  asKeyOf,
  sortableDateColumn,
  sortableDollarColumn,
  sortableIdColumn,
  sortableNumberColumn,
  sortablePercentColumn,
  sortDirections,
  withSortedColumn,
} from 'utils/tables';

import { PurchaseTargetsHashState } from 'types/hash-state';

import { arrUnique } from '../../../utils/array';
import {
  editButtonOnClick,
  recordToSupplierLocationWTarget,
  specialOrderFilterOptions,
  TargetTypeLabels,
  TargetTypesFilterOptions,
} from '../utils';
import { usePurchasingStatuses } from './PurchaseTargetLinesPage/utils';
import { SortResponse } from './types';

const statusOptions: MultiSelectOption[] = [
  { label: 'All', value: '' },
  { label: 'Ready', value: PurchaseTargetStatus.Ready },
  { label: 'Not Ready', value: PurchaseTargetStatus.NotReady },
];

export function PurchaseTargetsPage() {
  const history = useHistory();
  const [hashState, updateHashState] = useHashState<PurchaseTargetsHashState>();
  const {
    targetGroupType = TargetGroupType.Targets,
    targetTypes,
    selectedSupplierLocations = [],
    locationIds = [],
    supplierIds = [],
    buyerIds = [],
    specialOrder = [],
  } = hashState;
  const locationsSelectProps = useLocationsSelectProps({});
  const suppliersSelectProps = useSuppliersSelectProps();
  const buyerSelectProps = usePurchasingBuyersSelectProps();
  const { shouldShowSpecialOrderWarning, shouldCreateSpecialOrderLines } = usePurchasingStatuses();
  const initialSortState = getTargetGroupSort(targetGroupType);

  const tableProps = useCoreApiTableProps({
    schema: schemas.purchasing.getPurchaseTargets,
    queryParams: {
      targetGroupType,
      filter: {
        locationIds,
        supplierIds,
        buyerIds,
        status: hashState.status,
        targetTypes,
        specialOrder,
      },
    },
    initialState: initialSortState,
  });
  const { items: targetsData, reload: reloadPurchaseTargets, setPage, setSortBy, setSortDir } = tableProps;

  /** the purpose of this string is to memoize these values so that the useEffect below doesn't trigger when the values are the same.
   * The arrays in hashState are recreated per render and considered new objects, causing re-renders if added directly to the useEffect dependency array */
  const stringifyHashState = JSON.stringify(
    objPickKeys(
      hashState,
      'status',
      'targetGroupType',
      'supplierIds',
      'locationIds',
      'buyerIds',
      'targetTypes',
      'specialOrder',
    ),
  );

  useEffect(() => {
    setPage(1);
  }, [stringifyHashState, setPage]);

  const handleTargetValueChange = () => {
    reloadPurchaseTargets();
  };

  const setTargetGroupTypeFilter = (newTargetGroupType: TargetGroupType | undefined) => {
    updateHashState({ targetGroupType: newTargetGroupType });
    const newSort = getTargetGroupSort(newTargetGroupType);
    setSortBy?.(newSort.sortBy);
    setSortDir?.(newSort.sortDir);
    updateHashState({ targetTypes: undefined });
  };

  const tableColumns: PersistedColumn<PurchaseTargetDTO>[] = [
    sortableIdColumn({
      title: 'Supplier',
      dataIndex: asKeyOf<PurchaseTargetDTO>('supplierId'),
      sorter: true,
      settingKey: 'supplierId',
      required: true,
      render: (_, record: PurchaseTargetDTO) => `${record.supplierId}: ${record.supplierName}`,
    }),
    sortableIdColumn({
      title: 'Location',
      dataIndex: asKeyOf<PurchaseTargetDTO>('purchaseLocationId'),
      sorter: true,
      settingKey: 'purchaseLocationId',
      required: true,
      render: (_, record: PurchaseTargetDTO) => `${record.purchaseLocationId}: ${record.purchaseLocationName}`,
    }),
    sortableDateColumn({
      title: 'Last Ordered',
      dataIndex: asKeyOf<PurchaseTargetDTO>('lastOrderDate'),
      align: 'right',
      sorter: true,
      settingKey: 'lastOrderDate',
    }),
    ...(targetGroupType === TargetGroupType.ReviewCycle
      ? [
          sortableDateColumn({
            title: 'Last Reviewed',
            dataIndex: asKeyOf<PurchaseTargetDTO>('lastReviewDate'),
            align: 'right' as const,
            sorter: true,
            settingKey: 'lastReviewDate',
          }),
          sortableNumberColumn({
            title: 'Review Cycle (Days)',
            dataIndex: asKeyOf<PurchaseTargetDTO>('reviewCycleDays'),
            align: 'right' as const,
            sorter: true,
            settingKey: 'reviewCycleDays',
            render: (reviewCycleDays: number, record: PurchaseTargetDTO) => (
              <EditTargetActionButton
                value={reviewCycleDays}
                onClick={() => editButtonOnClick(record.supplierId, record.purchaseLocationId, handleTargetValueChange)}
              />
            ),
          }),
          sortableDateColumn({
            title: 'Next Review',
            dataIndex: asKeyOf<PurchaseTargetDTO>('nextReviewDate'),
            align: 'right' as const,
            sorter: true,
            settingKey: 'nextReviewDate',
          }),
        ]
      : [
          {
            title: 'Current',
            dataIndex: asKeyOf<PurchaseTargetDTO>('currentValue'),
            sorter: true,
            sortDirections,
            align: 'right' as const,
            settingKey: 'currentValue',
            render: (currentValue: number, record: PurchaseTargetDTO) =>
              record.targetType === 'dollars' ? formatUSD(currentValue) : formatNumber(currentValue, 0),
          },
          {
            title: 'Target',
            dataIndex: asKeyOf<PurchaseTargetDTO>('targetValue'),
            sorter: true,
            sortDirections,
            align: 'right' as const,
            settingKey: 'targetValue',
            render: (targetValue: number, record: PurchaseTargetDTO) => (
              <EditTargetActionButton
                value={record.targetType === 'dollars' ? formatUSD(targetValue) : formatNumber(targetValue)}
                onClick={() => editButtonOnClick(record.supplierId, record.purchaseLocationId, handleTargetValueChange)}
              />
            ),
          },
          {
            title: 'Target Type',
            dataIndex: asKeyOf<PurchaseTargetDTO>('targetType'),
            align: 'right' as const,
            settingKey: 'targetType',
            render: (targetTypeVal: SupplierTargetTypes | null) =>
              targetTypeVal ? TargetTypeLabels[targetTypeVal] : '-',
          },
          sortablePercentColumn({
            title: 'Target %',
            dataIndex: asKeyOf<PurchaseTargetDTO>('goalPercent'),
            settingKey: 'goalPercent',
            render: (val: number) => formatPercent(val, 0),
            sorter: true,
          }),
        ]),
    {
      title: 'Status',
      dataIndex: asKeyOf<PurchaseTargetDTO>('status'),
      settingKey: 'status',
      render: (status) => <StatusBadge status={status} />,
    },
    sortableDollarColumn({
      title: 'PO Cost',
      dataIndex: asKeyOf<PurchaseTargetDTO>('orderCost'),
      sorter: true,
      settingKey: 'orderCost',
      render: (orderCost, record) =>
        formatUSD(orderCost + (shouldCreateSpecialOrderLines ? record.specialOrderCost : 0)),
    }),
    sortableNumberColumn({
      title: 'Lines',
      dataIndex: asKeyOf<PurchaseTargetDTO>('numLines'),
      sorter: true,
      settingKey: 'numLines',
      render: (_, row) => (
        <>
          {shouldShowSpecialOrderWarning && row?.hasSpecialOrder && (
            <IconTooltip
              tooltipText="There is a special order available to be combined with this stock PO"
              wrapText
              Icon={ExceptionOutlined}
              iconStyle={{ color: theme.colors.twilight[700] }}
            />
          )}
          {row.numLines}
        </>
      ),
    }),
    {
      title: 'Actions',
      width: '100px',
      align: 'left',
      settingKey: 'actions',
      render: (_, record: PurchaseTargetDTO) => (
        <Button
          size="small"
          data-test-id="detailPageButton"
          disabled={selectedSupplierLocations.length > 0}
          onClick={() => {
            history.push(
              routes.purchasing.purchaseTargetLines({
                companyIds: record.companyId ? [record.companyId] : undefined,
                supplierLocations: [recordToSupplierLocationWTarget(record)],
              }),
            );
          }}
        >
          Lines <RightOutlined />
        </Button>
      ),
    },
  ];

  const [visibleColumnKeys, setVisibleColumnKeys] = useUserViewSettingsState(
    ViewSettingKey.PurchaseTargetsTable,
    tableColumns.filter((column) => !column.optional).map((column) => column.settingKey),
  );

  return (
    <Container>
      <PageHeader
        title={
          <InfoTooltip
            title={[
              'Recurrency tracks all your items and notifies you when items ready-to-purchase surpass a supplier purchase target.',
              'Use this page to configure your targets and review suppliers ready for a PO.',
              "Select items and then click 'PO Builder' for building multiple POs simultaneously.",
            ].join(' ')}
          >
            Purchase Targets
          </InfoTooltip>
        }
        headerActions={
          <Button
            type="primary"
            disabled={selectedSupplierLocations.length === 0}
            onClick={() => {
              history.push(
                routes.purchasing.purchaseTargetLines({
                  companyIds: arrUnique((targetsData || []).map((t) => t.companyId)).filter((c) => !!c),
                  supplierLocations: selectedSupplierLocations,
                }),
              );
            }}
          >
            PO Builder
          </Button>
        }
      />
      <FilterBarBox dividerLine>
        <FlexSpace direction="column" gap={16} fullWidth>
          <FlexSpace>
            <span>View</span>
            <RadioGroup
              value={targetGroupType}
              onChange={(ev) => {
                const newTargetGroupType = ev.target.value as TargetGroupType;
                setTargetGroupTypeFilter(newTargetGroupType);
              }}
            >
              <Radio.Button value={TargetGroupType.Targets}>Targets</Radio.Button>
              <Radio.Button value={TargetGroupType.ReviewCycle}>Review Cycle</Radio.Button>
            </RadioGroup>
          </FlexSpace>

          <FlexSpace wrap fullWidth>
            <span>Filter</span>
            <AsyncMultiSelect
              label="Status"
              mode="single"
              icon={<DashboardOutlined />}
              selectProps={{ options: statusOptions }}
              selectedValues={[hashState.status || statusOptions[0].value]}
              onSelectedValuesChange={(values) =>
                updateHashState({ status: (values[0] || undefined) as PurchaseTargetStatus })
              }
            />
            {targetGroupType === TargetGroupType.Targets ? (
              <AsyncMultiSelect
                label="Target Type"
                icon={<AimOutlined />}
                selectProps={{ options: TargetTypesFilterOptions }}
                selectedValues={targetTypes || []}
                onSelectedValuesChange={(values) =>
                  updateHashState({ targetTypes: values.length > 0 ? (values as SupplierTargetTypes[]) : undefined })
                }
              />
            ) : null}
            <AsyncMultiSelect
              selectProps={convertToMultiSelectProps(suppliersSelectProps)}
              label="Supplier"
              queryPlaceholder="Search suppliers"
              selectedValues={supplierIds}
              onSelectedValuesChange={(values) => updateHashState({ supplierIds: values })}
              icon={<ShopOutlined />}
            />
            <AsyncMultiSelect
              selectProps={convertToMultiSelectProps(locationsSelectProps)}
              label="Location"
              queryPlaceholder="Search locations"
              selectedValues={locationIds}
              onSelectedValuesChange={(values) => updateHashState({ locationIds: values })}
              icon={<EnvironmentOutlined />}
            />
            <AsyncMultiSelect
              selectProps={convertToMultiSelectProps(buyerSelectProps)}
              label="Buyer"
              queryPlaceholder="Search buyer"
              selectedValues={buyerIds}
              onSelectedValuesChange={(values) => updateHashState({ buyerIds: values })}
              icon={<UserOutlined />}
            />
            {shouldShowSpecialOrderWarning && (
              <AsyncMultiSelect
                selectProps={{ options: specialOrderFilterOptions }}
                label="Special Orders"
                selectedValues={specialOrder}
                onSelectedValuesChange={(values) =>
                  updateHashState({
                    specialOrder: values.length > 0 ? (values as SpecialOrderFilterOptions[]) : undefined,
                  })
                }
                icon={<ExceptionOutlined />}
                disableValuesTooltip
              />
            )}
            {hashState.status ||
            hashState.supplierIds ||
            hashState.locationIds ||
            hashState.targetTypes ||
            (hashState.specialOrder && hashState.specialOrder.length > 0) ||
            hashState.buyerIds ? (
              <ActionButton
                onClick={() =>
                  updateHashState({
                    status: undefined,
                    supplierIds: undefined,
                    locationIds: undefined,
                    buyerIds: undefined,
                    targetTypes: undefined,
                    specialOrder: undefined,
                  })
                }
                label="Clear All"
              />
            ) : null}
            <FlexSpacer />
            <ResultCount count={tableProps.totalCount} dataTestId="purchase-targets-result-count" />
          </FlexSpace>
        </FlexSpace>
      </FilterBarBox>

      <SelectionCountAndClear
        numSelected={selectedSupplierLocations.length}
        entitySingle="row"
        onClear={() => updateHashState({ selectedSupplierLocations: undefined })}
      />

      <ColumnChooserSection
        tableKey={ViewSettingKey.PurchaseTargetsTable}
        columns={tableColumns}
        visibleColumnKeys={visibleColumnKeys}
        setVisibleColumnKeys={setVisibleColumnKeys}
      />

      <AsyncTable
        tableProps={tableProps}
        columns={withSortedColumn(
          visibleColumnKeys.map((key) => tableColumns.find((column) => column.settingKey === key)).filter(truthy),
          tableProps.sortBy,
          tableProps.sortDir,
        )}
        rowKey={({ supplierId, purchaseLocationId }) => getRowKey({ supplierId, locationId: purchaseLocationId })}
        rowSelection={{
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedSupplierLocations.map(getRowKey),
          onChange: (newSelectedRowKeys: React.Key[]) => {
            const newSelection = newSelectedRowKeys.map(
              (key) =>
                selectedSupplierLocations.find((sl) => getRowKey(sl) === key) ||
                recordToSupplierLocationWTarget(
                  targetsData.find(
                    ({ supplierId, purchaseLocationId }) =>
                      getRowKey({ supplierId, locationId: purchaseLocationId }) === key,
                  )!,
                ),
            );
            updateHashState({ selectedSupplierLocations: newSelection });
          },
        }}
      />
    </Container>
  );
}

const getRowKey = (record: { supplierId: string; locationId: string }) => `${record.supplierId}|${record.locationId}`;

const getTargetGroupSort = (targetGroupType?: TargetGroupType): SortResponse => {
  if (targetGroupType === TargetGroupType.ReviewCycle) {
    return { sortBy: 'orderCost', sortDir: 'desc' };
  }
  return { sortBy: 'goalPercent', sortDir: 'desc' };
};
