import React from 'react';

import { css } from '@emotion/css';
import { theme } from 'theme';

import { daysOfWeekDef } from 'pages/purchasing/PurchaseTargetsPage/PurchaseTargetLinesPage/utils';

import { FlexSpace } from 'components/FlexSpace';

import { extractDaysOfWeek } from './utils';

interface RepeatingScheduleSelectorProps {
  id?: string;
  value: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  showError?: boolean;
  setShowError?: (showError: boolean) => void;
}

const DEFAULT_BACKGROUND = '#f5f5f5';
const DEFAULT_TEXT = '#00000040';
const DEFAULT_BORDER = '#d1d1e0';

export const RepeatingScheduleSelector = ({
  id,
  value,
  onChange,
  disabled,
  showError,
  setShowError,
}: RepeatingScheduleSelectorProps) => {
  const days = extractDaysOfWeek(value);

  function onEdit(days: number[]) {
    if (onChange) onChange(days.sort().join(','));
  }

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        gap: 12px;
      `}
      id={id}
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 8px;
        `}
      >
        <FlexSpace gap={8} alignItems="center">
          {daysOfWeekDef.map((day) => {
            const checked = days.includes(day.value);
            return (
              <div
                key={day.value}
                className={css`
                  background-color: ${disabled ? DEFAULT_BACKGROUND : checked ? theme.colors.info[500] : 'white'};
                  color: ${disabled ? DEFAULT_TEXT : checked ? 'white' : 'black'};
                  border: 1px solid ${disabled ? DEFAULT_BORDER : checked ? theme.colors.info[500] : 'lightgray'};
                  padding: 6px;
                  border-radius: 4px;
                  cursor: pointer;
                  width: 45px;
                  text-align: center;
                  user-select: none;
                  &:hover: {
                    color: ${theme.colors.info[500]};
                  }
                `}
                onClick={() => {
                  if (checked) {
                    onEdit(days.filter((oldDay) => oldDay !== day.value));
                  } else {
                    onEdit([...days, day.value]);
                  }
                  setShowError?.(false);
                }}
              >
                {day.label}
              </div>
            );
          })}
        </FlexSpace>
        {showError && (
          <div
            className={css`
              color: ${theme.colors.danger[500]};
            `}
          >
            Please select at least 1 day of the week
          </div>
        )}
      </div>
    </div>
  );
};
