import React, { useMemo, useState } from 'react';

import { DashboardOutlined, DownOutlined } from '@ant-design/icons';
import { PurchaseTargetLineStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { TransferTargetLineDTO } from '@recurrency/core-api-schema/dist/transferOrders/common';
import { Menu } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import { useDebounce } from 'use-debounce';

import { usePurchasingStatuses } from 'pages/purchasing/PurchaseTargetsPage/PurchaseTargetLinesPage/utils';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpacer } from 'components/FlexSpacer';
import { ResultCount } from 'components/ResultCount';
import { SearchInput } from 'components/SearchInput';
import { SplitPage } from 'components/SplitPage';

import { useEventListener } from 'hooks/useEventListener';

import { useHashState } from 'utils/routes';

import { TransferTargetLinesHashState, TransferTargetSummary, UpdatedTransferLine } from 'types/hash-state';

import {
  getLineStatusCounts,
  getResettedTransferLineUpdates,
  getTransferQty,
  sortAndFilterTransferLineRows,
} from '../utils';
import { ItemSummarySidePane } from './SidePane/ItemSummarySidePane';
import { TOSummarySidePane } from './SidePane/TOSummarySidePane';
import { TransferTargetLinesTable } from './TransferTargetLinesTable';

export function TransferTargetLinesAllLines({
  isLoading,
  isReadOnly = false,
  transferSummaries,
  lines,
  focusedSummary,
}: {
  isLoading: boolean;
  isReadOnly?: boolean;
  transferSummaries: TransferTargetSummary[];
  lines: UpdatedTransferLine[];
  focusedSummary?: TransferTargetSummary;
}) {
  const [hashState, updateHashState] = useHashState<TransferTargetLinesHashState>();
  const [focusedLine, setFocusedLine] = useState<TransferTargetLineDTO>();
  const [sortField, setSortField] = useState('originalTransferQty' as keyof UpdatedTransferLine);
  const [sortDir, setSortDir] = useState<SortOrder>('descend');
  const [debouncedSearchQuery] = useDebounce(hashState.searchQuery || '', 500);
  const { filteredStatuses, defaultStatusFilters } = usePurchasingStatuses();
  const statusesFilter = hashState.statuses ?? defaultStatusFilters;

  const lineStatusCounts = getLineStatusCounts(lines);

  function handleResetToRecommendedClick() {
    updateHashState({
      transferLineUpdates: hashState.transferLineUpdates?.map((line) => ({
        ...line,
        qtyToTransfer: undefined,
      })),
    });
  }

  function handleResetAllClick() {
    const updatedTransferLineUpdates = getResettedTransferLineUpdates(transferSummaries, hashState.transferLineUpdates);
    updateHashState({
      transferLineUpdates: updatedTransferLineUpdates,
    });
  }

  // clear focused line item when clicking outside of lines table and side pane
  useEventListener(
    document.body,
    'click',
    (ev) => {
      const lineInfoEls = Array.from(document.querySelectorAll('.ant-table, div.SidePane'));
      if (lineInfoEls.every((el) => !el.contains(ev.target as Node))) {
        setFocusedLine(undefined);
      }
    },
    {
      // evaluate at capture (instead of bubbling phase) so if a component stops event propagation
      // we still know where the user clicked
      capture: true,
    },
  );

  const filteredLines = useMemo(
    () =>
      sortAndFilterTransferLineRows(lines, sortField, sortDir, debouncedSearchQuery)
        .filter((line) => (isReadOnly ? getTransferQty(line) > 0 : true))
        .filter((line) =>
          isReadOnly && focusedSummary
            ? line.sourceLocationId === focusedSummary.sourceLocation.foreignId &&
              line.destinationLocationId === focusedSummary.destinationLocation.foreignId
            : true,
        ),
    [debouncedSearchQuery, focusedSummary, isReadOnly, lines, sortDir, sortField],
  );

  return (
    <>
      {!isReadOnly && (
        <FilterBarBox dividerLine>
          <AsyncMultiSelect
            label="Status"
            icon={<DashboardOutlined />}
            selectedValues={statusesFilter}
            onSelectedValuesChange={(values) =>
              updateHashState({ statuses: values.length > 0 ? (values as PurchaseTargetLineStatus[]) : undefined })
            }
            selectProps={{ options: filteredStatuses.map((status) => ({ label: status, value: status })) }}
          />

          <FlexSpacer />

          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={() => handleResetAllClick()}>Clear All Transfers</Menu.Item>
                <Menu.Item onClick={() => handleResetToRecommendedClick()}>Reset All to Recommended</Menu.Item>
              </Menu>
            }
          >
            <Button size="small">
              Reset <DownOutlined />
            </Button>
          </Dropdown>

          <SearchInput
            placeholder="Search by Item ID / Description"
            query={hashState.searchQuery}
            onQueryChange={(query) => updateHashState({ searchQuery: query !== '' ? query : undefined })}
          />

          <ResultCount count={filteredLines.length} />
        </FilterBarBox>
      )}
      <SplitPage
        left={
          <TransferTargetLinesTable
            isLoading={isLoading}
            isReadOnly={isReadOnly}
            focusedLine={focusedLine}
            setFocusedLine={setFocusedLine}
            setSortDir={setSortDir}
            setSortField={setSortField}
            lines={filteredLines}
          />
        }
        right={
          focusedLine ? (
            <ItemSummarySidePane itemInfo={focusedLine} />
          ) : (
            <TOSummarySidePane transferSummaries={transferSummaries} lineStatusCounts={lineStatusCounts} />
          )
        }
      />
    </>
  );
}
