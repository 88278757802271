import React from 'react';

import { RightOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { MonthlyDemandUsageRecordDTO } from '@recurrency/core-api-schema/dist/ml/getMonthlyDemandUsage';
import { ColumnType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';
import { Moment } from 'moment';
import moment from 'moment/moment';

import { Table } from 'components/Table';

import { useCoreApi } from 'hooks/useApi';

import { truthy } from 'utils/boolean';
import { sortableNumberColumn } from 'utils/tables';

import { Button } from '../Button';

export const MonthlyUsageTable = ({
  parentIsLoading = false,
  itemId,
  locationId,
  onLinesActionClick,
}: {
  parentIsLoading: boolean;
  itemId: string;
  locationId: string;
  onLinesActionClick: (fromDate: Moment, toDate: Moment) => void;
}) => {
  const { data: monthlyUsageData, isLoading } = useCoreApi(schemas.ml.getMonthlyDemandUsage, {
    queryParams: {
      itemId,
      locationId,
    },
  });

  const hasHistoricalDemand = (monthlyUsageData?.items || []).some((item) => item.inheritedUsageQty !== 0);

  const tableColumns: (ColumnType<MonthlyDemandUsageRecordDTO> | null)[] = [
    {
      title: 'Year',
      dataIndex: 'usageDate',
      defaultSortOrder: 'descend' as SortOrder,
      render: (value: string) => moment.utc(value).format('YYYY'),
    },
    {
      title: (
        <div
          className={css`
            padding-left: 50%;
          `}
        >
          Month
        </div>
      ),
      dataIndex: 'usageDate',
      render: (value: string) => (
        <div
          className={css`
            padding-left: 50%;
          `}
        >
          {moment.utc(value).format('MMMM')}
        </div>
      ),
    },
    sortableNumberColumn({
      title: 'Total Usage',
      dataIndex: 'baseUsageQty',
    }),
    hasHistoricalDemand
      ? sortableNumberColumn({
          title: 'Inherited Usage',
          dataIndex: 'inheritedUsageQty',
        })
      : null,
    {
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
    },
    {
      title: 'Actions',
      dataIndex: 'usageDate',
      width: '100px',
      render: (value: string) => (
        <Button
          size="small"
          onClick={() => {
            onLinesActionClick(moment.utc(value).startOf('month'), moment.utc(value).endOf('month'));
          }}
        >
          Lines <RightOutlined />
        </Button>
      ),
    },
  ].filter(truthy);

  return (
    <Table
      pageSize={10}
      isLoading={parentIsLoading || isLoading}
      data={monthlyUsageData?.items.sort((a, b) => (moment(a.usageDate).isBefore(b.usageDate) ? 1 : -1)) || []}
      columns={tableColumns.filter(truthy)}
    />
  );
};
