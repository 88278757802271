import React, { useEffect, useState } from 'react';

import { CloseOutlined, FileOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { DemandForecastOverrideUpdate } from '@recurrency/core-api-schema/dist/ml/updateDemandForecastOverrides';
import { SearchForecastDTO } from '@recurrency/core-api-schema/dist/search/getSearchForecasts';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { Divider, Steps, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';

import { Button } from 'components/Button';
import { ActionButton } from 'components/Button/ActionButton';
import { Container } from 'components/Container';
import { FlexSpace } from 'components/FlexSpace';
import { FlexSpacer } from 'components/FlexSpacer';
import { Modal } from 'components/Modal';
import { Table } from 'components/Table';
import { Tooltip } from 'components/Tooltip';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { coreApiFetch } from 'utils/api';
import { csvRowToArray } from 'utils/array';
import { truthy } from 'utils/boolean';
import { formatMonthYear, splitIdNameStr } from 'utils/formatting';
import { searchIndex } from 'utils/search/search';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { PersistedColumn } from 'utils/tableAndSidePaneSettings/types';
import { sortableDollarColumn, sortableIdColumn, sortableNumberColumn } from 'utils/tables';

import { BulkForecastOverridesStep } from 'types/hash-state';

import { ForecastDiff, ForecastDiffObject, GenerateUpdates, INPUT_KEY_COUNT, NOTE_COLUMN_INDEX } from './utils';

export interface OverallForecastDiff {
  dates: string[];
  diffs: ForecastDiffObject[];
}

export enum BulkForecastDownloadState {
  NotDownloading,
  Forecasts,
  Template,
}

const allowedHeaders = ['item', 'location', 'note'];

const DEFAULT_NOTE = '';

export interface BulkForecastOverridesModalProps {
  onClose: () => void;
  filters?: Obj<string[]>;
}

export const BulkForecastOverridesModal = ({ onClose, filters }: BulkForecastOverridesModalProps) => {
  const [currentStep, setCurrentStep] = useState<BulkForecastOverridesStep>(BulkForecastOverridesStep.Upload);
  const [downloadState, setDownloadState] = useState(BulkForecastDownloadState.NotDownloading);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [diff, setDiff] = useState<OverallForecastDiff>({ dates: [], diffs: [] });
  const [fileName, setFileName] = useState<string>('');
  const [failedDates, setFailedDates] = useState<string[]>([]);
  const [failedLines, setFailedLines] = useState<string[]>([]);
  const [latestChangeIndex, setLatestChangeIndex] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const downloadData = async (headersOnly: boolean) => {
    try {
      const responseHits = await getAllForecasts(headersOnly, true);

      const formattedData = [];
      // add headers
      const headers = ['item', 'location', 'note', ...(responseHits[0] ? responseHits[0].forecast_dates : [])];
      formattedData.push(headers.join(','));
      // add data lines
      if (!headersOnly)
        responseHits.forEach((item) => {
          formattedData.push(
            [
              `"${item.item_id}"`,
              `"${splitIdNameStr(item.location).foreignId}"`,
              DEFAULT_NOTE,
              ...item.forecast_demand,
            ].join(','),
          );
        });
      const csvString = formattedData.join('\n');
      const blob = new Blob([csvString], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'bulk-forecast-overrides.csv';
      a.click();
    } catch (error) {
      setError('Error downloading data');
    } finally {
      setDownloadState(BulkForecastDownloadState.NotDownloading);
    }
  };

  const parseUploadedData = async (file: RcFile) => {
    try {
      setIsUploading(true);
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target?.result as string;
        const lines = text.split('\n');
        const headers = lines[0].replace('\r', '').split(',');
        const unknownColumns = [];
        const headerDates: string[] = [];
        for (const header of headers) {
          const match = header.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/);
          // expected columns are just item, location, and then the dates of the forecast
          if (!allowedHeaders.includes(header)) {
            if (match !== null) {
              const date = match[0];
              if (diff.dates.includes(date)) {
                setError(`Duplicate date column "${date}" included in csv`);
                return;
              }
              headerDates.push(date);
            } else {
              unknownColumns.push(header);
            }
          }
        }
        setDiff((prev) => ({ dates: headerDates, diffs: prev.diffs }));
        if (unknownColumns.length > 0) {
          setError(`Unknown columns "${unknownColumns.join(', ')}" included in csv`);
          return;
        }
        const itemLocationSet = new Set<string>();
        const newData = lines
          .slice(1)
          .filter((s) => s.trim() !== '') // Remove empty lines
          .map((line, idx) => {
            const values = csvRowToArray(line);
            if (values.length !== headers.length) {
              setError(`Invalid csv format, line ${idx + 1} does not have the same number of columns as the header`);
            }
            for (let i = 0; i < values.length; i++) {
              // only the note column can be an empty string
              if (i !== NOTE_COLUMN_INDEX && values[i] === '') {
                setError(`Invalid csv format, line ${idx + 1} has an empty value for column ${headers[i]}`);
              }
              // If it's a date column, it should be a number
              const parsedValue = parseInt(values[i], 10);
              if (i > INPUT_KEY_COUNT - 1) {
                if (Number.isNaN(parsedValue)) {
                  setError(`Invalid csv format, line ${idx + 1} has a non-numeric value for column ${headers[i]}`);
                } else if (parsedValue < 0) {
                  setError(`Invalid value, line ${idx + 1} has a negative value for column ${headers[i]}`);
                }
              }
            }
            const obj: { [key: string]: string } = {};
            let itemLocation = '|';
            headers.forEach((header, index) => {
              if (header === 'item') {
                itemLocation = values[index] + itemLocation;
              }
              if (header === 'location') {
                itemLocation += values[index];
              }
              obj[header] = values[index];
            });
            if (itemLocationSet.has(itemLocation)) {
              setError(`Duplicate item-location pair "${itemLocation}" included in csv`);
            }
            itemLocationSet.add(itemLocation);
            return obj;
          })
          .filter(truthy);
        setCurrentStep(BulkForecastOverridesStep.Review);
        // sort by the largest change to show the most important changes first
        const newDiff = (await getDiff(newData, headerDates)).sort((a, b) => {
          // sorting to put the infinite values at the begining
          if (a.maxChangeCost < b.maxChangeCost) return 1;
          if (a.maxChangeCost > b.maxChangeCost) return -1;
          return 0;
        });
        setDiff((prev) => ({ dates: prev.dates, diffs: newDiff }));
      };
      reader.readAsText(file);
      setFileName(file.name);
    } catch (error) {
      setError('Error uploading data');
    } finally {
      setIsUploading(false);
    }
  };

  // get all the forecast data, split into batches in order to not overload the server
  const getAllForecasts = async (headersOnly: boolean, useFilters: boolean, itemIds?: string[]) => {
    let hits: SearchForecastDTO[] = [];
    let itemIndex = 0; // tracking where we are in the itemIds array
    const totalItems = itemIds ? itemIds.length : 1; // This is so it goes through the loop at least once, even if itemIds doesn't exist
    // This first loop is to go through the itemIds array in chunks of 1000, if there are no item ids then it will only go through once
    while (itemIndex < totalItems) {
      let page = 0;
      // start with max int so we can go into the loop and then set it to the actual value, after the first call
      let pageCount = headersOnly ? 1 : Number.MAX_SAFE_INTEGER;
      let response;
      // This inner loop is to go through the pages of the search results
      while (page < pageCount) {
        response = await searchIndex<SearchForecastDTO>({
          indexName: SearchIndexName.Forecasts,
          // if we are only getting headers, we only need one hit
          ...(headersOnly ? { hitsPerPage: 1 } : { hitsPerPage: 50_000, page }),
          // We filter by items if they are included, we break them up into groups of 1000 to not overwhelm the server
          ...(itemIds ? { filters: { item_id: itemIds.slice(itemIndex, itemIndex + 1_000) } } : {}),
          ...(useFilters && filters ? { filters } : {}),
        });
        // This updates the pageCount to the actual value after the first call
        if (!headersOnly && pageCount === Number.MAX_SAFE_INTEGER) pageCount = response.nbPages;
        // If there are no hits then we break out of the loop, pages might be messed up if there are no hits
        if (response.hits.length === 0) break;
        hits = hits.concat(response.hits);
        page++;
      }
      itemIndex += 1_000;
    }
    return hits;
  };

  const getDiff = async (data: { [key: string]: string }[], headerDates: string[]) => {
    setIsUploading(true);
    // get the list of item ids to filter the forecast data
    const itemIds = data.map((item) => item.item);
    // get the unique item ids as an array
    const uniqueItemIds = Array.from(new Set(itemIds));
    // get the current forecast data
    const currentForecastData = await getAllForecasts(false, false, uniqueItemIds);
    const currentForecastMap = new Map<string, SearchForecastDTO>();
    for (const forecast of currentForecastData) {
      currentForecastMap.set(`${forecast.item_id}|${splitIdNameStr(forecast.location).foreignId}`, forecast);
    }

    if (currentForecastData.length === 0) {
      setError('No forecast data found for the uploaded items');
      setIsUploading(false);
      return [];
    }

    // go through the forecast dates and update the dates array to only keep the ones that are in the current forecast
    //  this allows us to deal with an old csv uploaded with old dates
    const forecastDates = currentForecastData[0].forecast_dates;
    const nonAllowedDates: string[] = [];
    const allowedDates = headerDates.filter((date) => {
      // This is to keep track of the dates that are not in the forecast, without looping through again
      const include = forecastDates.includes(date);
      if (!include) nonAllowedDates.push(date);
      return include;
    });
    setFailedDates(nonAllowedDates);
    setDiff((prev) => ({ dates: allowedDates, diffs: prev.diffs }));

    // compare the uploaded data with the current forecast data
    const differences: ForecastDiffObject[] = [];
    let latestDiff = 0;
    data.forEach((item) => {
      let isDiff = false;
      let highestDiffPercentage = 0;
      let highestDiffCost = 0;
      const currentForecast = currentForecastMap.get(`${item.item}|${item.location}`);
      const diffArray: ForecastDiff[] = [];
      if (currentForecast) {
        // find the matching forecast date
        for (let i = INPUT_KEY_COUNT; i < Object.keys(item).length; i++) {
          const key = Object.keys(item)[i];
          const date = key;
          if (allowedDates.includes(date)) {
            const uploadedValue = parseFloat(item[date]);
            const forecastIndex = currentForecast.forecast_dates.indexOf(date);
            const forecastValue = currentForecast.forecast_demand[forecastIndex];
            const newDiff: ForecastDiff = {
              oldValue: forecastValue,
              newValue: uploadedValue,
            };
            diffArray.push(newDiff);
            const change = Math.abs(uploadedValue - forecastValue);
            if (change !== 0) {
              isDiff = true;
              const changeCost = change * currentForecast.planning_unit_cost;
              const changePercentage = (change / forecastValue) * 100;
              // update the highest change values
              highestDiffCost = Math.max(highestDiffCost, changeCost);
              highestDiffPercentage = Math.max(highestDiffPercentage, changePercentage);
              // keep track of the furthest date that has a change
              latestDiff = Math.max(latestDiff, i - INPUT_KEY_COUNT);
            }
          }
        }
        if (isDiff)
          differences.push({
            item: item.item,
            location: item.location,
            note: item.note,
            itemUid: currentForecast.item_uid,
            maxChangeCost: highestDiffCost,
            maxChangePercentage: highestDiffPercentage,
            changes: diffArray,
          });
      } else {
        setFailedLines((prev) => [...prev, `${item.item} - ${item.location}`]);
      }
    });

    setLatestChangeIndex(latestDiff);
    setIsUploading(false);
    if (differences.length === 0) {
      setError('No differences found between the uploaded data and the current forecast');
    }
    // return the differences
    return differences;
  };

  const getColumns = (): PersistedColumn<any>[] => {
    const baseColumns = [
      sortableIdColumn({
        title: 'Item',
        dataIndex: 'item',
        settingKey: 'item',
      }),
      sortableIdColumn({
        title: 'Location',
        dataIndex: 'location',
        settingKey: 'location',
      }),
      {
        title: 'Note',
        dataIndex: 'note',
        settingKey: 'note',
      },
      sortableDollarColumn({
        title: (
          <InfoTooltip title="The largest change in cost of a forecast across all months">
            Biggest Change ($)
          </InfoTooltip>
        ),
        defaultSortOrder: 'descend',
        settingKey: 'maxChangeCost',
        dataIndex: 'maxChangeCost',
      }),
      sortableNumberColumn({
        title: (
          <InfoTooltip title="The largest percentage change in a forecast across all months">
            Biggest Change (%)
          </InfoTooltip>
        ),
        settingKey: 'maxChange',
        dataIndex: 'maxChangePercentage',
        render: (_, item: ForecastDiffObject) => (
          <div
            className={css`
              width: 100px;
            `}
          >
            {`${Number.isFinite(item.maxChangePercentage) ? item.maxChangePercentage.toFixed(0) : '--'}%`}
          </div>
        ),
      }),
    ];
    // since the date columns are dynamic we need to generate them based on the data
    const dateColumns = diff.dates
      .map((key, index) =>
        index <= latestChangeIndex
          ? {
              title: formatMonthYear(key),
              settingKey: key,
              render: (item: ForecastDiffObject) => {
                const values = item.changes[index];
                const change = values.newValue - values.oldValue;
                return (
                  <div
                    className={css`
                      color: ${change !== 0 ? 'green' : ''};
                      width: 70px;
                    `}
                  >
                    {change !== 0 ? `${values.oldValue} -> ${values.newValue}` : values.oldValue}
                  </div>
                );
              },
            }
          : null,
      )
      .filter(truthy);
    return [...baseColumns, ...dateColumns];
  };

  useEffect(() => {
    if (error !== null) {
      setIsUploading(false);
      setCurrentStep(BulkForecastOverridesStep.Upload);
    }
  }, [error]);

  const removeFile = () => {
    setFileName('');
    setDiff({ dates: [], diffs: [] });
    setFailedLines([]);
    setError(null);
    setLatestChangeIndex(0);
    setFailedDates([]);
  };

  return (
    <Modal
      title="Upload Forecast Overrides"
      visible
      onCancel={() => onClose()}
      centered
      width={1200}
      footer={
        <>
          <Button
            onClick={() => setCurrentStep(BulkForecastOverridesStep.Upload)}
            disabled={currentStep === BulkForecastOverridesStep.Upload}
          >
            Previous
          </Button>
          <Button
            type="primary"
            disabled={fileName === '' || error !== null}
            loading={isSubmitting}
            onClick={async () => {
              if (currentStep === BulkForecastOverridesStep.Upload) {
                setCurrentStep(BulkForecastOverridesStep.Review);
              } else if (currentStep === BulkForecastOverridesStep.Review) {
                const updates: DemandForecastOverrideUpdate[] = GenerateUpdates(diff.dates, diff.diffs);
                setIsSubmitting(true);
                const submitNotification = createSubmissionNotification({
                  entityName: 'Forecast Overrides',
                  // 30 sec + 30 sec per 1k updates
                  expectedWaitSeconds: 30 + Math.round(updates.length / 1_000) * 30,
                });
                try {
                  await coreApiFetch(schemas.ml.updateDemandForecastOverrides, {
                    bodyParams: {
                      updates,
                    },
                  });
                  submitNotification.success({ successMessage: 'Forecast overrides applied', duration: 10 });
                  onClose();
                } catch (err) {
                  submitNotification.error(err);
                }
                setIsSubmitting(false);
              }
            }}
          >
            {currentStep === BulkForecastOverridesStep.Review ? 'Save Overrides' : 'Next'}
          </Button>
        </>
      }
    >
      <Steps current={currentStep}>
        <Steps.Step title="Upload Overrides" />
        <Steps.Step title="Review" />
      </Steps>
      {currentStep === BulkForecastOverridesStep.Upload && (
        <Container>
          <FlexSpace direction="column" gap={16}>
            <FlexSpacer />
            <div
              className={css`
                margin-left: 24px;
                margin-right: 24px;
              `}
            >
              Upload forecast overrides to be to used to generate recommendations in Recurrency. The overrides will stay
              until changed again, even as new Recurrency forecasts are generated. Upload the new forecast below using
              the proper template. (Note: The template must be in CSV format)
            </div>
            {fileName === '' ? (
              <Upload
                className={css`
                  margin-left: auto;
                  margin-right: auto;
                `}
                accept=".csv"
                maxCount={1}
                showUploadList={false}
                beforeUpload={parseUploadedData}
              >
                <Button
                  className={css`
                    margin-left: auto;
                    margin-right: auto;
                  `}
                  type="primary"
                  loading={isUploading}
                >
                  Upload Forecast
                </Button>
              </Upload>
            ) : (
              <FlexSpace
                className={css`
                  margin-left: auto;
                  margin-right: auto;
                  padding: 8px;
                  width: 250px;
                `}
              >
                <Tooltip title={fileName}>
                  <FileOutlined />
                </Tooltip>
                <p
                  className={css`
                    margin-top: auto;
                    margin-bottom: auto;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 250px;
                  `}
                >
                  {fileName}
                </p>

                <FlexSpacer />
                <ActionButton label={<CloseOutlined />} onClick={() => removeFile()} />
              </FlexSpace>
            )}
            <div
              className={css`
                margin-left: auto;
                margin-right: auto;
                color: red;
              `}
            >
              {error}
            </div>
            <FlexSpace>
              <div
                className={css`
                  margin-left: 24px;
                  margin-right: 24px;
                `}
              >
                Download an example template or the forecasts that are currently selected. Note Excel and other
                spreadsheet editors may remove the leading zeros of Item IDs which can cause issues when uploading. This
                download can take up to a minute to complete.
              </div>
              <Button
                className={css`
                  margin-left: auto;
                  margin-right: auto;
                `}
                onClick={() => {
                  setDownloadState(BulkForecastDownloadState.Forecasts);
                  downloadData(false);
                }}
                loading={downloadState === BulkForecastDownloadState.Forecasts}
              >
                Download Forecasts
              </Button>
              <Button
                className={css`
                  margin-left: auto;
                  margin-right: auto;
                `}
                onClick={() => {
                  setDownloadState(BulkForecastDownloadState.Template);
                  downloadData(true);
                }}
                loading={downloadState === BulkForecastDownloadState.Template}
              >
                Download Template
              </Button>
            </FlexSpace>
          </FlexSpace>
        </Container>
      )}
      {currentStep === BulkForecastOverridesStep.Review && (
        <>
          <Divider />
          <Table pageSize={10} isLoading={isUploading} data={diff.diffs} columns={getColumns()} size="large" />
          {failedLines.length > 0 && (
            <div>
              Could not find these item-locations (Item or Location IDs could be missing leading 0s). Please double
              check and try again:{' '}
              <span
                className={css`
                  color: red;
                `}
              >
                {failedLines.join(', ')}
              </span>
            </div>
          )}
          {failedDates.length > 0 && (
            <div>
              Could not find these dates in the current forecast. These columns will not be used to override the
              forecast:{' '}
              <span
                className={css`
                  color: orange;
                `}
              >
                {failedDates.join(', ')}
              </span>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};
