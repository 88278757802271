import { SearchForecastDTO } from '@recurrency/core-api-schema/dist/search/getSearchForecasts';

// DemandDataType handles historical, inherited, and forecast types for calculation purposes.
// This is different from ForecastDataType which handles just usage and forecast, for displaying data in the chart.
enum DemandDataType {
  HistoricalInherited = 'historical_inherited',
  Historical = 'historical',
  Forecast = 'forecast',
}

function getSummedDemandData(data: { keys: string[]; vals: number[] }[], dataType: DemandDataType) {
  const obj: Record<string, number> = {};

  data.forEach((hit) => {
    const { keys, vals } = hit;
    keys.forEach((key, i) => {
      obj[key] = typeof obj[key] === 'undefined' ? vals[i] : obj[key] + vals[i];
    });
  });

  if (dataType === DemandDataType.Forecast) {
    return {
      forecast_dates: Object.keys(obj),
      forecast_demand: Object.values(obj),
    };
  }
  if (dataType === DemandDataType.Historical) {
    return {
      historical_dates: Object.keys(obj),
      historical_demand: Object.values(obj),
    };
  }
  return {
    historical_inherited_demand: Object.values(obj),
  };
}

export function getMergedForecastRecords(hits?: SearchForecastDTO[]): SearchForecastDTO | undefined {
  if (!hits || hits.length === 0) return undefined;

  const historicalData = hits.map((hit) => ({ keys: hit.historical_dates, vals: hit.historical_demand }));
  const historicalInheritedData = hits.map((hit) => ({
    keys: hit.historical_dates,
    vals: hit.historical_inherited_demand,
  }));
  const forecastData = hits.map((hit) => ({ keys: hit.forecast_dates, vals: hit.forecast_demand }));
  const summedHistoricalDemand = getSummedDemandData(historicalData, DemandDataType.Historical);
  const summedHistoricalInheritedDemand = getSummedDemandData(
    historicalInheritedData,
    DemandDataType.HistoricalInherited,
  );
  const summedForecastDemand = getSummedDemandData(forecastData, DemandDataType.Forecast);

  return {
    ...hits[0],
    ...summedForecastDemand,
    ...summedHistoricalDemand,
    ...summedHistoricalInheritedDemand,
  };
}

export function getAncestorItemCodes(hits?: SearchForecastDTO[]): string[] | undefined {
  if (!hits || hits.length === 0) return undefined;
  const ancestorItemCodes = new Set<string>();

  hits.forEach((hit) => {
    if (hit.ancestor_item_codes) {
      // After chagning data type from string separated by comma to list, we can change this function
      hit.ancestor_item_codes.split(',').forEach((code: string) => ancestorItemCodes.add(code.trim()));
    }
  });

  return Array.from(ancestorItemCodes);
}
