import { PlanningHubAndSpokeType, TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import {
  TenantSettingKey,
  TenantSettings,
  TenantSettingsFields,
} from '@recurrency/core-api-schema/dist/common/tenantSettings';
import {
  NumberGrid,
  TenantSettingFieldType,
  TenantSettingNumberUnits,
} from '@recurrency/core-api-schema/dist/common/tenantSettingsTypes';
import { TenantDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';

import { ExtendedUser, getGlobalAppState } from 'hooks/useGlobalApp';

import { objKeys } from './object';
import { shouldShowFeatureFlag } from './roleAndTenant';
import { isTenantErpTypeP21 } from './tenants';

export function getTenantSetting<SettingKey extends keyof TenantSettings>(
  settingKey: SettingKey,
): Partial<TenantSettings>[SettingKey] | undefined {
  const { activeTenantSettings } = getGlobalAppState();
  return activeTenantSettings?.[settingKey];
}

export function convertTenantSettingsForDisplay(
  tenantSettings: Partial<TenantSettings> | undefined,
  pendingSettings: Partial<TenantSettings>,
): Partial<TenantSettings> | undefined {
  if (!tenantSettings) {
    return tenantSettings;
  }
  return Object.fromEntries(
    objKeys(tenantSettings).map((key) => {
      // show the pending value as display value if it exists, fall back to tenantSettings
      const value = pendingSettings[key] ?? tenantSettings[key];
      const field = TenantSettingsFields[key];
      if (field) {
        if (field.type === TenantSettingFieldType.Number && value !== undefined) {
          const typedValue = value as number;
          if (field.units === TenantSettingNumberUnits.Percent) {
            // Convert decimal to percent
            return [key, typedValue * 100];
          }
        }
        return [key, value];
      }
      // If field is not defined, setting has been added to core api but frontend has not been upgraded to the latest schema package. Ignore field for now
      return [];
    }),
  );
}

export function convertTenantSettingsForSubmit(
  tenantSettings: Partial<TenantSettings> | undefined,
): Partial<TenantSettings> {
  if (!tenantSettings) {
    return tenantSettings ?? {};
  }
  return Object.fromEntries(
    objKeys(tenantSettings).map((key) => {
      const value = tenantSettings[key];
      const field = TenantSettingsFields[key];
      if (field.type === TenantSettingFieldType.Number && value !== undefined) {
        if (field.units === TenantSettingNumberUnits.Percent) {
          // Convert percent to decimal
          return [key, Number(value) / 100];
        }
        // Ensure numbers are submitted as numbers
        return [key, Number(value)];
      }
      if (field.type === TenantSettingFieldType.NumberGrid) {
        const typedValue = value as NumberGrid;
        if (typedValue.values !== undefined) {
          // Ensure numbers are submitted as numbers
          return [
            key,
            {
              ...typedValue,
              values: typedValue.values.map((row: (string | number)[]) =>
                row.map((cell: string | number) => Number(cell)),
              ),
            },
          ];
        }
      }
      return [key, value];
    }),
  );
}

export function isHubAndSpokeEnabled(): boolean {
  const planningHubAndSpokeSetting = getTenantSetting(TenantSettingKey.FeaturePlanningHubAndSpoke);
  return (
    planningHubAndSpokeSetting === PlanningHubAndSpokeType.Flexible ||
    planningHubAndSpokeSetting === PlanningHubAndSpokeType.Rigid
  );
}

export function showSpecialOrderWarning(activeTenant?: TenantDTO): boolean {
  if (!activeTenant) {
    ({ activeTenant } = getGlobalAppState());
  }
  return (
    // Null when the app starts up, so set default value
    activeTenant &&
    isTenantErpTypeP21(activeTenant.erpType) &&
    !!getTenantSetting(TenantSettingKey.FeaturePurchasingShowSpecialOrders)
  );
}

export function isSpecialOrderPurchasingEnabled(activeTenant?: TenantDTO, activeUser?: ExtendedUser): boolean {
  if (!activeTenant || !activeUser) {
    ({ activeTenant, activeUser } = getGlobalAppState());
  }
  return (
    // Null when the app starts up, so set default value
    activeTenant &&
    activeUser &&
    showSpecialOrderWarning(activeTenant) &&
    shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.FeaturePurchasingSpecialOrderM2)
  );
}
