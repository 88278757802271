import React from 'react';

import { SalesInvoiceLinesReportItemDTO } from '@recurrency/core-api-schema/dist/reports/getSalesInvoiceLinesReport';

import { PropertyListItemProps } from 'components/PropertyListItem';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { formatPercent, formatUSD } from 'utils/formatting';
import { shouldHideCostAndGm } from 'utils/roleAndTenant';

import { DetailPagePropertyList } from '../detailPage/DetailPagePropertyList';

export const SalesReportStatistics = ({
  stats,
  isLoading,
}: {
  stats: SalesInvoiceLinesReportItemDTO | undefined;
  isLoading: boolean;
}) => {
  const { activeTenant, activeUser } = useGlobalApp();
  const hideCostAndGm = shouldHideCostAndGm(activeTenant, activeUser);

  const statsList: PropertyListItemProps[] = [
    {
      label: 'Sales YTD',
      value: formatUSD(stats?.salesYtd),
      isLoading,
    },
    {
      label: 'Sales LYTD',
      value: formatUSD(stats?.salesLYtd),
      isLoading,
    },
    ...(hideCostAndGm
      ? []
      : [
          {
            label: 'GM YTD',
            value: formatUSD(stats?.gmYtd),
            isLoading,
          },
          { label: 'GM LYTD', value: formatUSD(stats?.gmLYtd), isLoading },

          {
            label: 'GM% YTD',
            value: formatPercent(stats?.gmPctYtd, 0),
            isLoading,
          },
          { label: 'GM% LYTD', value: formatPercent(stats?.gmPctLYtd, 0), isLoading },
        ]),
  ];
  return <DetailPagePropertyList rows={[statsList]} flex />;
};
