import React from 'react';

import {
  AimOutlined,
  DashboardOutlined,
  EnvironmentOutlined,
  ExceptionOutlined,
  ShopOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  PurchaseTargetStatus,
  SpecialOrderFilterOptions,
  SupplierTargetTypes,
  TargetGroupType,
} from '@recurrency/core-api-schema/dist/common/enums';
import { Radio } from 'antd';

import { usePurchasingStatuses } from 'pages/purchasing/PurchaseTargetsPage/PurchaseTargetLinesPage/utils';
import { specialOrderFilterOptions, TargetTypesFilterOptions } from 'pages/purchasing/utils';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { MultiSelectOption } from 'components/AsyncSelect/types';
import { convertToMultiSelectProps } from 'components/AsyncSelect/useAsyncMultiSelectProps';
import {
  useLocationsSelectProps,
  usePurchasingBuyersSelectProps,
  useSuppliersSelectProps,
} from 'components/AsyncSelect/useAsyncSelectProps';
import { ActionButton } from 'components/Button/ActionButton';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpace } from 'components/FlexSpace';
import { FlexSpacer } from 'components/FlexSpacer';
import { RadioGroup } from 'components/Radio';
import { ResultCount } from 'components/ResultCount';
import { SearchInput } from 'components/SearchInput';

import { UpdateHashStateOptions } from 'utils/routes';

import { PurchaseGroupsHashState } from '../PurchaseGroupsPage.types';

export type PurchaseGroupsFiltersProps = {
  hashState: PurchaseGroupsHashState;
  updateHashState: (update: Partial<PurchaseGroupsHashState>, options?: UpdateHashStateOptions | undefined) => void;
  setTargetGroupTypeFilter: (targetGroupType: TargetGroupType | undefined) => void;
  totalCount: number;
};

const statusOptions: MultiSelectOption[] = [
  { label: 'All', value: '' },
  { label: 'Ready', value: PurchaseTargetStatus.Ready },
  { label: 'Not Ready', value: PurchaseTargetStatus.NotReady },
];

export function PurchaseGroupsFilters({
  hashState,
  updateHashState,
  setTargetGroupTypeFilter,
  totalCount,
}: PurchaseGroupsFiltersProps) {
  const locationsSelectProps = useLocationsSelectProps({ sortByValueProp: true });
  const suppliersSelectProps = useSuppliersSelectProps();
  const buyerSelectProps = usePurchasingBuyersSelectProps();
  const { shouldShowSpecialOrderWarning } = usePurchasingStatuses();

  const {
    supplierIds = [],
    locationIds = [],
    buyerIds = [],
    targetTypes,
    targetGroupType = TargetGroupType.Targets,
    status,
    specialOrder = [],
  } = hashState;

  return (
    <FilterBarBox dividerLine>
      <FlexSpace direction="column" gap={16} fullWidth>
        <FlexSpace direction="column" gap={16} fullWidth>
          <FlexSpace>
            <span>View</span>
            <RadioGroup
              value={targetGroupType}
              onChange={(ev) => {
                const newTargetGroupType = ev.target.value as TargetGroupType;
                setTargetGroupTypeFilter(newTargetGroupType);
              }}
            >
              <Radio.Button value={TargetGroupType.Targets}>Targets</Radio.Button>
              <Radio.Button value={TargetGroupType.ReviewCycle}>Review Cycle</Radio.Button>
            </RadioGroup>
          </FlexSpace>
        </FlexSpace>

        <FlexSpace wrap fullWidth>
          <span>Filter</span>
          <AsyncMultiSelect
            label="Status"
            mode="single"
            icon={<DashboardOutlined />}
            selectProps={{ options: statusOptions }}
            selectedValues={[status || statusOptions[0].value]}
            onSelectedValuesChange={(values) =>
              updateHashState({ status: (values[0] || undefined) as PurchaseTargetStatus })
            }
          />
          {targetGroupType === TargetGroupType.Targets ? (
            <AsyncMultiSelect
              label="Target Type"
              icon={<AimOutlined />}
              selectProps={{ options: TargetTypesFilterOptions }}
              selectedValues={targetTypes || []}
              onSelectedValuesChange={(values) =>
                updateHashState({ targetTypes: values.length > 0 ? (values as SupplierTargetTypes[]) : undefined })
              }
            />
          ) : null}
          <AsyncMultiSelect
            selectProps={convertToMultiSelectProps(suppliersSelectProps)}
            label="Supplier"
            queryPlaceholder="Search suppliers"
            selectedValues={supplierIds}
            onSelectedValuesChange={(values) => updateHashState({ supplierIds: values })}
            icon={<ShopOutlined />}
          />
          <AsyncMultiSelect
            selectProps={convertToMultiSelectProps(locationsSelectProps)}
            label="Purchase Location"
            queryPlaceholder="Search locations"
            selectedValues={locationIds}
            onSelectedValuesChange={(values) => updateHashState({ locationIds: values })}
            icon={<EnvironmentOutlined />}
          />
          <AsyncMultiSelect
            selectProps={convertToMultiSelectProps(buyerSelectProps)}
            label="Buyer"
            queryPlaceholder="Search buyer"
            selectedValues={buyerIds}
            onSelectedValuesChange={(values) => updateHashState({ buyerIds: values })}
            icon={<UserOutlined />}
          />
          {shouldShowSpecialOrderWarning && (
            <AsyncMultiSelect
              selectProps={{ options: specialOrderFilterOptions }}
              label="Special Orders"
              selectedValues={specialOrder}
              onSelectedValuesChange={(values) =>
                updateHashState({
                  specialOrder: values.length > 0 ? (values as SpecialOrderFilterOptions[]) : undefined,
                })
              }
              icon={<ExceptionOutlined />}
              disableValuesTooltip
            />
          )}
          {hashState.status ||
          hashState.supplierIds ||
          hashState.locationIds ||
          hashState.buyerIds ||
          hashState.targetTypes ||
          (hashState.specialOrder && hashState.specialOrder.length > 0) ||
          hashState.query ? (
            <ActionButton
              onClick={() =>
                updateHashState({
                  status: undefined,
                  supplierIds: undefined,
                  locationIds: undefined,
                  buyerIds: undefined,
                  targetTypes: undefined,
                  query: undefined,
                  specialOrder: undefined,
                })
              }
              label="Clear All"
            />
          ) : null}
          <FlexSpacer />
          <SearchInput
            placeholder="Search groups by name"
            query={hashState.query}
            onQueryChange={(newSearchQuery) => updateHashState({ query: newSearchQuery })}
          />
          <ResultCount count={totalCount} dataTestId="purchase-groups-result-count" />
        </FlexSpace>
      </FlexSpace>
    </FilterBarBox>
  );
}
