import React from 'react';

import { PurchaseTargetLineStatus } from '@recurrency/core-api-schema/dist/common/enums';

import { LineStatusCountsPanelContent } from 'pages/purchasing/PurchaseTargetsPage/PurchaseTargetLinesPage/sidePane/LineStatusCountsPanelContent';

import { SidePane } from 'components/recipes/sidePane/SidePane';

import { ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';

import { TransferTargetSummary } from 'types/hash-state';

import { TOSummaryPanelContent } from './TOSummaryPanelContent';

export function TOSummarySidePane({
  transferSummaries,
  lineStatusCounts,
}: {
  transferSummaries: TransferTargetSummary[];
  lineStatusCounts: Record<PurchaseTargetLineStatus, number>;
}) {
  return (
    <SidePane
      title="Overview"
      settingKey={ViewSettingKey.TransferTargetTOSummarySidePane}
      panels={[
        transferSummaries.length > 0
          ? {
              settingKey: 'transferSummary',
              title: 'Transfer Summary',
              content: <TOSummaryPanelContent targetSummaries={transferSummaries} />,
            }
          : null,
        {
          settingKey: 'inventoryStatus',
          title: 'Inventory Status',
          content: <LineStatusCountsPanelContent lineStatusCounts={lineStatusCounts} />,
        },
      ]}
    />
  );
}
