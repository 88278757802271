import React from 'react';

import {
  P21LeadTimeSource,
  P21SafetyStockType,
  RecurrencyReplenishmentMethod,
} from '@recurrency/core-api-schema/dist/common/enums';
import { ColumnType } from 'antd/lib/table';

import { FlexSpace } from 'components/FlexSpace';
import { ModalSectionTitle } from 'components/Modal/ModalSectionTitle';
import { BadgeStatus } from 'components/recipes/StatusBadge';
import { Table } from 'components/Table';

import { truthy } from 'utils/boolean';
import { MinMaxSubmissionType } from 'utils/track';

import {
  FilterItemsSelection,
  ListItemsSelection,
  MultiModalSelection,
  PlanningBulkUpdate,
  leadTimeSourceNames,
  safetyStockSourceNames,
  formatSafetyStock,
} from './utils';

interface ReplenishmentSetting {
  itemId: string;
  location: string;

  // Min/Max/OP/OQ
  currentMin?: number;
  newMin?: number | undefined;
  currentMax?: number;
  newMax?: number | undefined;
  currentReplenishmentMethod?: string;
  newReplenishmentMethod?: string;

  // Safety Stock
  currentSafetyStock?: number;
  newSafetyStock?: number | undefined;
  currentSafetyStockSource?: P21SafetyStockType;
  newSafetyStockSource?: P21SafetyStockType;

  // Lead Time
  currentLeadTime?: number;
  newLeadTime?: number | undefined;
  currentLeadTimeSource?: P21LeadTimeSource;
  newLeadTimeSource?: P21LeadTimeSource;

  // Include/Exclude
  currentIncludeExcludeStatus?: string;
  newIncludeExcludeStatus?: string;
}

export function MultiUpdateReplenishmentSettingsTable({
  selection,
  bulkUpdate,
  isOnboardingVariant = false,
}: {
  selection: MultiModalSelection;
  bulkUpdate: PlanningBulkUpdate;
  isOnboardingVariant?: boolean;
}) {
  return (
    <FlexSpace direction="column" gap={16} alignItems="stretch">
      <ModalSectionTitle title={selection.type === 'filter' ? 'Filters of Items to Change' : 'Settings to Change'} />
      {selection.type === 'filter' ? (
        <FilterSelectionTable selection={selection} />
      ) : (
        <ListSelectionTable selection={selection} bulkUpdate={bulkUpdate} isOnboardingVariant={isOnboardingVariant} />
      )}
    </FlexSpace>
  );
}

function FilterSelectionTable({ selection }: { selection: FilterItemsSelection }) {
  const filterColumns: ColumnType<{ filter: string; values: string[] }>[] = [
    {
      title: 'Filter',
      dataIndex: 'filter',
      width: '50%',
    },
    {
      title: 'Values',
      dataIndex: 'values',
      render: (values: string[]) => values.join(', '),
    },
  ];

  const filterData = Object.entries(selection.filters || {}).map(([key, value], index) => ({
    key: index,
    filter: selection.filterTitleMapping?.[key] ?? key,
    values: value,
  }));

  const hasFilters = filterData.length > 0;

  return hasFilters ? (
    <Table columns={filterColumns} data={filterData} pagination={false} />
  ) : (
    <p style={{ marginTop: '10px' }}>No filters are selected, all items will be updated.</p>
  );
}

function ListSelectionTable({
  selection,
  bulkUpdate,
  isOnboardingVariant = false,
}: {
  selection: ListItemsSelection;
  bulkUpdate: PlanningBulkUpdate;
  isOnboardingVariant?: boolean;
}) {
  const { columns, data } = getReplenishmentSettingsColumnsAndData(selection, bulkUpdate, isOnboardingVariant);
  return <Table columns={columns} data={data} pageSize={5} />;
}

function getReplenishmentSettingsColumnsAndData(
  selection: ListItemsSelection,
  bulkUpdate: PlanningBulkUpdate,
  isOnboardingVariant = false,
): { columns: ColumnType<ReplenishmentSetting>[]; data: ReplenishmentSetting[] } {
  // Common columns
  let data: ReplenishmentSetting[] = [];
  let columns: ColumnType<ReplenishmentSetting>[] = [];

  const baseColumns: ColumnType<ReplenishmentSetting>[] = [
    {
      title: 'Item',
      dataIndex: 'itemId',
    },
    {
      title: 'Location',
      dataIndex: 'location',
    },
  ];

  // Safety Stock specific columns
  if (bulkUpdate.type === 'safetyStock') {
    columns = [
      ...baseColumns,
      {
        title: 'Current Safety Stock',
        dataIndex: 'currentSafetyStock',
        render: (_, record) => formatSafetyStock(record.currentSafetyStock, record.currentSafetyStockSource!),
      },
      {
        title: 'Current Source',
        dataIndex: 'currentSafetyStockSource',
        render: (value: P21SafetyStockType) => safetyStockSourceNames[value],
      },
      {
        title: 'New Safety Stock',
        dataIndex: 'newSafetyStock',
        render: (_, record) => formatSafetyStock(record.newSafetyStock, record.newSafetyStockSource!),
      },
      {
        title: 'New Source',
        dataIndex: 'newSafetyStockSource',
        render: (value: P21SafetyStockType) => safetyStockSourceNames[value],
      },
    ];

    data = selection.items.map((record) => ({
      itemId: record.item_id,
      location: record.location,
      currentSafetyStock: record.safety_stock_value,
      currentSafetyStockSource: record.safety_stock_type,
      newSafetyStock: bulkUpdate.safetyStock,
      newSafetyStockSource:
        bulkUpdate.safetyStock === undefined ? P21SafetyStockType.defaultDays : P21SafetyStockType.overrideDays,
    }));
  } else if (bulkUpdate.type === 'leadTime') {
    columns = [
      ...baseColumns,
      {
        title: 'Current Lead Time',
        dataIndex: 'currentLeadTime',
      },
      {
        title: 'Current Source',
        dataIndex: 'currentLeadTimeSource',
        render: (value: P21LeadTimeSource) => leadTimeSourceNames[value],
      },
      {
        title: 'New Lead Time',
        dataIndex: 'newLeadTime',
      },
      {
        title: 'New Source',
        dataIndex: 'newLeadTimeSource',
        render: (value: P21LeadTimeSource) => leadTimeSourceNames[value],
      },
    ];

    data = selection.items.map((record) => ({
      itemId: record.item_id,
      location: record.location,
      currentLeadTime: record.lead_time,
      currentLeadTimeSource: record.lead_time_source,
      newLeadTime: bulkUpdate.leadTime ?? record.smart_lead_time,
      newLeadTimeSource: bulkUpdate.leadTime === undefined ? P21LeadTimeSource.smart : P21LeadTimeSource.override,
    }));
  } else if (bulkUpdate.type === 'includeExclude') {
    columns = [
      ...baseColumns,
      {
        title: 'Current Status',
        dataIndex: 'currentIncludeExcludeStatus',
      },
      {
        title: 'New Status',
        dataIndex: 'newIncludeExcludeStatus',
      },
    ];

    data = selection.items.map((record) => ({
      itemId: record.item_id,
      location: record.location,
      currentIncludeExcludeStatus: record.excluded ? 'Excluded' : 'Included',
      newIncludeExcludeStatus: bulkUpdate.exclude ? 'Excluded' : 'Included',
    }));
  } else if (bulkUpdate.type === 'flagged') {
    columns = [
      ...baseColumns,
      {
        title: 'Current Status',
        dataIndex: 'currentFlaggedStatus',
      },
      {
        title: 'New Status',
        dataIndex: 'newFlaggedStatus',
      },
    ];

    data = selection.items.map((record) => ({
      itemId: record.item_id,
      location: record.location,
      currentFlaggedStatus: record.planning_status === BadgeStatus.Flagged ? 'Flagged' : 'Not Flagged',
      newFlaggedStatus: bulkUpdate.flagged ? 'Flagged' : 'Not Flagged',
    }));
  } else if (bulkUpdate.type === 'minMax') {
    // OP/OQ specific columns
    if (bulkUpdate.isOpOqVariant) {
      const isRecommendedMinMax = bulkUpdate.submissionType === MinMaxSubmissionType.RecommendedMinMax;
      columns = [
        ...baseColumns,
        {
          title: isRecommendedMinMax ? 'Current Min' : 'Current OP',
          dataIndex: 'currentMin',
        },
        {
          title: isRecommendedMinMax ? 'Current Max' : 'Current OQ',
          dataIndex: 'currentMax',
        },
        {
          title: 'Current Replenishment Method',
          dataIndex: 'currentReplenishmentMethod',
        },
        {
          title: isRecommendedMinMax ? 'New Min' : 'New OP',
          dataIndex: 'newMin',
        },
        {
          title: isRecommendedMinMax ? 'New Max' : 'New OQ',
          dataIndex: 'newMax',
        },
        {
          title: 'New Replenishment Method',
          dataIndex: 'newReplenishmentMethod',
        },
      ];

      data = selection.items.map((record) => ({
        itemId: record.item_id,
        location: record.location,
        currentMin: record.current_min,
        currentMax: record.current_max,
        currentReplenishmentMethod: record.replenishment_method,
        newMin:
          bulkUpdate.submissionType === MinMaxSubmissionType.Custom ? bulkUpdate.customMin! : record.recommended_min,
        newMax:
          bulkUpdate.submissionType === MinMaxSubmissionType.Custom
            ? bulkUpdate.customMax!
            : bulkUpdate.submissionType === MinMaxSubmissionType.Recommended
            ? record.current_max // OP/OQ recommended doesn't change the max
            : record.recommended_max,
        newReplenishmentMethod: isRecommendedMinMax
          ? RecurrencyReplenishmentMethod.MinMax
          : RecurrencyReplenishmentMethod.OPOQ,
      }));
    } else {
      // Min/Max specific columns
      columns = [
        ...baseColumns,
        isOnboardingVariant
          ? {
              title: 'Current Min',
              dataIndex: 'currentMin',
            }
          : null,
        isOnboardingVariant
          ? {
              title: 'Current Max',
              dataIndex: 'currentMax',
            }
          : null,
        {
          title: 'New Min',
          dataIndex: 'newMin',
        },
        {
          title: 'New Max',
          dataIndex: 'newMax',
        },
        isOnboardingVariant
          ? {
              title: 'Current Replenishment Method',
              dataIndex: 'currentReplenishmentMethod',
            }
          : null,
        isOnboardingVariant
          ? {
              title: 'New Replenishment Method',
              dataIndex: 'newReplenishmentMethod',
            }
          : null,
      ].filter(truthy);

      data = selection.items.map((record) => ({
        itemId: record.item_id,
        location: record.location,
        currentMin: record.current_min,
        currentMax: record.current_max,
        newMin:
          bulkUpdate.submissionType === MinMaxSubmissionType.Custom ? bulkUpdate.customMin! : record.recommended_min,
        newMax:
          bulkUpdate.submissionType === MinMaxSubmissionType.Custom ? bulkUpdate.customMax! : record.recommended_max,
        currentReplenishmentMethod: record.replenishment_method,
        newReplenishmentMethod: RecurrencyReplenishmentMethod.MinMax,
      }));
    }
  }

  return { columns, data };
}
