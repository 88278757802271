import React, { ReactChild, useState } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import {
  TenantTableSyncStatusDTO,
  TenantTableSyncStatusListDTO,
} from '@recurrency/core-api-schema/dist/integrations/tenantIntegrationDTO';
import { Progress } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { fuzzyFilter } from 'fuzzbunny';
import { BarChart, XAxis, YAxis, Tooltip, Legend, CartesianGrid, Bar } from 'recharts';
import { theme } from 'theme';

import { Card, CardHeader, CardSection } from 'components/Card';
import { Container } from 'components/Container';
import { DividerLine } from 'components/DividerLine';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpace } from 'components/FlexSpace';
import { Input } from 'components/Input';
import { CenteredLoader } from 'components/Loaders';
import { Table } from 'components/Table';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useCoreApi } from 'hooks/useApi';

import { capitalizeFirst, formatDate, formatNumber } from 'utils/formatting';
import { sortableStringColumn, sortableNumberColumn } from 'utils/tables';

import { EditableDateCell } from './EditableDateCell';

// Define the styles using @emotion/css
const changeTrackingStyles = css`
  font-weight: bold;
  color: red;
`;

export interface TenantTableSyncStatusTableProps {
  tableStatusList?: TenantTableSyncStatusDTO[];
  tenantId: string;
  onDataChanged: () => void;
}

export interface TenantTableSyncStatusChartProps {
  tableStatusList?: TenantTableSyncStatusDTO[];
}

export function TenantSyncStatusTable({ tableStatusList, tenantId, onDataChanged }: TenantTableSyncStatusTableProps) {
  const tableStatusColumns: ColumnType<TenantTableSyncStatusDTO>[] = [
    sortableStringColumn({
      title: 'Source Table',
      dataIndex: 'sourceTableName',
    }),
    sortableStringColumn({
      title: 'Target Table',
      dataIndex: 'targetTableName',
    }),
    sortableStringColumn({
      title: 'Synced to (Tenant TZ)',
      dataIndex: 'dateLastModified',
      render: (value, record) => (
        <EditableDateCell
          dateType="Modified"
          tenantId={tenantId}
          tableName={record.targetTableName}
          dbType={record.dbType}
          initialDate={value}
          onDataChanged={onDataChanged}
        />
      ),
    }),
    sortableStringColumn({
      title: 'Updated At',
      dataIndex: 'updatedAt',
      render: (value, record) => (
        <EditableDateCell
          dateType="Updated"
          tenantId={tenantId}
          tableName={record.targetTableName}
          dbType={record.dbType}
          initialDate={value}
          onDataChanged={onDataChanged}
        />
      ),
    }),
    sortableStringColumn({
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (value) => formatDate(value),
    }),
    sortableNumberColumn({
      title: 'Diff',
      dataIndex: 'countDiff',
      render: (_, record) => {
        if (
          record.sourceTableCount === null ||
          record.sourceTableCount === undefined ||
          record.targetTableCount === null ||
          record.targetTableCount === undefined
        ) {
          return '-';
        }
        return (record.sourceTableCount - record.targetTableCount).toLocaleString();
      },
    }),
    sortableNumberColumn({
      title: 'Diff %',
      dataIndex: 'diffPercentage',
      render: (value) => {
        if (value === null || value === undefined) {
          return '-';
        }
        //  * = more than 1% in difference
        value = Math.abs(value);
        value = Math.round(value * 100) / 100; // 2 decimal places
        if (value > 1) return `*${value}`;
        return value;
      },
    }),
    sortableNumberColumn({
      title: 'Src Table Count',
      dataIndex: 'sourceTableCount',
    }),
    sortableNumberColumn({
      title: 'Dest Table Count',
      dataIndex: 'targetTableCount',
    }),
    sortableStringColumn({
      title: 'Sync Strategy',
      dataIndex: 'syncStrategy',
      render: (value) => {
        if (value === null || value === undefined) {
          return '-';
        }
        if (value === 'sync_change_tracking') {
          return <div className={changeTrackingStyles}>Change Tracking</div>;
        }
        return value;
      },
    }),
    sortableStringColumn({
      title: 'Last Comparator Run',
      dataIndex: 'diffCreatedAt',
      render: (value) => formatDate(value, true),
    }),
    sortableStringColumn({
      title: 'Type',
      dataIndex: 'dbType',
      render: (value) => (
        <div
          title={value === 'postgres' ? 'Tenant DB -> Postgres' : value}
          className={css`
            cursor: help;
          `}
        >
          {value}
        </div>
      ),
    }),
  ];

  const [query, setQuery] = useState('');
  let filteredList: { sourceTableName: string; dbType: string }[] = [];
  if (tableStatusList) {
    filteredList = fuzzyFilter(tableStatusList || [], query, {
      fields: ['sourceTableName', 'dbType'],
    })
      .map(({ item }) => item)
      .sort((a, b) => (b.countDiff ?? 0) - (a.countDiff ?? 0));
  }

  let isChangeTrackingEnabled = false;
  if (tableStatusList) {
    // Check if at least one instance of 'sync_change_tracking' exists in syncStrategy
    isChangeTrackingEnabled = tableStatusList.some((item) => item.syncStrategy === 'sync_change_tracking');
  }

  return (
    <>
      {isChangeTrackingEnabled && <p className={changeTrackingStyles}> Change Tracking Enabled!</p>}
      <FilterBarBox>
        <Input
          placeholder="Filter Table or Type"
          title="search"
          onChange={(ev) => setQuery(ev.target.value)}
          style={{ maxWidth: '300px' }}
        />
      </FilterBarBox>
      <p>* = need attention, more than 1% in difference or one day in delay</p>
      <Table
        columns={tableStatusColumns}
        data={filteredList}
        rowKey={(record) => `${record.sourceTableName}-${record.targetTableName}-${record.dbType}`}
        isLoading={!tableStatusList}
      />
    </>
  );
}

export function TenantSyncDiffChart({ tableStatusList }: TenantTableSyncStatusChartProps) {
  const data = [];
  const temp_list = tableStatusList || [];
  // due to the nature of the sync, there's an uneven distribution of tables with sync diff percentage,
  // here I pick below 0, 0-10 and > 10 percentage point as arbitrary yet good grouping thresholds
  const max_relevant_percentage = 10;
  // calc tables with sync diff below 0 percent
  data.push({
    range: `[-,${0}]`,
    tables_in_diff_percent_range: temp_list.filter((x) => (x.diffPercentage ?? 0) < 0).length,
  });
  // calc tables with sync diff between 0 and 10 percent
  let i = 1;
  for (; i <= max_relevant_percentage; i++) {
    const upper = i;
    const lower = i - 1;
    const segment_count = temp_list.filter(
      (x) => (x.diffPercentage ?? 0) >= lower && (x.diffPercentage ?? 0) < upper,
    ).length;
    data.push({ range: `[${lower},${upper}]`, tables_in_diff_percent_range: segment_count });
  }
  // cal tables with sync diff above 10 percent
  data.push({
    range: `[${i - 1}, >]`,
    tables_in_diff_percent_range: temp_list.filter((x) => (x.diffPercentage ?? 0) >= i - 1).length,
  });

  return (
    <BarChart width={700} height={350} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="range" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="tables_in_diff_percent_range" fill={theme.colors.chart.lagoonBlue} />
    </BarChart>
  );
}

function StatLine({ label, value }: { label: string | ReactChild; value: string | ReactChild }) {
  return (
    <FlexSpace justify="space-between">
      <strong>{label}:</strong>
      <span>{value}</span>
    </FlexSpace>
  );
}

function tableCompletedTotal(tableStatusList: TenantTableSyncStatusListDTO | undefined) {
  const data = { complete: 0, total: 0 };
  if (!tableStatusList) {
    return data;
  }
  data.total = tableStatusList.totalCount;
  data.complete = tableStatusList.items.reduce((sum: number, current: TenantTableSyncStatusDTO) => {
    if (current.diffPercentage === null) return sum;
    return sum + (current.diffPercentage ?? 0 < 1 ? 1 : 0);
  }, 0);

  return data;
}

function daysToSync(
  currentDiff: number,
  avgDailySyncDiff: number,
  tableStatusList: TenantTableSyncStatusListDTO,
): number {
  const { complete, total } = tableCompletedTotal(tableStatusList);
  return Math.floor(currentDiff / avgDailySyncDiff / Math.max(total - complete, 1));
}

export function TenantSyncStatusTab({ tenantId, integrationStatus }: { tenantId: string; integrationStatus: string }) {
  const {
    data: tableStatusList,
    reload: reloadTableStatusList,
    isLoading: tableStatusIsLoading,
  } = useCoreApi(schemas.integrations.getTenantTableSyncStatusList, {
    pathParams: { tenantId },
  });

  const { data: syncStatusKpis, isLoading: syncStatusIsLoading } = useCoreApi(
    schemas.integrations.getTenantSyncPerfomanceKpis,
    {
      pathParams: { tenantId },
    },
  );

  const { data: onboardingDateData } = useCoreApi(schemas.integrations.getTenantOnboardingDate, {
    pathParams: { tenantId },
  });

  if (tableStatusIsLoading || syncStatusIsLoading) {
    return <CenteredLoader />;
  }

  const { complete, total } = tableCompletedTotal(tableStatusList);

  let dateToSync = new Date().toLocaleDateString();
  if (tableStatusList && syncStatusKpis) {
    const days = daysToSync(syncStatusKpis.currentDiff, syncStatusKpis.avgDailySyncDiff, tableStatusList);
    const d = new Date();
    d.setDate(d.getDate() + days);
    dateToSync = d.toLocaleString();
  }
  return (
    <Container>
      {syncStatusKpis && tableStatusList && (
        <FlexSpace
          gap={8}
          alignItems="flex-start"
          justify="flex-start"
          fullWidth
          className={css`
            margin-bottom: 24px;
          `}
        >
          <Card
            className={css`
              width: 20%;
              min-width: 300px;
              height: 400px;
            `}
          >
            <CardHeader title="Status" />
            <CardSection>
              <Progress percent={Math.floor(syncStatusKpis.currentPercentComplete)} />
            </CardSection>
            <CardSection>
              <StatLine label="Total ERP Rows" value={formatNumber(syncStatusKpis.currentErpCount)} />
            </CardSection>
            <CardSection>
              <StatLine label="Total Synced Rows" value={formatNumber(syncStatusKpis.currentRecurrencyCount)} />
            </CardSection>
            <CardSection>
              <StatLine label="Avg Daily Volume / Table" value={formatNumber(syncStatusKpis.avgDailySyncDiff)} />
            </CardSection>
            <CardSection>
              <StatLine label="Completed / Total Tables" value={`${formatNumber(complete)} / ${formatNumber(total)}`} />
            </CardSection>
            <CardSection>
              <StatLine
                label={
                  <InfoTooltip
                    title={
                      <span>
                        Approximation <br />
                        [Remaining Rows / Avg Daily Volume / Remaining Tables]
                      </span>
                    }
                  >
                    Days-to-sync
                  </InfoTooltip>
                }
                value={`${formatNumber(
                  daysToSync(syncStatusKpis.currentDiff, syncStatusKpis.avgDailySyncDiff, tableStatusList),
                )} (${formatDate(dateToSync)})
                  `}
              />
            </CardSection>
          </Card>
          <Card
            className={css`
              width: 20%;
              min-width: 300px;
              height: 400px;
            `}
          >
            <CardHeader title="Integration Info" />
            <CardSection>
              <StatLine label="Onboarding Start Date" value={formatDate(onboardingDateData?.onboardingDate)} />
            </CardSection>
            <CardSection>
              <StatLine label="Current Status" value={capitalizeFirst(integrationStatus)} />
            </CardSection>
          </Card>
          <Card
            className={css`
              height: 400px;
            `}
          >
            <CardHeader title="Table Diff Percent Ranges" />
            <br />
            <TenantSyncDiffChart tableStatusList={tableStatusList?.items} />
          </Card>
        </FlexSpace>
      )}
      <DividerLine />
      <TenantSyncStatusTable
        tableStatusList={tableStatusList?.items}
        tenantId={tenantId}
        onDataChanged={reloadTableStatusList}
      />
    </Container>
  );
}
