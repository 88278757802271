import React from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { ForecastAccuracyDTO } from '@recurrency/core-api-schema/dist/ml/getForecastAccuracyByMonth';
import moment from 'moment';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { convertToMultiSelectProps } from 'components/AsyncSelect/useAsyncMultiSelectProps';
import {
  useItemGroupsSelectProps,
  useItemsSelectProps,
  useLocationsSelectProps,
  usePurchaseClassSelectProps,
} from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { Container } from 'components/Container';
import { FlexSpace } from 'components/FlexSpace';
import { PageHeader } from 'components/PageHeader';

import { useHashState } from 'utils/routes';
import { PersistedColumn } from 'utils/tableAndSidePaneSettings/types';
import { asKeyOf, sortableDollarWithCentsColumn, sortableIdColumn, sortableNumberColumn } from 'utils/tables';

import { ForecastAccuracyHashState } from 'types/hash-state';

// const forecastAccuracyViewOptions = [
//   { value: 'metrics', label: 'Metrics' },
//   { value: 'months', label: 'Months' },
// ];

const forecastAccuracyBreakdownOptions = [
  { value: 'locationId', label: 'Location' },
  { value: 'purchaseClass', label: 'ABC Class' },
  { value: 'itemGroupId', label: 'Product Group' },
  { value: 'itemId', label: 'Item' },
];

// this is kept seperate so that when we add columns for months, we can base them off these dates
const last12Months = Array.from({ length: 12 }, (_, i) => {
  const date = moment().subtract(i, 'months').startOf('month');
  return date;
});

const last12MonthsOptions = last12Months.map((date) => ({
  value: date.format('M-YYYY'),
  label: date.format('MMM YYYY'),
}));

export const ForecastAccuracyPage = () => {
  const [hashState, updateHashState] = useHashState<ForecastAccuracyHashState>();
  const { breakdown = 'locationId', month = last12MonthsOptions[0].value } = hashState;
  const itemsSelectProps = useItemsSelectProps();
  const locationsSelectProps = useLocationsSelectProps({});
  const itemClassProps = usePurchaseClassSelectProps();
  const productGroupProps = useItemGroupsSelectProps();

  const tableProps = useCoreApiTableProps({
    schema: schemas.ml.getForecastAccuracyByMonth,
    queryParams: {
      groupBy: breakdown,
      usageMonth: month,
      filters: {
        itemIds: hashState.items,
        locationIds: hashState.locations,
        purchaseClasses: hashState.classes,
        itemGroupIds: hashState.groups,
      },
    },
    initialState: {
      sortBy: 'groupedId',
      sortDir: 'asc',
    },
  });

  const columns: PersistedColumn<ForecastAccuracyDTO>[] = [
    sortableIdColumn({
      title: forecastAccuracyBreakdownOptions.find((item) => item.value === (hashState.breakdown ?? 'locationId'))
        ?.label,
      dataIndex: asKeyOf<ForecastAccuracyDTO>('groupedId'),
      settingKey: 'groupedId',
      sorter: true,
    }),
    {
      title: 'Forecast Error',
      settingKey: 'forecastError',
      children: [
        sortableNumberColumn({
          title: 'Average',
          dataIndex: asKeyOf<ForecastAccuracyDTO>('avgForecastError'),
          settingKey: 'avgForecastError',
          sorter: true,
        }),
        sortableNumberColumn({
          title: 'Median',
          dataIndex: asKeyOf<ForecastAccuracyDTO>('medianForecastError'),
          settingKey: 'medianForecastError',
          sorter: true,
        }),
        sortableDollarWithCentsColumn({
          title: '$ Error',
          dataIndex: asKeyOf<ForecastAccuracyDTO>('sumForecastDollarError'),
          settingKey: 'sumForecastDollarError',
          sorter: true,
        }),
        sortableDollarWithCentsColumn({
          title: '$ Over',
          dataIndex: asKeyOf<ForecastAccuracyDTO>('sumOverForecastDollarError'),
          settingKey: 'sumOverForecastDollarError',
          sorter: true,
        }),
        sortableDollarWithCentsColumn({
          title: '$ Under',
          dataIndex: asKeyOf<ForecastAccuracyDTO>('sumUnderForecastDollarError'),
          settingKey: 'sumUnderForecastDollarError',
          sorter: true,
        }),
      ],
    },
    {
      title: 'Base Forecast Error',
      settingKey: 'forecastError',
      children: [
        sortableNumberColumn({
          title: 'Average',
          dataIndex: asKeyOf<ForecastAccuracyDTO>('avgBaseForecastError'),
          settingKey: 'avgBaseForecastError',
          sorter: true,
        }),
        sortableNumberColumn({
          title: 'Median',
          dataIndex: asKeyOf<ForecastAccuracyDTO>('medianBaseForecastError'),
          settingKey: 'medianBaseForecastError',
          sorter: true,
        }),
        sortableDollarWithCentsColumn({
          title: '$ Error',
          dataIndex: asKeyOf<ForecastAccuracyDTO>('sumBaseForecastDollarError'),
          settingKey: 'sumBaseForecastDollarError',
          sorter: true,
        }),
        sortableDollarWithCentsColumn({
          title: '$ Over',
          dataIndex: asKeyOf<ForecastAccuracyDTO>('sumBaseOverForecastDollarError'),
          settingKey: 'sumBaseOverForecastDollarError',
          sorter: true,
        }),
        sortableDollarWithCentsColumn({
          title: '$ Under',
          dataIndex: asKeyOf<ForecastAccuracyDTO>('sumBaseUnderForecastDollarError'),
          settingKey: 'sumBaseUnderForecastDollarError',
          sorter: true,
        }),
      ],
    },
  ];

  return (
    <Container>
      <FlexSpace>
        <PageHeader title="Forecast Accuracy Reporting" />
      </FlexSpace>
      <FlexSpace
        gap={8}
        className={css`
          margin-bottom: 12px;
        `}
      >
        <div>View for</div>
        {/* This will be added back in the future when we finish up forecast accuracy
         <AsyncMultiSelect
          mode="single"
          selectProps={{ options: forecastAccuracyViewOptions }}
          selectedValues={[hashState.view ?? 'metrics']}
          onSelectedValuesChange={(values: string[]) => updateHashState({ view: values[0] })}
        /> 
        <div>for</div> */}
        <AsyncMultiSelect
          mode="single"
          selectProps={{ options: last12MonthsOptions }}
          selectedValues={[hashState.month ?? last12MonthsOptions[0].value]}
          onSelectedValuesChange={(values: string[]) => updateHashState({ month: values[0] })}
        />
      </FlexSpace>
      <FlexSpace
        gap={8}
        className={css`
          margin-bottom: 12px;
        `}
      >
        <div>Breakdown by</div>{' '}
        <AsyncMultiSelect
          mode="single"
          selectProps={{ options: forecastAccuracyBreakdownOptions }}
          selectedValues={[hashState.breakdown ?? 'locationId']}
          onSelectedValuesChange={(values: string[]) => updateHashState({ breakdown: values[0] })}
        />
      </FlexSpace>
      <FlexSpace
        gap={8}
        className={css`
          margin-bottom: 12px;
        `}
      >
        <div>Filters</div>
        <AsyncMultiSelect
          selectProps={convertToMultiSelectProps(itemsSelectProps)}
          label="Item"
          queryPlaceholder="Search items"
          selectedValues={hashState.items ?? []}
          onSelectedValuesChange={(values: string[]) => updateHashState({ items: values })}
        />
        <AsyncMultiSelect
          label="Location"
          queryPlaceholder="Search locations"
          selectProps={convertToMultiSelectProps(locationsSelectProps)}
          selectedValues={hashState.locations ?? []}
          onSelectedValuesChange={(values: string[]) => updateHashState({ locations: values })}
        />
        <AsyncMultiSelect
          label="ABC Class"
          queryPlaceholder="Search item classes"
          selectProps={convertToMultiSelectProps(itemClassProps)}
          selectedValues={hashState.classes ?? []}
          onSelectedValuesChange={(values: string[]) => updateHashState({ classes: values })}
        />
        <AsyncMultiSelect
          label="Product Group"
          queryPlaceholder="Search product groups"
          selectProps={convertToMultiSelectProps(productGroupProps)}
          selectedValues={hashState.groups ?? []}
          onSelectedValuesChange={(values: string[]) => updateHashState({ groups: values })}
        />
      </FlexSpace>
      <AsyncTable tableProps={tableProps} columns={columns} />
    </Container>
  );
};
