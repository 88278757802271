import { defaultConfig, TenantConfig } from './default';

export function getConfig(): TenantConfig {
  const config = { ...defaultConfig };
  config.itemsListPage.hideProductGroupFilter = true;
  // This can likely be removed to migrate to the default (false), but keeping as is for
  // smooth change management. Read more at https://recurrency.atlassian.net/browse/PE-4128
  config.purchaseOrderEntryFlow.formConfig.email.defaultValue = undefined;
  return config;
}
