import { PurchaseTargetLineRow } from 'pages/purchasing/PurchaseTargetsPage/types';

import { AllocationRowWithKey } from './types';

// Uses line state to determine current allocation and select rows accordingly
export function getInitialAllocationState(targetLine: PurchaseTargetLineRow, itemsWithKeys: AllocationRowWithKey[]) {
  // If the user has previously selected orders, we maintain that state
  if (targetLine?.specialOrderLinks?.length) {
    return targetLine.specialOrderLinks.map(
      ({ salesOrderLineNo, salesOrderNo }) => `${salesOrderNo}-${salesOrderLineNo}`,
    );
  }
  // If the qty hasn't been edited, everything is selected
  if (targetLine.userQtyForSpecialOrders === targetLine.qtyForSpecialOrders) {
    return itemsWithKeys.map(({ key }) => key);
  }
  // If the qty has been cleared, we clear the selection
  return [];
}

export function createAllocationPayload(
  itemsWithKeys: AllocationRowWithKey[],
  selectedRowKeys: string[],
  targetLine: PurchaseTargetLineRow,
  selectedQuantitySum: number,
) {
  // No change made
  if (selectedQuantitySum === targetLine.userQtyForSpecialOrders) return { changesMade: false };

  // collect new allocation
  const selectedLines = itemsWithKeys?.filter((item) => selectedRowKeys.includes(item.key)) || [];
  const transformedResult = selectedLines.map((line) => ({
    salesOrderNo: line.orderNumber,
    salesOrderLineNo: line.lineNumber,
    linkQuantity: line.linkQuantity,
  }));
  return {
    changesMade: true,
    updatedAllocation: transformedResult,
    totalQtySelected: selectedQuantitySum,
  };
}
