import React from 'react';

import { SearchForecastDTO } from '@recurrency/core-api-schema/dist/search/getSearchForecasts';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';

import { InventoryUsageForecastView } from 'components/recipes/InventoryUsageForecastView';
import { ViewAllModal } from 'components/recipes/sidePane/ViewAllModal';

import { usePromise } from 'hooks/usePromise';

import { captureError } from 'utils/error';
import { formatIdNameObj, splitIdNameStr } from 'utils/formatting';
import { searchIndex } from 'utils/search/search';

export function DemandPlanningContextModal({
  itemId,
  itemUid,
  location,
  onClose,
  onForecastOverridesUpdated,
}: {
  itemId: string;
  itemUid: string;
  location: string;
  onClose: () => void;
  onForecastOverridesUpdated?: () => void;
}) {
  const locationObj = splitIdNameStr(location);
  const locationId = locationObj.foreignId;
  const forecastRecordId = `${itemUid}|${locationId}`;
  const {
    data: searchIndexResponse,
    isLoading,
    reload: reloadForecastRecord,
  } = usePromise(
    () =>
      searchIndex<SearchForecastDTO>({
        indexName: SearchIndexName.Forecasts,
        filters: { id: [forecastRecordId] },
        hitsPerPage: 1,
      }),
    [locationId, itemId],
    {
      cacheKey: `searchframe/${SearchIndexName.Forecasts}:${forecastRecordId}`,
      onError: captureError,
    },
  );

  const data = searchIndexResponse?.hits;

  return ViewAllModal({
    title: `Usage & Forecast for Item #${itemId} at Location ${formatIdNameObj(locationObj)}`,
    content: (
      <InventoryUsageForecastView
        forecastRecords={data}
        itemUid={itemUid}
        locations={[locationObj]}
        isLoading={isLoading}
        onForecastOverridesUpdated={() => {
          reloadForecastRecord();
          onForecastOverridesUpdated?.();
        }}
      />
    ),
    onClose,
  });
}
