import { SupplierTargetTypes } from '@recurrency/core-api-schema/dist/common/enums';
import { ISODateStr } from '@recurrency/core-api-schema/dist/common/types';
import { AllocatedOrderLinePayload } from '@recurrency/core-api-schema/dist/purchaseOrders/createPurchaseOrder';
import { BasePurchaseTargetLinesDTO } from '@recurrency/core-api-schema/dist/purchasing/basePurchaseTargetLines';
import { PurchaseTargetLineDTO } from '@recurrency/core-api-schema/dist/purchasing/getPurchaseTargetLines';
import urltron from 'urltron';

import { PurchasingLineTransfer } from 'types/hash-state';
import { IdNameObj } from 'types/legacy-api';

export interface SortResponse {
  sortBy: string;
  sortDir: 'asc' | 'desc';
}
export interface PurchaseTargetLineRow extends PurchaseTargetLineDTO {
  /** `${supplierId}|{locationId}|{itemId}` */
  key: string;
  /** ${line.supplierId}|${line.locationId} OR ${line.supplierId}|${line.purchaseLocationId} */
  poKey?: string;
  /** user defined qtyToOrder */
  userQtyToOrder: number;
  /** user defined unitCost */
  userUnitCost: number;
  /** user defined transfers */
  userTransfers?: PurchasingLineTransfer[];
  /** user defined requiredDated */
  userRequiredDate?: string;
  /** Default value of requiredDate, based on the dueDate of the PO */
  placeholderRequiredDate?: string;
  /** user allocation for special orders */
  userQtyForSpecialOrders?: number;
  /** if specific special orders were chosen, we save the links here */
  specialOrderLinks?: AllocatedOrderLinePayload[];
}

export interface PurchaseAutoTrimFillTableRow extends PurchaseTargetLineRow {
  /** recommended qty based on algorithm, allow undefined */
  recommendedQtyToOrder?: number;
  /** qty base on value in the current lines */
  currentQtyToOrder: number;
  /** days of supply based on po order qty */
  projectedDaysOfSupply?: number | null;
}

export enum DocSummaryType {
  PurchaseOrder = 'PO',
  TransferOrder = 'TO',
}

export interface POSummary {
  kind: DocSummaryType.PurchaseOrder;
  purchasingLocation: IdNameObj;
  requirementLocation?: IdNameObj;
  supplier: IdNameObj;
  vendor: IdNameObj;
  targetType: SupplierTargetTypes;
  currentValue: number;
  targetValue: number;
  totalPOCost: number;
  totalLines: number;
  lines: GenericTargetLineRow[];
  companyId?: string;
  buyer?: IdNameObj;
  carrier?: IdNameObj;
  approved?: boolean;
  dueDate?: ISODateStr;
  groupName?: string;
  groupId?: string;
}

export interface GroupedPOSummary extends POSummary {
  children: POSummary[];
}

export interface TransferSummary {
  kind: DocSummaryType.TransferOrder;
  companyId?: string;
  sourceLocation: IdNameObj;
  destinationLocation: IdNameObj;
  carrier?: IdNameObj;
  approved?: boolean;
  totalLines: number;
  transferDays?: number;
  totalCost: number;
  totalWeight: number;
  lines: TransferSummaryLine[];
}

export interface TransferSummaryLine {
  purchaseLineKey: string;
  itemId: string;
  itemName: string;
  itemUid: string;
  unitOfMeasure: string;
  unitQuantity: number;
  unitSize: number;
  qtyAvailable: number;
}

export interface GenericTargetLineDTO extends BasePurchaseTargetLinesDTO {
  locationId?: string;
  locationName?: string;
  potentialTransfer?: boolean;
  purchaseLocationId?: string;
  purchaseLocationName?: string;
  groupId?: string;
  groupName?: string;
  spokeLocations?: IdNameObj[];
  requiredDate?: ISODateStr;
}

export interface GenericTargetLineRow extends GenericTargetLineDTO {
  /** `${supplierId}|{locationId}|{itemId}` OR `${groupId}|{itemId}` */
  key: string;
  /** ${line.supplierId}|${line.locationId} OR ${line.supplierId}|${line.purchaseLocationId} */
  poKey?: string;
  /** user defined qtyToOrder */
  userQtyToOrder: number;
  /** user defined unitCost */
  userUnitCost: number;
  /** user defined transfers */
  userTransfers?: PurchasingLineTransfer[];
  /** user defined requiredDate */
  userRequiredDate?: string;
  /** Default value of requiredDate, based on the dueDate of the PO */
  placeholderRequiredDate?: string;
  /** user allocation for special orders */
  userQtyForSpecialOrders?: number;
  /** if specific special orders were chosen, we save the links here */
  specialOrderLinks?: AllocatedOrderLinePayload[];
}

export interface GenericAutoTrimFillTableRow extends GenericTargetLineRow {
  /** recommended qty based on algorithm, allow undefined */
  recommendedQtyToOrder?: number;
  /** qty base on value in the current lines */
  currentQtyToOrder: number;
  /** days of supply based on po order qty */
  projectedDaysOfSupply?: number | null;
}

// Atypical purchasing types that should be added to quantity (drop-ship, production)
export enum PurchasingType {
  // type: semantic name
  SpecialOrder = 'SpecialOrder',
}

export interface PurchasingTypeConfig {
  /* condition: function that returns true if the type is enabled ex. tenantSettings/FF */
  condition: () => boolean;
  /* qtyField: field that should be added to the qty if condition is true */
  qtyField: keyof GenericTargetLineRow;
}

export function getIsGroupBuy() {
  try {
    return urltron.parse(window.location.hash).groupBuy;
  } catch (e) {
    return true;
  }
}
