import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { EnvironmentOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { ErpOrderStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { PurchaseOrderLineDTO } from '@recurrency/core-api-schema/dist/v2/purchaseOrders/index';
import { Radio } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { AsyncSelectVariant, tableFilterSelectClassName } from 'components/AsyncSelect';
import { AsyncMultiSelect, filterMultiSelectOptions } from 'components/AsyncSelect/AsyncMultiSelect';
import { SearchSelect } from 'components/AsyncSelect/SearchSelect';
import { convertToMultiSelectProps } from 'components/AsyncSelect/useAsyncMultiSelectProps';
import { useLocationsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { DateRangePicker } from 'components/DatePicker';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpacer } from 'components/FlexSpacer';
import { ButtonLink } from 'components/Links';
import { RadioGroup } from 'components/Radio';
import { ResultCount } from 'components/ResultCount';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { optArrFromVal } from 'utils/array';
import { DateFilter, formatMomentDateToSqlDate, getDefaultDateFilter } from 'utils/date';
import { filterCostAndGM } from 'utils/filterCostAndGM';
import { splitIdNameStr } from 'utils/formatting';
import { routes } from 'utils/routes';
import {
  sortableIdColumn,
  sortableStringColumn,
  sortableNumberColumn,
  sortableDateColumn,
  sortableDollarWithCentsColumn,
  erpStatusColumn,
  asKeyOf,
} from 'utils/tables';
import { isTenantErpTypeP21 } from 'utils/tenants';

export const PurchaseOrderLinesTable = ({
  itemId,
  companyId,
  vendorId,
  locationIds,
}: {
  itemId?: string | undefined;
  companyId?: string;
  vendorId?: string;
  locationIds?: string[];
}) => {
  const { activeTenant } = useGlobalApp();
  const columns: ColumnType<PurchaseOrderLineDTO>[] = [
    sortableIdColumn({
      title: 'PO No',
      dataIndex: asKeyOf<PurchaseOrderLineDTO>('purchaseOrderNumber'),
      render: (id: string) => <Link to={routes.purchasing.purchaseOrderDetails(id)}>{id}</Link>,
      sorter: true,
    }),
    erpStatusColumn(),
    sortableDateColumn({
      title: 'Order Date',
      dataIndex: asKeyOf<PurchaseOrderLineDTO>('orderDate'),
      sorter: true,
      defaultSortOrder: 'descend',
    }),
    sortableDateColumn({
      title: 'Date Due',
      dataIndex: asKeyOf<PurchaseOrderLineDTO>('dueDate'),
      sorter: true,
    }),
    itemId
      ? null
      : sortableStringColumn({
          title: 'Item',
          dataIndex: asKeyOf<PurchaseOrderLineDTO>('itemCode'),
          render: (_, record: PurchaseOrderLineDTO) => (
            <Link to={routes.purchasing.itemDetails(record.itemCode)}>
              {record.itemCode}: {record.itemName}
            </Link>
          ),
          sorter: true,
        }),
    sortableStringColumn({
      title: 'Location',
      dataIndex: asKeyOf<PurchaseOrderLineDTO>('locationId'),
      render: (_, record: PurchaseOrderLineDTO) =>
        record.locationId ? `${record.locationId}: ${record.locationName}` : '-',
      sorter: true,
    }),
    !vendorId
      ? sortableStringColumn({
          title: 'Vendor',
          dataIndex: asKeyOf<PurchaseOrderLineDTO>('vendorId'),
          render: (_, record: PurchaseOrderLineDTO) =>
            record.vendorId ? (
              <Link to={routes.purchasing.vendorDetails(record.vendorId)}>
                {record.vendorId}: {record.vendorName}
              </Link>
            ) : (
              '-'
            ),
          sorter: true,
        })
      : null,
    sortableNumberColumn({
      title: 'Qty Ordered',
      dataIndex: asKeyOf<PurchaseOrderLineDTO>('quantityOrdered'),
      sorter: true,
    }),
    sortableNumberColumn({
      title: 'Qty Received',
      dataIndex: asKeyOf<PurchaseOrderLineDTO>('quantityReceived'),
      sorter: true,
    }),
    sortableStringColumn({
      title: 'UOM',
      dataIndex: asKeyOf<PurchaseOrderLineDTO>('unitOfMeasure'),
      sorter: true,
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Cost',
      dataIndex: asKeyOf<PurchaseOrderLineDTO>('unitPrice'),
      sorter: true,
    }),
    isTenantErpTypeP21(activeTenant.erpType)
      ? sortableDateColumn({
          title: 'Required Date',
          dataIndex: asKeyOf<PurchaseOrderLineDTO>('requiredDate'),
          sorter: true,
        })
      : null,
    {
      render: ({ purchaseOrderNumber }: { purchaseOrderNumber: string }) =>
        ButtonLink(routes.purchasing.purchaseOrderDetails(purchaseOrderNumber)),
    },
  ].filter(filterCostAndGM);

  const locationsSelectProps = useLocationsSelectProps({ sortByValueProp: true });
  const [status, setStatus] = useState<ErpOrderStatus>();
  const [filteredLocationIds, setFilteredLocationIds] = useState<string[]>([]);
  const [filteredVendorId, setFilteredVendorId] = useState<string | undefined>(vendorId);
  const [filteredDate, setFilteredDate] = useState<DateFilter>(getDefaultDateFilter);

  const tableProps = useCoreApiTableProps({
    schema: schemas.purchaseOrderLines.getPurchaseOrderLinesV2,
    queryParams: {
      filter: {
        status,
        itemId,
        companyIds: optArrFromVal(companyId),
        vendorId: filteredVendorId,
        locationIds: filteredLocationIds.length > 0 ? filteredLocationIds : locationIds,
        orderDateFrom: formatMomentDateToSqlDate(filteredDate.from),
        orderDateTo: formatMomentDateToSqlDate(filteredDate.to),
      },
    },
  });

  const { setPage } = tableProps;
  useEffect(() => {
    setPage(1);
  }, [filteredLocationIds, filteredVendorId, status, setPage]);

  return (
    <>
      <FilterBarBox>
        <RadioGroup value={status} onChange={({ target: { value } }) => setStatus(value)}>
          <Radio.Button value={undefined}>All</Radio.Button>
          <Radio.Button value={ErpOrderStatus.Open}>Open</Radio.Button>
          <Radio.Button value={ErpOrderStatus.Completed}>Completed</Radio.Button>
        </RadioGroup>
        {(!locationIds || locationIds.length > 1) && (
          <AsyncMultiSelect
            selectProps={filterMultiSelectOptions(convertToMultiSelectProps(locationsSelectProps), locationIds)}
            label="Location"
            queryPlaceholder="Search locations"
            selectedValues={filteredLocationIds}
            onSelectedValuesChange={(values) => setFilteredLocationIds(values)}
            icon={<EnvironmentOutlined />}
          />
        )}
        {!vendorId && (
          <SearchSelect
            searchParams={{ searchIndex: SearchIndexName.Vendors }}
            className={tableFilterSelectClassName}
            entityPlural="vendors"
            variant={AsyncSelectVariant.Filter}
            onSelect={(_, option) => setFilteredVendorId(splitIdNameStr(option.value).foreignId)}
            onClear={() => setFilteredVendorId(undefined)}
            allowClear
            size="small"
          />
        )}
        <FlexSpacer />
        <DateRangePicker
          value={[filteredDate.from, filteredDate.to]}
          onChange={(values) => setFilteredDate(values ? { from: values[0], to: values[1] } : getDefaultDateFilter())}
          format="MM/DD/YYYY"
        />
        <ResultCount count={tableProps.totalCount} />
      </FilterBarBox>
      <AsyncTable tableProps={tableProps} columns={columns} />
    </>
  );
};
