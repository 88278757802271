import React from 'react';

import { Link } from 'react-router-dom';

import {
  AppstoreOutlined,
  CheckOutlined,
  DashboardOutlined,
  EnvironmentOutlined,
  ShopOutlined,
  TagOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/css';
import { SearchInventoryStatusDTO } from '@recurrency/core-api-schema/dist/search/getSearchInventoryStatus';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';

import { DownloadButton } from 'components/recipes/DownloadButton';
import { NetStockPopover } from 'components/recipes/equation/NetStockPopover';
import { QtyToOrderPopover } from 'components/recipes/equation/QtyToOrderPopover';
import { SearchFrame, ValueFacet } from 'components/recipes/SearchFrame';
import { TargetLineStatusBadge } from 'components/recipes/TargetLineStatusBadge';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { truthy } from 'utils/boolean';
import { formatNumber } from 'utils/formatting';
import { routes } from 'utils/routes';
import { PersistedColumn, ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';
import { asKeyOf, sortableNumberColumn, sortDirections } from 'utils/tables';
import { isTenantErpTypeP21 } from 'utils/tenants';

import { PurchaseStatusSidePane } from './PurchaseStatusSidePane';

export const PurchaseStatusPage = () => {
  const { activeTenant } = useGlobalApp();

  const columns: (PersistedColumn<SearchInventoryStatusDTO> | null)[] = [
    {
      title: 'Item',
      settingKey: 'itemId',
      required: true,
      dataIndex: asKeyOf<SearchInventoryStatusDTO>('itemId'),
      fixed: 'left',
      sorter: true,
      sortDirections,
      render: (_: string, record: SearchInventoryStatusDTO) => (
        <div>
          <Link to={routes.purchasing.itemDetails(record.itemId)}>{record.itemId}</Link>
          <div>{record.itemName}</div>
        </div>
      ),
      exportValue: {
        'Item ID': asKeyOf<SearchInventoryStatusDTO>('itemId'),
        'Item Name': asKeyOf<SearchInventoryStatusDTO>('itemName'),
      },
    },
    {
      title: 'Location',
      settingKey: 'location',
      required: true,
      dataIndex: asKeyOf<SearchInventoryStatusDTO>('location'),
      fixed: 'left',
    },
    isTenantErpTypeP21(activeTenant.erpType)
      ? {
          title: 'ABC Class',
          settingKey: 'abcClass',
          dataIndex: asKeyOf<SearchInventoryStatusDTO>('purchaseClass'),
          sorter: true,
          sortDirections,
        }
      : null,
    {
      title: 'Stockable',
      settingKey: 'stockable',
      dataIndex: asKeyOf<SearchInventoryStatusDTO>('stockable'),
      align: 'center' as const,
      render: (value) => (value === 'Y' ? <CheckOutlined /> : null),
      optional: true,
    },
    {
      title: 'Status',
      settingKey: 'status',
      dataIndex: asKeyOf<SearchInventoryStatusDTO>('status'),
      render: (status: SearchInventoryStatusDTO['status']) => <TargetLineStatusBadge status={status} />,
    },
    {
      title: 'Primary Supplier',
      settingKey: 'supplier',
      dataIndex: asKeyOf<SearchInventoryStatusDTO>('supplier'),
      // Todo: temporary remove the link to vendor page, and the eventual fix is to link to supplier page
    },
    sortableNumberColumn({
      title: <InfoTooltip title="Recommended quantity to order">Qty to Order</InfoTooltip>,
      settingKey: 'qtyToOrder',
      dataIndex: asKeyOf<SearchInventoryStatusDTO>('qtyToOrder'),
      sorter: true,
      render: (qtyToOrder, record: SearchInventoryStatusDTO) => (
        <div
          className={css`
            display: inline-flex;
          `}
        >
          {formatNumber(qtyToOrder)}
          <QtyToOrderPopover record={record} title="Quantity to Order Calculation" resultProperty="Qty To Order" />
        </div>
      ),
    }),
    sortableNumberColumn({
      title: 'Net Stock',
      settingKey: 'netStock',
      dataIndex: asKeyOf<SearchInventoryStatusDTO>('netStock'),
      sorter: true,
      render: (netStock, record: SearchInventoryStatusDTO) => (
        <>
          {formatNumber(netStock)}
          <NetStockPopover record={record} />
        </>
      ),
    }),
    sortableNumberColumn({
      title: 'Qty Available',
      settingKey: 'qtyAvailable',
      dataIndex: asKeyOf<SearchInventoryStatusDTO>('qtyAvailable'),
      sorter: true,
    }),
    sortableNumberColumn({
      title: 'Days of Supply',
      settingKey: 'daysOfSupply',
      dataIndex: asKeyOf<SearchInventoryStatusDTO>('daysOfSupply'),
      sorter: true,
    }),
    {
      title: 'UOM',
      settingKey: 'unitOfMeasure',
      dataIndex: asKeyOf<SearchInventoryStatusDTO>('unitOfMeasure'),
    },
    // optional columns
    {
      title: 'Product Group',
      settingKey: 'itemGroup',
      dataIndex: asKeyOf<SearchInventoryStatusDTO>('itemGroup'),
      optional: true,
    },
    {
      title: 'Minimum',
      settingKey: 'invMin',
      dataIndex: asKeyOf<SearchInventoryStatusDTO>('invMin'),
      optional: true,
    },
    {
      title: 'Maximum',
      settingKey: 'invMax',
      dataIndex: asKeyOf<SearchInventoryStatusDTO>('invMax'),
      optional: true,
    },
    {
      title: 'Qty Backordered',
      settingKey: 'qtyBackordered',
      dataIndex: asKeyOf<SearchInventoryStatusDTO>('qtyBackordered'),
      optional: true,
    },
    {
      title: 'Qty in Transit',
      settingKey: 'qtyInTransit',
      dataIndex: asKeyOf<SearchInventoryStatusDTO>('qtyInTransit'),
      optional: true,
    },
    {
      title: 'Qty on PO',
      settingKey: 'qtyOnPurchaseOrder',
      dataIndex: asKeyOf<SearchInventoryStatusDTO>('qtyOnPurchaseOrder'),
      optional: true,
    },
    {
      title: 'Item Name',
      settingKey: 'itemName',
      dataIndex: asKeyOf<SearchInventoryStatusDTO>('itemName'),
      sorter: true,
      sortDirections,
      optional: true,
    },
  ];

  const valueFacets: (ValueFacet<SearchInventoryStatusDTO> | null)[] = [
    {
      title: 'Status',
      field: asKeyOf<SearchInventoryStatusDTO>('status'),
      icon: <DashboardOutlined />,
    },
    {
      title: 'Location',
      field: asKeyOf<SearchInventoryStatusDTO>('location'),
      queryPlaceholder: 'Search locations',
      icon: <EnvironmentOutlined />,
    },
    isTenantErpTypeP21(activeTenant.erpType)
      ? {
          title: 'ABC Class',
          field: asKeyOf<SearchInventoryStatusDTO>('purchaseClass'),
          sortBy: 'alpha',
          icon: <TagOutlined />,
        }
      : null,
    {
      title: 'Supplier',
      field: asKeyOf<SearchInventoryStatusDTO>('supplier'),
      queryPlaceholder: 'Search primary suppliers',
      icon: <ShopOutlined />,
    },
    {
      title: 'Product Group',
      field: asKeyOf<SearchInventoryStatusDTO>('itemGroup'),
      queryPlaceholder: 'Search product groups',
      icon: <AppstoreOutlined />,
    },
  ];

  return (
    <SearchFrame<SearchInventoryStatusDTO>
      title={
        <InfoTooltip title="View all stockable and backordered non-stock items by their purchase status across all locations">
          Purchase Status
        </InfoTooltip>
      }
      indexName={SearchIndexName.InventoryStatus}
      queryPlaceholder="Search by item"
      tableColumnsSettingKey={ViewSettingKey.PurchaseStatusTable}
      columns={columns.filter(truthy)}
      valueFacets={valueFacets.filter(truthy)}
      sidePane={(props) => <PurchaseStatusSidePane {...props} />}
      defaultSortBy={{ field: asKeyOf<SearchInventoryStatusDTO>('qtyToOrder'), order: 'desc' }}
      headerActions={(searchFrameContext) => (
        <>
          <DownloadButton
            getDownloadData={searchFrameContext.getDownloadData}
            recordType="InventoryStatus"
            disabled={!searchFrameContext.canDownload}
          />
        </>
      )}
    />
  );
};
