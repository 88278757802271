import React from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { SortDirection } from '@recurrency/core-api-schema/dist/common/enums';
import {
  GetSalesHistoryReportSortByField,
  GetSalesInvoiceLinesReportGroupBy,
  SalesInvoiceLinesReportItemDTO,
  SalesInvoiceLinesReportListDTO,
} from '@recurrency/core-api-schema/dist/reports/getSalesInvoiceLinesReport';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';
import { mediaQueries } from 'theme/breakpoints';

import { Card, CardHeader, CardSection } from 'components/Card';
import { PerformanceChart } from 'components/Charts';
import { monthNames, PerfChartPoint } from 'components/Charts/PerformanceChart';
import { CenteredError, SmallLoader } from 'components/Loaders';
import { ChangeDeltaWithArrow } from 'components/recipes/ChangeDeltaWithArrow';
import { LastUpdatedWithFrequency, LastUpdatedWithFrequencyEntity } from 'components/recipes/LastUpdatedMoment';
import { Table } from 'components/Table';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { getLastTwoYears } from 'utils/date';
import { formatPercent, formatUSD } from 'utils/formatting';
import { shouldHideCostAndGm } from 'utils/roleAndTenant';

export const Performance = () => {
  const { activeTenant, activeUser } = useGlobalApp();
  const shouldShowCostAndGM = !shouldHideCostAndGm(activeTenant, activeUser);

  const {
    data: YTDData,
    error: YTDError,
    isLoading: YTDIsLoading,
  } = useCoreApi(schemas.reports.getSalesInvoiceLinesReport, {
    queryParams: {
      groupBy: GetSalesInvoiceLinesReportGroupBy.Month,
      sortBy: GetSalesHistoryReportSortByField.ForeignId,
      sortDir: SortDirection.Asc,
      yearToDate: true,
    },
  });
  const {
    data: FYData,
    error: FYError,
    isLoading: FYIsLoading,
  } = useCoreApi(schemas.reports.getSalesInvoiceLinesReport, {
    queryParams: {
      groupBy: GetSalesInvoiceLinesReportGroupBy.Month,
      sortBy: GetSalesHistoryReportSortByField.ForeignId,
      sortDir: SortDirection.Asc,
      yearToDate: false,
    },
  });

  if (YTDError || FYError) {
    return <CenteredError error={YTDError ?? FYError} />;
  }

  const lastTwoYears = getLastTwoYears();
  const [lastYear, curYear] = lastTwoYears;
  const tableData = YTDData ? getPerformanceTableData(YTDData) : [];
  const salesChartData = FYData ? getPerformanceChartData(FYData, 'sales') : [];
  const gmChartData = FYData ? getPerformanceChartData(FYData, 'grossMargin') : [];
  // reverse YTD, LYTD so it aligns legend with table
  const perfChartYears = getLastTwoYears().reverse();

  const salesColumns: ColumnType<SalesInvoiceLinesReportItemDTO>[] = [
    {
      title: 'Month',
      dataIndex: 'name',
    },
    {
      title: `Sales ${curYear}`,
      dataIndex: 'salesYtd',
      render: (amt: number) => formatUSD(amt),
      align: 'right' as const,
    },
    {
      title: `Sales ${lastYear}`,
      dataIndex: 'salesLYtd',
      render: (amt: number) => formatUSD(amt),
      align: 'right' as const,
    },
    {
      title: `Sales ∆`,
      dataIndex: 'salesDelta',
      render: (value: number) => <ChangeDeltaWithArrow value={value} valueFormatter={formatUSD} />,
      align: 'right' as const,
    },
    {
      title: `Sales ∆%`,
      dataIndex: 'salesDeltaPct',
      render: (value: number) => <ChangeDeltaWithArrow value={value} valueFormatter={(val) => formatPercent(val, 0)} />,
      align: 'right' as const,
    },
  ];

  const gmColumns: ColumnType<SalesInvoiceLinesReportItemDTO>[] = [
    {
      title: 'Month',
      dataIndex: 'name',
    },
    {
      title: `GM ${curYear}`,
      dataIndex: 'gmYtd',
      render: (amt: number) => formatUSD(amt),
      align: 'right' as const,
    },
    {
      title: `GM ${lastYear}`,
      dataIndex: 'gmLYtd',
      render: (amt: number) => formatUSD(amt),
      align: 'right' as const,
    },
    {
      title: `GM ∆`,
      dataIndex: 'gmDelta',
      render: (value: number) => <ChangeDeltaWithArrow value={value} valueFormatter={formatUSD} />,
      align: 'right' as const,
    },
    {
      title: `GM ∆%`,
      dataIndex: 'gmDeltaPct',
      render: (value: number) => <ChangeDeltaWithArrow value={value} valueFormatter={(val) => formatPercent(val, 0)} />,
      align: 'right' as const,
    },
  ];

  return (YTDIsLoading && FYIsLoading) || !YTDData || !FYData ? (
    <div
      className={css`
        margin: 32px 0;
      `}
    >
      <SmallLoader />
    </div>
  ) : (
    <>
      <LastUpdatedWithFrequency
        dateString={YTDData?.lastUpdatedAt}
        lastUpdateEntity={LastUpdatedWithFrequencyEntity.SalesReport}
      />
      <div
        className={css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 32px;

          ${mediaQueries.xl} {
            flex-direction: row;
            .perf-column {
              width: 40%;
            }
          }

          .perf-table-totals-row {
            font-weight: 600;
          }

          .perf-column {
            display: flex;
            flex-direction: column;
            gap: 32px;
            flex: 1;
          }
        `}
      >
        <div className="perf-column">
          <Card>
            <CardHeader
              title="Sales"
              useTooltip
              description={
                <div>
                  Invoiced line item totals across the entire company/your territory.
                  <br />
                  <br />
                  The chart displays sales history over time for this year and last year.
                  <br />
                  <br />
                  The table below compares year-to-date sales to last year’s sales up to this point in the year.
                </div>
              }
            />
            <CardSection>
              <PerformanceChart data={salesChartData} years={perfChartYears} />
            </CardSection>
            <Table
              columns={salesColumns}
              data={tableData}
              pagination={false}
              rowKey="foreignId"
              rowClassName={(record: SalesInvoiceLinesReportItemDTO) =>
                record.foreignId === 'totals' ? `perf-table-totals-row` : ``
              }
            />
          </Card>
        </div>
        {shouldShowCostAndGM && (
          <div className="perf-column">
            <Card>
              <CardHeader
                title="Gross Margin"
                useTooltip
                description={
                  <div>
                    Gross margin of invoiced sales across the entire company/your territory.
                    <br />
                    <br />
                    The chart displays gross margin history over time for this year and last year.
                    <br />
                    <br />
                    The table below compares year-to-date gross margin to last year’s gross margin up to this point in
                    the year.
                  </div>
                }
              />
              <CardSection>
                <PerformanceChart data={gmChartData} years={perfChartYears} />
              </CardSection>
              <Table
                columns={gmColumns}
                data={tableData}
                pagination={false}
                rowKey="foreignId"
                rowClassName={(record: SalesInvoiceLinesReportItemDTO) =>
                  record.foreignId === 'totals' ? `perf-table-totals-row` : ``
                }
              />
            </Card>
          </div>
        )}
      </div>
    </>
  );
};

function getPerformanceChartData(
  data: SalesInvoiceLinesReportListDTO,
  type: 'grossMargin' | 'sales',
): PerfChartPoint[] {
  const lastTwoYears = getLastTwoYears();
  const chartPoints: PerfChartPoint[] = [];
  const currentMonth = moment().month();

  const statsByMonth: { [monthName: string]: SalesInvoiceLinesReportItemDTO } = {};
  for (const item of data.items) {
    statsByMonth[item.name] = item;
  }

  // create a data point from Jan to Dec
  for (let monthNum = 0; monthNum < 12; ++monthNum) {
    const monthName = monthNames[monthNum];
    const chartPoint: PerfChartPoint = { monthName };
    if (statsByMonth[monthName]) {
      const stats = statsByMonth[monthName];
      const YTDValue = type === 'grossMargin' ? stats.gmYtd : stats.salesYtd;
      chartPoint[lastTwoYears[0]] = type === 'grossMargin' ? stats.gmLYtd : stats.salesLYtd;
      chartPoint[lastTwoYears[1]] = monthNum > currentMonth ? null : YTDValue;
    } else {
      chartPoint[lastTwoYears[0]] = 0;
      chartPoint[lastTwoYears[1]] = 0;
    }
    chartPoints.push(chartPoint);
  }

  return chartPoints;
}

function getPerformanceTableData(data: SalesInvoiceLinesReportListDTO): SalesInvoiceLinesReportItemDTO[] {
  // +1 because getUTCMonth() is 0-indexed while snowflake month() is 1-indexed
  const curMonth = new Date().getMonth() + 1;
  const items = data.items.filter((item) => parseInt(item.foreignId, 10) <= curMonth);

  // calculate total item for summary
  const totalSalesYtd = items.reduce((sum, item) => sum + item.salesYtd, 0);
  const totalSalesLYtd = items.reduce((sum, item) => sum + item.salesLYtd, 0);
  const totalSalesDelta = totalSalesYtd - totalSalesLYtd;
  const totalSalesDeltaPct = totalSalesLYtd ? totalSalesDelta / totalSalesLYtd : 0;
  const totalGMYtd = items.reduce((sum, item) => sum + item.gmYtd, 0);
  const totalGMLYtd = items.reduce((sum, item) => sum + item.gmLYtd, 0);
  const totalGMDelta = totalGMYtd - totalGMLYtd;
  const totalGMDeltaPct = totalGMLYtd ? totalGMDelta / totalGMLYtd : 0;

  const totalItem = {
    foreignId: 'totals',
    name: 'Totals',
    salesYtd: totalSalesYtd,
    salesLYtd: totalSalesLYtd,
    salesDelta: totalSalesDelta,
    salesDeltaPct: totalSalesDeltaPct,
    gmYtd: totalGMYtd,
    gmLYtd: totalGMLYtd,
    gmDelta: totalGMDelta,
    gmDeltaPct: totalGMDeltaPct,
  } as SalesInvoiceLinesReportItemDTO;

  return [...items, totalItem];
}
