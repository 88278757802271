import { IdNameObj } from 'types/legacy-api';

// Deprecated: do not expand on this system. For adding new config, use tenant settings.

export interface PurchaseOrderEntryFlowFormConfig {
  email: {
    defaultValue?: boolean;
  };
  // If poCostDiscount.active=true, user can change unitPrice in PO form. However under the hood the originalUnitPrice is still sent to core-api,
  // Additionally, the discountPercent field is auto-calculated and sent, which SAPB1 will apply internally to finalize the lineCost.
  poCostDiscount: {
    active?: boolean;
  };
}

export interface TenantConfig {
  quoteOrderEntryFlow: {
    formConfig: {
      approved: {
        defaultValue: boolean;
      };
      deliveryInstructions: {
        required: boolean;
      };
      emailAddress: {
        required: boolean;
      };
      customerPONumber: {
        required: boolean;
      };
      sourceLocation: {
        required: boolean;
      };
      carrier: {
        required: boolean;
      };
      class1: {
        required: boolean;
        // the input of class1 in Q/O entry can be either asyncSelect or checkbox
        fieldType: 'asyncSelect' | 'checkbox';
      };
      isQuoteForOrder: {
        visible: boolean;
      };
      customerJobNo: {
        visible: boolean;
      };
      shippingRouteId: {
        required: boolean;
      };
      quoteValidUntilDays: {
        defaultValue: number;
      };
      /**
       * ocrCode(N) refers to customer-based codes in SAPB1
       * defaultValueFieldFromAlgoliaCustomer indicates the field in AlgoliaCustomer
       * that should be used as the default value for this code
       */
      ocrCode?: {
        enableExport: boolean;
        defaultValueFieldFromAlgoliaCustomer: string;
      };
      ocrCode2?: {
        enableExport: boolean;
        defaultValueFieldFromAlgoliaCustomer: string;
      };
      terms: {
        enableExport: boolean;
      };
      contact: {
        required: boolean;
      };
    };
    lineItemConfig: {
      otherCost: {
        visible: boolean;
        displayName: string;
      };
      otherCostBasedGM: {
        visible: boolean;
        displayName: string;
      };
    };
  };
  itemEntryFlow: {
    formConfig: {
      class2: {
        defaultValue?: IdNameObj;
      };
      supplierPartNo: {
        required: boolean;
      };
      itemGroup: {
        defaultValue?: IdNameObj;
      };
      itemDescription: {
        validate: boolean;
      };
      supplier: {
        // default supplier value is company-based
        defaultValueForAssemblyByCompanyId?: { [companyId: string]: IdNameObj };
      };
      // Represents location settings for new items in Q/O entry
      locationForQuoteEntry: {
        selectMode: 'all' | 'list'; // Specifies whether to select all locations or from a list
        excludeName?: string; // Optional location name to be excluded
        listOptions?: {
          defaultLocation: boolean; // Indicates whether the default location should be included
          quoteEntryLocation: boolean; // Indicates whether the Q/O entry location should be included
        };
      };
      // Represents location settings for new items on Inventory page
      locationForInventory: {
        selectMode: 'all' | 'list';
        excludeName?: string;
        listOptions?: {
          defaultLocation: boolean;
          quoteEntryLocation: boolean;
        };
      };
    };
  };
  purchaseOrderEntryFlow: {
    formConfig: PurchaseOrderEntryFlowFormConfig;
  };
  itemsListPage: {
    /** Used to hide the product group filter in the item list page
     * This is used in cases where tenants have multiple companies. For tenants with
     * multiple companies, the product group can differ between companies and in our
     * item list page, we only display/filter/count by the default item product group.
     */
    hideProductGroupFilter: boolean;
  };
}

export const defaultConfig: TenantConfig = {
  quoteOrderEntryFlow: {
    formConfig: {
      approved: {
        defaultValue: true,
      },
      deliveryInstructions: {
        required: false,
      },
      emailAddress: {
        required: false,
      },
      customerPONumber: {
        required: false,
      },
      sourceLocation: {
        required: false,
      },
      carrier: {
        required: false,
      },
      class1: {
        required: false,
        fieldType: 'asyncSelect',
      },
      isQuoteForOrder: {
        visible: false,
      },
      customerJobNo: {
        visible: false,
      },
      shippingRouteId: {
        required: false,
      },
      terms: {
        enableExport: false,
      },
      contact: {
        required: false,
      },
      quoteValidUntilDays: {
        defaultValue: 7,
      },
    },
    lineItemConfig: {
      otherCost: {
        visible: false,
        displayName: 'Other Cost',
      },
      otherCostBasedGM: {
        visible: false,
        displayName: 'GM Based on Other Cost', // place holder
      },
    },
  },
  itemEntryFlow: {
    formConfig: {
      class2: {
        defaultValue: undefined,
      },
      supplierPartNo: {
        required: false,
      },
      itemGroup: {
        defaultValue: undefined,
      },
      itemDescription: {
        validate: false,
      },
      supplier: {
        defaultValueForAssemblyByCompanyId: undefined,
      },
      locationForQuoteEntry: {
        selectMode: 'all',
      },
      locationForInventory: {
        selectMode: 'all',
      },
    },
  },
  purchaseOrderEntryFlow: {
    formConfig: {
      email: {
        defaultValue: false,
      },
      poCostDiscount: {
        active: false,
      },
    },
  },
  itemsListPage: {
    hideProductGroupFilter: false,
  },
};
