import React from 'react';

import { css } from '@emotion/css';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';

import { MiniTable } from 'components/MiniTable';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { formatPercent, formatUSD } from 'utils/formatting';
import { shouldHideCostAndGm, shouldShowFeatureFlag } from 'utils/roleAndTenant';

import {
  getAverageGrossMarginPct,
  getNumLineItems,
  getTotalGrossMargin,
  getTotalNetWeight,
  getTotalPrice,
  IVA_TAX_RATE,
  QuoteLineItemP21WithInfo,
} from '../../quoteUtils';

export type SummaryLine = { name: string; value: number };

export function QuoteLineItemSummaryStats({
  lineItemsWithInfo,
  additionalLines,
}: {
  lineItemsWithInfo: QuoteLineItemP21WithInfo[];
  additionalLines: SummaryLine[];
}) {
  const { activeTenant, activeUser } = useGlobalApp();

  // eslint-disable-next-line no-return-assign
  const additionalLinesTotalPrice = additionalLines.reduce((total, item) => (total += item.value), 0);
  const summaryStats = [
    { name: 'Total Lines', value: getNumLineItems(lineItemsWithInfo) },
    ...additionalLines.map(({ name, value }) => ({ name, value: formatUSD(value, true) })),
    { name: 'Total Price', value: formatUSD(getTotalPrice(lineItemsWithInfo) + additionalLinesTotalPrice, true) },
  ];

  if (!shouldHideCostAndGm(activeTenant, activeUser)) {
    summaryStats.splice(1, 0, {
      name: 'Gross Margin',
      value: formatPercent(getAverageGrossMarginPct(lineItemsWithInfo, activeTenant) / 100),
    });
    summaryStats.splice(2, 0, {
      name: 'Gross Profit',
      value: formatUSD(getTotalGrossMargin(lineItemsWithInfo, activeTenant), true),
    });
  }

  if (shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SalesShowNetWeight)) {
    summaryStats.splice(1, 0, {
      name: 'Total Net Weight',
      value: `${getTotalNetWeight(lineItemsWithInfo)} lbs.`,
    });
  }
  if (shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SalesShowIvaTax)) {
    summaryStats.push({
      name: 'Total Price with IVA',
      value: formatUSD(getTotalPrice(lineItemsWithInfo) * IVA_TAX_RATE + additionalLinesTotalPrice, true),
    });
  }

  return (
    <MiniTable
      numRowsShown={summaryStats.length}
      data={summaryStats}
      columns={[
        {
          render: (record) => `${record.name}:`,
          align: 'right',
        },
        {
          render: (record) => (
            <strong
              className={css`
                margin-left: 16px;
              `}
            >
              {record.value}
            </strong>
          ),
          align: 'right',
          maxWidth: '200px',
        },
      ]}
    />
  );
}
