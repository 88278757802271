import React, { useEffect, useRef, useState } from 'react';

import { css } from '@emotion/css';
import { IntegratedErps } from '@recurrency/core-api-schema/dist/common/enums';
import { notification, Progress } from 'antd';

import { captureAndShowError } from 'utils/error';

import { getErpName } from './formatting';

let notificationCounter = 0; // to ensure uniqueness
const DEFAULT_EXPECTED_WAIT_SECONDS = 300;

export function createSubmissionNotification({
  entityName,
  erpType,
  expectedWaitSeconds,
  submittingMessage,
  fireInProgressNotification = true,
}: {
  entityName: string;
  erpType?: IntegratedErps;
  // Expected time to complete submission in seconds
  expectedWaitSeconds?: number;
  submittingMessage?: string;
  fireInProgressNotification?: boolean;
}) {
  const notificationKey = `submission-${notificationCounter++}`;
  submittingMessage = submittingMessage || `Submitting ${entityName} ${erpType ? `to ${getErpName(erpType)}` : ''}`;

  const inProgress = (
    options: {
      description?: (notificationKey: string, entityName: string) => React.ReactNode;
    } = {},
  ) => {
    notification.info({
      message: submittingMessage,
      key: notificationKey,
      description: (
        <div
          className={css`
            margin-top: 8px;
          `}
        >
          <ExpectedWaitProgress expectedWaitSeconds={expectedWaitSeconds ?? DEFAULT_EXPECTED_WAIT_SECONDS} showInfo />
          {options.description ? (
            <>
              <br />
              {options.description(notificationKey, entityName)}
            </>
          ) : null}
        </div>
      ),
      duration: 0,
    });
  };

  if (fireInProgressNotification) inProgress();

  const success = (
    options: {
      successMessage?: string;
      description?: (notificationKey: string, entityName: string) => React.ReactNode;
      duration?: number;
    } = {},
  ) => {
    notification.info({
      message: submittingMessage,
      key: notificationKey,
      description: (
        <div
          className={css`
            margin-top: 8px;
          `}
        >
          Almost done...
          <ExpectedWaitProgress initialProgress={100} expectedWaitSeconds={0} />
        </div>
      ),
      duration: 0,
    });
    setTimeout(() => {
      notification.success({
        key: notificationKey,
        message: options.successMessage || `${entityName} submitted`,
        description: options.description?.(notificationKey, entityName),
        duration: options.duration || 0,
      });
    }, 1000);
  };

  const error = (
    err: unknown,
    options: {
      description?: (notificationKey: string, entityName: string) => React.ReactNode;
    } = {},
  ) => {
    captureAndShowError(err, `Unable to submit ${entityName}`, {
      notificationKey,
      renderDescription: (errorDescription) => (
        <>
          {errorDescription}
          {options.description ? (
            <>
              <br />
              {options.description(notificationKey, entityName)}
            </>
          ) : null}
        </>
      ),
    });
  };

  return { success, error, inProgress, notificationKey } as const;
}

export interface ExpectedWaitProgressProps {
  expectedWaitSeconds: number;
  initialProgress?: number;
  showInfo?: boolean;
}

export function ExpectedWaitProgress({
  expectedWaitSeconds,
  showInfo,
  initialProgress = 0,
}: ExpectedWaitProgressProps) {
  const percentagePoint = 100 / (expectedWaitSeconds * 10);
  const [currentProgress, setCurrentProgress] = useState(initialProgress);
  const stepRef = useRef(1 * percentagePoint);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (currentProgress > 95) {
        stepRef.current = 0;
      } else if (currentProgress > 90) {
        stepRef.current = 0.2 * percentagePoint;
      } else if (currentProgress > 80) {
        stepRef.current = 0.5 * percentagePoint;
      } else if (currentProgress > 50) {
        stepRef.current = 0.75 * percentagePoint;
      }
      setCurrentProgress(currentProgress + stepRef.current);
    }, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [currentProgress, percentagePoint]);

  return (
    <>
      {showInfo &&
        `Estimate: ${estimatedSecondsToHumanReadable(
          expectedWaitSeconds / (100 / (100 - currentProgress)),
        )} remaining...`}
      <Progress percent={currentProgress} showInfo={false} />
    </>
  );
}

export function estimatedSecondsToHumanReadable(seconds: number): string {
  if (seconds <= 10) {
    return `10 seconds`;
  }
  if (seconds <= 15) {
    return `15 seconds`;
  }
  if (seconds <= 30) {
    return `30 seconds`;
  }
  if (seconds <= 60) {
    return `1 minute`;
  }
  return `${Math.ceil(seconds / 60)} minutes`;
}
