import React from 'react';

import { Badge, BadgeProps, BadgeVariant } from '../Badge';

export enum BadgeStatus {
  Incomplete,
  Completed,
  Open,
  Canceled,
  Prospect,
  UserAuth0,
  UserSSO,
  UserImported,
  Draft,
  Expired,
  Submitted,
  Pinned,
  Review = 'Review',
  Excluded = 'Excluded',
  Updated = 'Updated',
  Understock = 'Understock',
  Overstock = 'Overstock',
  Backordered = 'Backordered',
  Normal = 'Normal',
  Ready = 'Ready',
  NotReady = 'NotReady',
  Deadstock = 'Deadstock',
  Unknown = 'Unknown',
  Nonstock = 'Nonstock',
  Closed = 'Closed',
  Shipped = 'shipped',
  Flagged = 'Flagged',
}

export const colorByStatus: Record<BadgeStatus, BadgeVariant> = {
  [BadgeStatus.Incomplete]: 'warning',
  [BadgeStatus.Completed]: 'success',
  [BadgeStatus.Open]: 'info',
  [BadgeStatus.Canceled]: 'danger',
  [BadgeStatus.Prospect]: 'neutral',
  [BadgeStatus.UserAuth0]: 'success',
  [BadgeStatus.UserSSO]: 'info',
  [BadgeStatus.UserImported]: 'neutral',
  [BadgeStatus.Draft]: 'neutral',
  [BadgeStatus.Expired]: 'warning',
  [BadgeStatus.Submitted]: 'warning',
  [BadgeStatus.Pinned]: 'info',
  [BadgeStatus.Review]: 'danger',
  [BadgeStatus.Excluded]: 'neutral',
  [BadgeStatus.Flagged]: 'warning',
  [BadgeStatus.Updated]: 'info',
  [BadgeStatus.Understock]: 'danger',
  [BadgeStatus.Deadstock]: 'danger',
  [BadgeStatus.Overstock]: 'warning',
  [BadgeStatus.Backordered]: 'warning',
  [BadgeStatus.Normal]: 'info',
  [BadgeStatus.Ready]: 'success',
  [BadgeStatus.NotReady]: 'neutral',
  [BadgeStatus.Unknown]: 'neutral',
  [BadgeStatus.Nonstock]: 'neutral',
  [BadgeStatus.Closed]: 'success',
  [BadgeStatus.Shipped]: 'warning',
};

export const labelByStatus: Record<BadgeStatus, string> = {
  [BadgeStatus.Incomplete]: 'Incomplete',
  [BadgeStatus.Completed]: 'Completed',
  [BadgeStatus.Open]: 'Open',
  [BadgeStatus.Canceled]: 'Canceled',
  [BadgeStatus.Prospect]: 'Prospect',
  [BadgeStatus.UserAuth0]: 'Auth0',
  [BadgeStatus.UserSSO]: 'SSO',
  [BadgeStatus.UserImported]: 'Imported',
  [BadgeStatus.Draft]: 'Draft',
  [BadgeStatus.Expired]: 'Expired',
  [BadgeStatus.Submitted]: 'Submitted',
  [BadgeStatus.Pinned]: 'Pinned',
  [BadgeStatus.Review]: 'Needs Review',
  [BadgeStatus.Excluded]: 'Excluded',
  [BadgeStatus.Flagged]: 'Flagged',
  [BadgeStatus.Updated]: 'OK',
  [BadgeStatus.Understock]: 'Understock',
  [BadgeStatus.Overstock]: 'Overstock',
  [BadgeStatus.Backordered]: 'Backordered',
  [BadgeStatus.Deadstock]: 'Deadstock',
  [BadgeStatus.Normal]: 'Normal',
  [BadgeStatus.Ready]: 'Ready',
  [BadgeStatus.NotReady]: 'Not Ready',
  [BadgeStatus.Unknown]: 'Unknown',
  [BadgeStatus.Nonstock]: 'Nonstock',
  [BadgeStatus.Closed]: 'Closed',
  [BadgeStatus.Shipped]: 'Shipped',
};

export type Props = BadgeProps & {
  status: BadgeStatus;
  labelText?: React.ReactNode;
};

export const StatusBadge = ({ labelText, ...props }: Props): JSX.Element => {
  const color = colorByStatus[props.status] ?? colorByStatus[BadgeStatus.Unknown];
  const label = labelText ?? labelByStatus[props.status] ?? colorByStatus[BadgeStatus.Unknown];
  return <Badge variant={color} label={label} {...props} />;
};
