import React, { ReactElement } from 'react';

import { Button } from 'components/Button';
import { DividerLine } from 'components/DividerLine';
import { Modal } from 'components/Modal';
import { ItemLocationSettingsAuditLog } from 'components/recipes/AuditLog/ItemLocationSettingsAuditLog';
import { Tabs } from 'components/Tabs';

import { splitIdNameStr } from 'utils/formatting';

import { MultiUpdateReplenishmentSettingsTable } from './MultiUpdateReplenishmentSettingsTable';
import {
  MultiModalSelection,
  PlanningBulkUpdate,
  PlanningBulkUpdateToFields,
  PlanningBulkUpdateToVerbiage,
} from './utils';

export function ItemLocationSettingChangeModal({
  selection,
  set,
  bulkUpdateType,
  description,
  customization,
  validator,
  onClose,
  onSubmit,
}: {
  selection: MultiModalSelection;
  // Set is whether we are setting the field or unsetting it, example: If you are marking the field as excluded, set would be true
  set: boolean;
  bulkUpdateType: PlanningBulkUpdate;
  description: string;
  customization: ReactElement;
  validator?: () => boolean;
  onClose: () => void;
  onSubmit: () => void;
}) {
  const options = PlanningBulkUpdateToVerbiage[bulkUpdateType.type];
  const isSingleRecord = selection.type === 'list' && selection.items.length === 1;
  const title = `${set ? options.set : options.unset} ${
    isSingleRecord
      ? `Item #${selection.items[0].item_id} at Location ${selection.items[0].location}`
      : `${selection.count} Items`
  }`;

  return (
    <Modal
      visible
      title={title}
      onCancel={() => onClose()}
      width={900}
      destroyOnClose
      footer={
        <>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button
            onClick={() => {
              // If a validator is provided, run it and only close if it returns true
              if (validator) {
                if (validator()) onSubmit();
              } else {
                onSubmit();
              }
            }}
            type="primary"
          >
            {set ? options.set : options.unset}
          </Button>
        </>
      }
    >
      <>
        <span>{description}</span>
        {customization}
        <DividerLine />
        <MultiUpdateReplenishmentSettingsTable selection={selection} bulkUpdate={bulkUpdateType} />
        {isSingleRecord && (
          <>
            <DividerLine marginTop={42} />
            <Tabs
              tabs={[
                {
                  header: 'Audit Log',
                  infoTooltip: `${options.set} updates for Item #${selection.items[0].item_id} at Location #${selection.items[0].location}`,
                  content: (
                    <ItemLocationSettingsAuditLog
                      itemUid={selection.items[0].item_uid}
                      locationId={splitIdNameStr(selection.items[0].location).foreignId}
                      fields={PlanningBulkUpdateToFields[bulkUpdateType.type]}
                    />
                  ),
                },
              ]}
            />
          </>
        )}
      </>
    </Modal>
  );
}
