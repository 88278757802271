import React from 'react';

import { formatNumber } from 'utils/formatting';

import { calcLine, calcOp, calcProp, Equation, noteLine } from './Equation';
import { EquationPopover } from './EquationPopover';

export interface RequiredQtyRecord {
  requiredQty: number;
  destinationMax: number;
  destinationMin: number;
  destinationNetStock: number;
  destinationBackOrderQty: number;
  replenishmentMethod: string;
  stockable: boolean;
  unitOfMeasure: string;
}

export function RequiredQtyPopover({ record }: { record: RequiredQtyRecord }) {
  const showOpOq = record.replenishmentMethod === 'OP/OQ';
  const isNonStock = !record.stockable;
  const { destinationMin: orderPoint, destinationMax: orderQuantity } = record;
  const orderCount = Math.ceil((orderPoint - record.destinationNetStock) / orderQuantity);
  return (
    <EquationPopover title="Required Quantity Calculation">
      <Equation
        lines={
          isNonStock && record.destinationNetStock >= 0
            ? [
                calcLine(
                  calcProp('Net Stock Quantity', `${formatNumber(record.destinationNetStock)} ${record.unitOfMeasure}`),
                  calcOp('>='),
                  calcProp('Minimum Quantity', `0 ${record.unitOfMeasure}`),
                ),
                noteLine(
                  'No inventory needs to be transferred as the current net stock is greater than or equal to 0.',
                ),
              ]
            : [
                calcLine(
                  calcProp('Required Quantity', `${formatNumber(record.requiredQty)} ${record.unitOfMeasure}`),
                  calcOp('='),
                  ...(isNonStock && record.destinationNetStock < 0
                    ? [
                        calcOp('-'),
                        calcProp(
                          'Net Stock Quantity',
                          `${formatNumber(record.destinationNetStock)} ${record.unitOfMeasure}`,
                        ),
                      ]
                    : showOpOq
                    ? [
                        calcProp('Number of Orders', `${formatNumber(orderCount)} ${record.unitOfMeasure}`),
                        calcOp('x'),
                        calcProp('Order Quantity', `${formatNumber(orderQuantity)} ${record.unitOfMeasure}`),
                      ]
                    : [
                        calcProp('Destination Max', `${formatNumber(record.destinationMax)} ${record.unitOfMeasure}`),
                        calcOp('-'),
                        calcProp(
                          'Destination Net Stock',
                          `${formatNumber(record.destinationNetStock)} ${record.unitOfMeasure}`,
                        ),
                      ]),
                ),
              ]
        }
      />
    </EquationPopover>
  );
}
