import React from 'react';

import { css } from '@emotion/css';
import { Empty } from 'antd';
import { theme } from 'theme';

import { usePurchasingStatuses } from '../utils';

export function PurchaseAutoTargetFillTrimEmptyView({
  targetValue,
  currentValue,
}: {
  targetValue: number;
  currentValue: number;
}) {
  const { shouldCreateSpecialOrderLines } = usePurchasingStatuses();
  let detailMessage = '';
  let additionalMessage = '';
  if (targetValue === currentValue) {
    detailMessage = 'Purchase Order is already at target.';
  } else if (targetValue > currentValue) {
    detailMessage = 'No more items recommended to add to order.';
  } else {
    detailMessage = 'All items are at their minimum levels.';
  }

  if (shouldCreateSpecialOrderLines) {
    additionalMessage = `Special order items are not affected by auto ${targetValue > currentValue ? 'fill' : 'trim'}.`;
  }

  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        <div
          className={css`
            color: ${theme.colors.neutral[600]};
          `}
        >
          No recommendations available.
          <br />
          {detailMessage}
          <br />
          {additionalMessage}
        </div>
      }
    />
  );
}
