import React from 'react';

import { Link } from 'react-router-dom';

import { DashboardOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { TransferOrderStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { TransferOrderDTO } from '@recurrency/core-api-schema/dist/transferOrders/common';
import { ColumnType } from 'antd/lib/table';

import { ButtonLink } from 'components/Links';
import { SearchFrame, ValueFacet } from 'components/recipes/SearchFrame';
import { BadgeStatus, StatusBadge } from 'components/recipes/StatusBadge';

import { truthy } from 'utils/boolean';
import { formatDateShorthand } from 'utils/formatting';
import { routes } from 'utils/routes';
import { asKeyOf, sortableDollarWithCentsColumn, sortableNumberColumn } from 'utils/tables';

export const TransferStatusBadge = {
  [TransferOrderStatus.Open]: BadgeStatus.Open,
  [TransferOrderStatus.Shipped]: BadgeStatus.Shipped,
  [TransferOrderStatus.Completed]: BadgeStatus.Completed,
};

export const TransferOrderListPage = () => {
  const columns: (ColumnType<TransferOrderDTO> | null)[] = [
    {
      title: 'Transfer No',
      dataIndex: asKeyOf<TransferOrderDTO>('transferOrderNumber'),
      render: (id: string) => <Link to={routes.purchasing.transferOrderDetails(id)}>{id}</Link>,
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: asKeyOf<TransferOrderDTO>('status'),
      render: (status: TransferOrderStatus) => <StatusBadge status={TransferStatusBadge[status]} />,
      sorter: true,
    },
    {
      title: 'Source Location',
      dataIndex: asKeyOf<TransferOrderDTO>('sourceLocationName'),
      render: (_, record) => (
        <span>
          {record.sourceLocationId}: {record.sourceLocationName}
        </span>
      ),
      sorter: true,
    },
    {
      title: 'Destination Location',
      dataIndex: asKeyOf<TransferOrderDTO>('destinationLocationName'),
      render: (_, record) => (
        <span>
          {record.destinationLocationId}: {record.destinationLocationName}
        </span>
      ),
      sorter: true,
    },
    {
      title: 'Transfer Date',
      dataIndex: asKeyOf<TransferOrderDTO>('transferDate'),
      render: (transferDate: string) => formatDateShorthand(transferDate),
      sorter: true,
    },
    sortableDollarWithCentsColumn({
      title: 'Total Cost',
      dataIndex: asKeyOf<TransferOrderDTO>('totalCost'),
      sorter: true,
    }),
    sortableNumberColumn({
      title: 'Line Count',
      dataIndex: asKeyOf<TransferOrderDTO>('lineCount'),
      sorter: true,
    }),
    {
      title: '',
      dataIndex: asKeyOf<TransferOrderDTO>('transferOrderNumber'),
      render: (id: string) => ButtonLink(routes.purchasing.transferOrderDetails(id)),
    },
  ];

  const valueFacets: ValueFacet<TransferOrderDTO>[] = [
    {
      title: 'Status',
      field: asKeyOf<TransferOrderDTO>('status'),
      icon: <DashboardOutlined />,
      labelFormatFn: (status: string) => status.charAt(0).toUpperCase() + status.slice(1),
    },
    {
      title: 'Source Location',
      field: asKeyOf<TransferOrderDTO>('sourceLocationName'),
      queryPlaceholder: 'Search locations',
      icon: <EnvironmentOutlined />,
    },
    {
      title: 'Destination Location',
      field: asKeyOf<TransferOrderDTO>('destinationLocationName'),
      queryPlaceholder: 'Search locations',
      icon: <EnvironmentOutlined />,
    },
  ];

  return (
    <SearchFrame<TransferOrderDTO>
      title="Transfer Orders"
      indexName={SearchIndexName.TransferOrders}
      queryPlaceholder="Search recent transfer orders"
      columns={columns.filter(truthy)}
      valueFacets={valueFacets.filter(truthy)}
    />
  );
};
