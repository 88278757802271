import React, { forwardRef } from 'react';

import { EditOutlined } from '@ant-design/icons';

import { ActionButton } from 'components/Button/ActionButton';

import { showAsyncModal } from 'utils/asyncModal';
import { formatDate } from 'utils/formatting';

import { EditSyncDatesModal, changeSyncDateType } from './EditSyncDatesModal';

interface EditableDateCellProps {
  dateType: changeSyncDateType;
  tenantId: string; // Id of the tenant
  tableName: string; // the Target Table, which corresponds to table_name in PG's data_last_sync_info
  dbType: string; // type of database (SF or PG)
  initialDate: string; // current last modified date
  onDataChanged: () => void; // callback to reload the table status list
}

export const EditableDateCell = forwardRef<HTMLDivElement, EditableDateCellProps>(
  ({ dateType, tenantId, tableName, dbType, initialDate, onDataChanged, ...props }, ref) => {
    const now = new Date();

    //  * = more than one day in delay, only show for "Synced To"
    const displayedValue = (() => {
      if (dateType === 'Modified') {
        const isDelayed = (now.getTime() - new Date(initialDate).getTime()) / 1000 > 3600 * 24;
        return isDelayed ? `*${initialDate}` : initialDate;
      }
      return initialDate;
    })();

    return (
      <div {...props} ref={ref}>
        {formatDate(displayedValue, true)}
        <ActionButton
          label={<EditOutlined />}
          onClick={async () => {
            showAsyncModal(EditSyncDatesModal, {
              SyncDatesData: {
                dateType,
                initialDate,
                tenantId,
                tableName,
                dbType,
                onDataChanged,
              },
            });
          }}
        />
      </div>
    );
  },
);
