import React from 'react';

import { Link } from 'react-router-dom';

import {
  CheckOutlined,
  DeleteOutlined,
  DownOutlined,
  LineChartOutlined,
  LoadingOutlined,
  PlusOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { css, cx } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import {
  RecommendedQuoteDTO,
  RecommendedQuoteLineDTO,
  WorkflowState,
} from '@recurrency/core-api-schema/dist/ml/getRecommendedQuotes';
import Empty from 'antd/lib/empty';
import Menu from 'antd/lib/menu';
import moment from 'moment';
import { colors } from 'theme/colors';
import { fontWeights } from 'theme/typography';

import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';
import { PropertyListItem } from 'components/PropertyListItem';

import { useCoreApi } from 'hooks/useApi';
import { getGlobalAppState } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { joinIdNameObj, capitalize } from 'utils/formatting';
import { shouldHideCostAndGm, shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';
import { RecommendedQuoteItemCardAction, track, TrackEvent } from 'utils/track';

import { CloseWonOrderChooserModal } from './CloseWonOrderChooserModal';
import { ReasonChooserModal } from './ReasonChooserModal';
import { SalesHistoryModal } from './SalesHistoryModal';
import { getAvailabilityText, getRecLastOrderText, getRecReasonText } from './utils';

export function RecItemCards({
  selectedRecQuote,
  selectedWfState,
  onLineItemWorkflowStateChange,
  shouldShowWfActions,
  lineItems,
  selectedLineItems,
  onSelectedLineItemsChange,
}: {
  selectedRecQuote: RecommendedQuoteDTO;
  selectedWfState: WorkflowState;
  onLineItemWorkflowStateChange: (
    lineItem: RecommendedQuoteLineDTO,
    workflowState: WorkflowState,
    reason?: string,
  ) => void;
  shouldShowWfActions: boolean;
  lineItems: RecommendedQuoteLineDTO[];
  selectedLineItems: RecommendedQuoteLineDTO[];
  onSelectedLineItemsChange: (newLineItems: RecommendedQuoteLineDTO[]) => void;
}) {
  return (
    <>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          gap: 16px;
        `}
      >
        {lineItems.length > 0 ? (
          lineItems.map((lineItem, lineItemIdx) => (
            <RecItemCard
              key={lineItemIdx}
              lineItem={lineItem}
              selectedRecQuote={selectedRecQuote}
              selectedWfState={selectedWfState}
              shouldShowWfActions={shouldShowWfActions}
              onLineItemWorkflowStateChange={onLineItemWorkflowStateChange}
              selectedLineItems={selectedLineItems}
              onSelectedLineItemsChange={onSelectedLineItemsChange}
            />
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No opportunites to show." />
        )}
      </div>
    </>
  );
}

export function RecItemCard({
  lineItem,
  selectedRecQuote,
  shouldShowWfActions,
  selectedWfState,
  onLineItemWorkflowStateChange,
  selectedLineItems,
  onSelectedLineItemsChange,
}: {
  lineItem: RecommendedQuoteLineDTO;
  selectedRecQuote: RecommendedQuoteDTO;
  shouldShowWfActions: boolean;
  selectedWfState: WorkflowState;
  onLineItemWorkflowStateChange: (
    lineItem: RecommendedQuoteLineDTO,
    workflowState: WorkflowState,
    reason?: string,
  ) => void;
  selectedLineItems: RecommendedQuoteLineDTO[];
  onSelectedLineItemsChange: (newLineItems: RecommendedQuoteLineDTO[]) => void;
}) {
  const { activeTenant, activeUser } = getGlobalAppState();
  const shouldShowGM = !shouldHideCostAndGm(activeTenant, activeUser);
  const { data: itemAvailabilityData, isLoading: itemAvailabilityIsLoading } = useCoreApi(
    schemas.items.getItemAvailability,
    {
      pathParams: { itemUid: lineItem.itemUid },
      queryParams: { fromCacheOnly: true },
    },
  );

  return (
    <div
      className={cx(
        'RecItemCard',
        css`
          border: 1px solid ${colors.neutral[300]};
          border-radius: 8px;
          overflow: hidden;
        `,
      )}
    >
      <div
        className={css`
          padding: 12px 16px;
          background-color: ${colors.neutral[50]};
          font-weight: ${fontWeights.bold};
          border-bottom: 1px solid ${colors.neutral[300]};
        `}
      >
        <Link to={routes.sales.itemDetails(lineItem.itemId)}>{lineItem.itemId}</Link>
        <span>: {lineItem.itemName}</span>
      </div>
      <div
        className={css`
          padding: 16px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        `}
      >
        <PropertyListItem
          label="Recommendation"
          value={
            <span>
              {getRecLastOrderText(lineItem, shouldShowGM)} {capitalize(moment(lineItem.lastOrderDate).fromNow(true))}{' '}
              since{' '}
              {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SalesReordersFromInvoiceData) ? (
                'last invoice.'
              ) : (
                <a target="_blank" href={routes.orders.orderDetails(lineItem.lastOrderId)} rel="noreferrer">
                  last order.
                </a>
              )}
            </span>
          }
        />
        <PropertyListItem label="Reason" value={getRecReasonText(lineItem)} />
        <PropertyListItem
          label="Availability"
          value={
            itemAvailabilityIsLoading ? (
              <LoadingOutlined style={{ color: colors.neutral[400] }} />
            ) : itemAvailabilityData ? (
              getAvailabilityText(itemAvailabilityData.items, lineItem.lastLocationId, lineItem.itemUid)
            ) : null
          }
        />
        <RecItemCardActions
          selectedRecQuote={selectedRecQuote}
          lineItem={lineItem}
          shouldShowWfActions={shouldShowWfActions}
          selectedWfState={selectedWfState}
          onLineItemWorkflowStateChange={onLineItemWorkflowStateChange}
          selectedLineItems={selectedLineItems}
          onSelectedLineItemsChange={onSelectedLineItemsChange}
        />
      </div>
    </div>
  );
}

function RecItemCardActions({
  selectedRecQuote,
  lineItem,
  shouldShowWfActions,
  selectedWfState,
  onLineItemWorkflowStateChange,
  selectedLineItems,
  onSelectedLineItemsChange,
}: {
  selectedRecQuote: RecommendedQuoteDTO;
  selectedWfState: WorkflowState;
  shouldShowWfActions: boolean;
  onLineItemWorkflowStateChange: (
    lineItem: RecommendedQuoteLineDTO,
    workflowState: WorkflowState,
    reason?: string,
  ) => void;
  lineItem: RecommendedQuoteLineDTO;
  selectedLineItems: RecommendedQuoteLineDTO[];
  onSelectedLineItemsChange: (newLineItems: RecommendedQuoteLineDTO[]) => void;
}) {
  const isLineItemSelected = selectedLineItems.includes(lineItem);

  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        gap: 8px;
      `}
    >
      {selectedWfState === WorkflowState.Open ? (
        <>
          <Button
            size="small"
            icon={isLineItemSelected ? <CheckOutlined /> : <PlusOutlined />}
            type={isLineItemSelected ? 'primary' : 'default'}
            onClick={() => {
              const newSelectedLineItems = [...selectedLineItems];
              if (isLineItemSelected) {
                newSelectedLineItems.splice(selectedLineItems.indexOf(lineItem), 1);
              } else {
                newSelectedLineItems.push(lineItem);
              }
              onSelectedLineItemsChange(newSelectedLineItems);
            }}
          >
            Select
          </Button>
          {shouldShowWfActions ? (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={async () => {
                      const orderId = await showAsyncModal(CloseWonOrderChooserModal, {
                        title: `Close as Won for Item ${lineItem.itemId}: ${lineItem.itemName}`,
                        customerId: selectedRecQuote.customerId,
                      });
                      if (orderId) {
                        onLineItemWorkflowStateChange(lineItem, WorkflowState.Won, orderId);
                      }
                    }}
                  >
                    Close as Won
                  </Menu.Item>
                  <Menu.Item
                    onClick={async () => {
                      const reason = await showAsyncModal(ReasonChooserModal, {
                        title: `Close as Lost for Item ${lineItem.itemId}: ${lineItem.itemName}`,
                        reasons: [
                          'Lost due to pricing',
                          'Lost to competitor',
                          'Lost due to stock availability',
                          'Other',
                        ],
                      });
                      if (reason) {
                        onLineItemWorkflowStateChange(lineItem, WorkflowState.Lost, reason);
                      }
                    }}
                  >
                    Close as Lost
                  </Menu.Item>
                </Menu>
              }
            >
              <Button size="small">
                Close <DownOutlined />
              </Button>
            </Dropdown>
          ) : null}
        </>
      ) : null}
      {selectedWfState === WorkflowState.Lost ? (
        <>
          <Button
            size="small"
            icon={<UndoOutlined />}
            onClick={() => onLineItemWorkflowStateChange(lineItem, WorkflowState.Open)}
          >
            Reopen
          </Button>
        </>
      ) : null}
      {selectedWfState === WorkflowState.Rejected ? (
        <>
          <Button
            size="small"
            icon={<UndoOutlined />}
            onClick={() => onLineItemWorkflowStateChange(lineItem, WorkflowState.NeedsReview)}
          >
            Reopen
          </Button>
        </>
      ) : null}
      <Button
        size="small"
        icon={<LineChartOutlined />}
        onClick={async () => {
          await showAsyncModal(SalesHistoryModal, {
            customerId: selectedRecQuote.customerId,
            shipToId: selectedRecQuote.shipToId,
            itemId: lineItem.itemId,
            itemName: lineItem.itemName,
          });
          track(TrackEvent.RecommendedQuotes_ItemCard_Action, {
            action: RecommendedQuoteItemCardAction.OpenHistoryModal,
            shipTo: joinIdNameObj({
              foreignId: selectedRecQuote.shipToId,
              name: selectedRecQuote.shipToName || '',
            }),
            item: joinIdNameObj({
              foreignId: lineItem.itemId,
              name: lineItem.itemName,
            }),
          });
        }}
      >
        History
      </Button>
      {selectedWfState === WorkflowState.Open && shouldShowWfActions ? (
        <>
          <Button
            size="small"
            icon={<DeleteOutlined />}
            danger
            onClick={() => onLineItemWorkflowStateChange(lineItem, WorkflowState.Rejected)}
            className={css`
              margin-left: auto;
            `}
          >
            Remove
          </Button>
        </>
      ) : null}
    </div>
  );
}
