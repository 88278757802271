import React from 'react';

import { css } from '@emotion/css';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { SearchForecastDTO } from '@recurrency/core-api-schema/dist/search/getSearchForecasts';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import moment from 'moment';

import { FORECAST_COLOR, USAGE_COLOR } from 'pages/purchasing/utils';

import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { DEFAULT_NUM_ROWS_SHOWN, MiniTable } from 'components/MiniTable';
import {
  getUsageTableRowsFromForecastRecord,
  InventoryUsageForecastView,
} from 'components/recipes/InventoryUsageForecastView';
import { ViewAllModal } from 'components/recipes/sidePane/ViewAllModal';
import { Sparkline } from 'components/Sparkline';
import { getMonthlyFilledSeries } from 'components/Sparkline/utils';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';
import { usePromise } from 'hooks/usePromise';

import { showAsyncModal } from 'utils/asyncModal';
import { captureError } from 'utils/error';
import { formatIdNameObj } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { searchIndex } from 'utils/search/search';

import { IdNameObj } from 'types/legacy-api';

import { getAncestorItemCodes, getMergedForecastRecords } from './sidePane.utils';
import { UsageInheritanceInfo } from './UsageInheritanceInfo';

export function InventoryUsagePanelContent({
  itemId,
  itemUid,
  locations,
  purchaseGroupName,
  onForecastOverridesUpdated,
}: {
  itemId: string;
  itemUid: string;
  locations: IdNameObj[];
  purchaseGroupName?: string;
  onForecastOverridesUpdated?: () => void;
}) {
  const { activeTenant, activeUser } = useGlobalApp();
  const showUsageInheritance = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.FeatureItemUsageInheritance,
  );

  const forecastRecordIds = locations.map((l) => `${itemUid}|${l.foreignId}`);
  const {
    data: searchIndexResponse,
    isLoading,
    error,
    reload: reloadForecastRecord,
  } = usePromise(
    () =>
      searchIndex<SearchForecastDTO>({
        indexName: SearchIndexName.Forecasts,
        filters: { id: forecastRecordIds },
      }),
    [itemId, locations],
    {
      cacheKey: `searchframe/${SearchIndexName.Forecasts}:${forecastRecordIds.join(',')}`,
      onError: captureError,
    },
  );

  const forecastRecords = searchIndexResponse?.hits;
  const forecastRecord = getMergedForecastRecords(forecastRecords);
  const usageTableRows = getUsageTableRowsFromForecastRecord(forecastRecord);
  const ancestorData = getAncestorItemCodes(forecastRecords);

  return (
    <>
      {forecastRecord && usageTableRows.length > 0 && !isLoading ? (
        <div>
          {showUsageInheritance && (
            <div
              className={css`
                margin-bottom: 16px;
              `}
            >
              <UsageInheritanceInfo processedAncestorData={ancestorData ?? []} itemUid={itemUid} itemId={itemId} />
            </div>
          )}
          <Sparkline
            className={css`
              margin-bottom: 12px;
            `}
            height={32}
            series={[
              ...getMonthlyFilledSeries({
                data: usageTableRows,
                dateField: 'date',
                valueField: 'usage',
                numPrevYears: 2,
                // usage data is calculated at end of month, so current month is always 0, we show forecast instead
                curMonthOffset: -1,
                title: 'Qty Used',
                formatFn: (value: number) => `${value} ${forecastRecord.unit_of_measure}`,
                color: USAGE_COLOR,
              }),
              {
                data: forecastRecord.forecast_dates.slice(0, 3 /* 3 month forecast */).map((_, i) => ({
                  date: forecastRecord.forecast_dates[i],
                  value: forecastRecord.forecast_demand[i] ?? 0,
                  dotted: true,
                })),
                title: 'Qty Forecasted',
                formatFn: (value: number) => `${value} ${forecastRecord.unit_of_measure}`,
                color: FORECAST_COLOR,
              },
            ]}
          />
        </div>
      ) : null}
      <MiniTable
        columns={[
          { render: (record) => moment(record.date).format('YYYY MMMM') },
          { render: (record) => `${record.usage} ${record.unitOfMeasure || ``}`, align: 'right' },
        ]}
        data={usageTableRows}
        isLoading={isLoading}
        error={error}
        numRowsShown={DEFAULT_NUM_ROWS_SHOWN}
        onMoreButtonClick={() => {
          showAsyncModal(UsageForecastModal, {
            itemId,
            itemUid,
            locations,
            purchaseGroupName,
            forecastRecords,
            isLoading,
            onForecastOverridesUpdated: () => {
              reloadForecastRecord();
              onForecastOverridesUpdated?.();
            },
          });
        }}
      />
    </>
  );
}

function UsageForecastModal({
  itemId,
  itemUid,
  locations,
  purchaseGroupName,
  forecastRecords,
  isLoading,
  onClose,
  onForecastOverridesUpdated,
}: {
  itemId: string;
  itemUid: string;
  locations: IdNameObj[];
  purchaseGroupName?: string;
  forecastRecords: SearchForecastDTO[] | undefined;
  isLoading: boolean;
  onClose: () => void;
  onForecastOverridesUpdated?: () => void;
}) {
  return ViewAllModal({
    title: (
      <ConditionalWrapper
        condition={!!purchaseGroupName}
        wrapper={(children) => (
          <InfoTooltip useInfoIcon title={[locations?.map((sl, i) => <div key={i}>{sl.name}</div>)]}>
            {children}
          </InfoTooltip>
        )}
      >
        <>
          Usage & Forecast for Item #{itemId} at Location {purchaseGroupName ?? formatIdNameObj(locations[0])}
        </>
      </ConditionalWrapper>
    ),
    content: (
      <InventoryUsageForecastView
        forecastRecords={forecastRecords}
        itemUid={itemUid}
        locations={locations}
        isLoading={isLoading}
        onForecastOverridesUpdated={() => {
          onForecastOverridesUpdated?.();
        }}
      />
    ),
    onClose,
  });
}
