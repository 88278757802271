import { SupplierTargetTypes } from '@recurrency/core-api-schema/dist/common/enums';

import { EventProps, TrackEvent } from 'utils/track';

import { GenericAutoTrimFillTableRow, GenericTargetLineRow } from '../../types';
import { TargetFieldSumMap, calculateFieldQtyTotal, getFieldTotal } from '../../utils';
import { additionalPurchasingQty } from '../utils';

export function calculateDaysOfSupply(line: GenericTargetLineRow): number | null {
  if (line.dailyDemand == null || line.dailyDemand === 0) {
    return null;
  }
  const dailyDemand = line.unitSize > 0 ? line.dailyDemand / line.unitSize : line.dailyDemand;
  // take the PO's current order qty into account when projecting days of supply
  if (line.netStock + line.userQtyToOrder === 0) {
    return 0;
  }
  return Math.min(Math.floor((line.netStock + line.userQtyToOrder) / dailyDemand), 365 * 5);
}

function getAutoTrimFillRow(line: GenericTargetLineRow): GenericAutoTrimFillTableRow {
  return {
    ...line,
    currentQtyToOrder: line.userQtyToOrder,
    daysOfSupply: calculateDaysOfSupply(line) ?? line.daysOfSupply,
    projectedDaysOfSupply: calculateDaysOfSupply(line),
  };
}

export function getAutoFillTrimTrackProps(
  recommendedLines: GenericAutoTrimFillTableRow[],
): EventProps[TrackEvent.Purchasing_AutoFillTrimModal_Open] {
  // Calculate number of line items where recommended qty equals order qty
  const numAcceptedLines = recommendedLines.filter((line) => line.userQtyToOrder === line.recommendedQtyToOrder).length;
  return { numAcceptedLines };
}

export function getAutoTrimLines(
  allLines: GenericTargetLineRow[],
  currentValue: number,
  targetValue: number,
  targetType: SupplierTargetTypes,
): GenericAutoTrimFillTableRow[] {
  let lines = allLines.filter((line) => line.userQtyToOrder > 0).map((line) => getAutoTrimFillRow(line));

  // do not trim any line whose projected net stock is at min
  let otherLines = lines.filter(
    (line) =>
      line.userQtyToOrder + line.netStock <= line.invMin ||
      line.userQtyToOrder === 0 ||
      !hasUnitValue(line, targetType),
  );
  let potentialLines = lines.filter(
    (line) =>
      line.userQtyToOrder > 0 && line.userQtyToOrder + line.netStock > line.invMin && hasUnitValue(line, targetType),
  );
  let recommendValue = currentValue;
  while (recommendValue > targetValue && potentialLines.length > 0) {
    // sort by daysOfSupply descending
    potentialLines.sort((a, b) => (b.projectedDaysOfSupply ?? 0) - (a.projectedDaysOfSupply ?? 0));
    const line = potentialLines[0];
    const decrementAmount =
      line.qtyToOrderAlignUnitOfMeasure && line.qtyToOrderAlignUnitSize ? line.qtyToOrderAlignUnitSize : 1;
    line.userQtyToOrder = Math.max(0, line.userQtyToOrder - decrementAmount);
    line.recommendedQtyToOrder = line.userQtyToOrder;
    line.projectedDaysOfSupply = calculateDaysOfSupply(line);

    // recalculates which lines are above min to reduce next
    lines = [...otherLines, ...potentialLines];
    potentialLines = lines.filter(
      (pLine) => pLine.userQtyToOrder > 0 && pLine.userQtyToOrder + pLine.netStock > pLine.invMin,
    );
    otherLines = lines.filter(
      (oLine) => oLine.userQtyToOrder + oLine.netStock <= oLine.invMin || oLine.userQtyToOrder === 0,
    );
    recommendValue = getFieldTotal(lines, TargetFieldSumMap[targetType]);
  }
  return lines.filter((line) => line.recommendedQtyToOrder != null);
}

// check that the unit value for the target type (e.g unit cost, unit weight etc) has
// non-zero value
function hasUnitValue(line: GenericAutoTrimFillTableRow, targetType: SupplierTargetTypes): boolean {
  const field = TargetFieldSumMap[targetType];
  return field === 'qtyToOrder' ? true : parseFloat(String(line[field])) > 0;
}

export function getAutoFillLines(
  allLines: GenericTargetLineRow[],
  currentValue: number,
  targetValue: number,
  targetType: SupplierTargetTypes,
): GenericAutoTrimFillTableRow[] {
  const trimFillLines = allLines.map((line) => getAutoTrimFillRow(line));
  const potentialLines = trimFillLines.filter(
    (line) => line.stockable && line.projectedDaysOfSupply != null && hasUnitValue(line, targetType),
  );
  // the lines user currently have that's not in a candidate for filling
  const nonPotentialLinesInPO = trimFillLines.filter(
    (line) => line.userQtyToOrder > 0 && line.projectedDaysOfSupply == null && !hasUnitValue(line, targetType),
  );

  const poLinesValue = getFieldTotal(nonPotentialLinesInPO, TargetFieldSumMap[targetType]);
  let recommendValue = currentValue;
  while (recommendValue < targetValue && potentialLines.length > 0) {
    // sort by daysOfSupply ascending
    potentialLines.sort((a, b) => (a.projectedDaysOfSupply ?? 0) - (b.projectedDaysOfSupply ?? 0));
    // modify the line with the smallest daysOfSupply
    const line = potentialLines[0];
    const incrementAmount =
      line.qtyToOrderAlignUnitOfMeasure && line.qtyToOrderAlignUnitSize ? line.qtyToOrderAlignUnitSize : 1;
    line.userQtyToOrder += incrementAmount;
    line.recommendedQtyToOrder = line.userQtyToOrder;
    line.projectedDaysOfSupply = calculateDaysOfSupply(line);

    recommendValue = poLinesValue + getFieldTotal(trimFillLines, TargetFieldSumMap[targetType]);
  }

  return potentialLines.filter((line) => (line.recommendedQtyToOrder ?? 0) > 0);
}

/**
 * Calculates the recommended total value for a set of purchase order lines after auto-fill/trim adjustments
 *
 * @param selectedLines - Array of purchase order lines that have been modified by auto-fill/trim
 * @param currentValue - Current total value of all purchase order lines (including special orders)
 * @param targetType - Type of target being calculated (e.g., cost, weight, quantity)
 * @param totalQtyForSpecialOrders - Total quantity of special order items
 *
 * @returns The recommended total value after applying auto-fill/trim adjustments
 */
export function calculateRecommendedValue(
  selectedLines: GenericAutoTrimFillTableRow[],
  currentValue: number,
  targetType: SupplierTargetTypes,
) {
  const linesTotalValue = selectedLines.reduce(
    (total: number, line) =>
      // total values for currentQtyToOrder and additionalPurchasingQty are already part of currentValue
      calculateFieldQtyTotal(
        line,
        TargetFieldSumMap[targetType],
        line.userQtyToOrder - line.currentQtyToOrder - additionalPurchasingQty(line),
      ) + total,
    0,
  );

  return linesTotalValue + currentValue;
}

/**
 * Calculates the current total cost of the purchase order including special if the setting is enabled.
 *
 * @param allLines - Array of purchase order lines
 * @returns The current total cost of the purchase order including special orders
 */
export function getTrimFillCurrentPOCost(allLines: GenericTargetLineRow[]) {
  const linesWithQuantity = allLines.map((line) => ({
    ...line,
    totalQtyOnPurchasingLine: line.userQtyToOrder + additionalPurchasingQty(line),
  }));

  return getFieldTotal(
    linesWithQuantity,
    TargetFieldSumMap[SupplierTargetTypes.Dollars],
    'totalQtyOnPurchasingLine' as keyof GenericTargetLineRow,
  );
}
