import React, { useState, useMemo, useCallback } from 'react';

import { CaretDownFilled } from '@ant-design/icons';
import { Tree as AntTree } from 'antd';
import type { DataNode, TreeProps as AntDesignTreeProps } from 'antd/es/tree';
import { fuzzyFilter } from 'fuzzbunny';
import { useDebounce } from 'use-debounce';

import { Button } from './Button';

interface TreeProps {
  searchQuery?: string;
  treeData: DataNode[];
  setTreeData: (newTreeData: DataNode[]) => void;
  className?: string;
  defaultExpandAll?: boolean;
  showExpandCollapseButton?: boolean;
  draggable?: boolean;
}

const Tree: React.FC<TreeProps> = ({
  treeData,
  searchQuery = '',
  setTreeData,
  defaultExpandAll = false,
  showExpandCollapseButton = true,
  draggable = true,
  ...props
}) => {
  const memoizedKeys = useMemo(() => {
    const keys: (string | number)[] = [];

    const traverse = (nodes: DataNode[]) => {
      nodes.forEach((node) => {
        keys.push(node.key);
        if (node.children) {
          traverse(node.children);
        }
      });
    };

    traverse(treeData);
    return keys;
  }, [treeData]);

  const [expandedKeys, setExpandedKeys] = useState<(string | number)[]>(defaultExpandAll ? memoizedKeys : []);

  function findNodeAndExecute(
    data: DataNode[],
    key: React.Key,
    callback: (node: DataNode, index: number, arr: DataNode[]) => void,
  ) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].key === key) {
        callback(data[i], i, data);
        return;
      }
      if (data[i].children) {
        findNodeAndExecute(data[i].children!, key, callback);
      }
    }
  }

  const onDrop: AntDesignTreeProps['onDrop'] = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPosition = info.dropPosition - parseInt(info.node.pos.split('-').pop()!, 10);

    const data = [...treeData];
    let dragObj: DataNode;

    findNodeAndExecute(data, dragKey, (node, index, arr) => {
      arr.splice(index, 1);
      dragObj = node;
    });

    if (!info.dropToGap || ((info.node.children || []).length > 0 && info.node.expanded && dropPosition === 1)) {
      findNodeAndExecute(data, dropKey, (node) => {
        node.children = node.children || [];
        node.children.unshift(dragObj);
      });
    } else {
      findNodeAndExecute(data, dropKey, (_, index, arr) => {
        arr.splice(dropPosition === -1 ? index : index + 1, 0, dragObj);
      });
    }

    setTreeData(data);
  };

  function expandOrCollapseAllKeys() {
    if (expandedKeys.length > 0) {
      setExpandedKeys([]);
      return;
    }
    setExpandedKeys(memoizedKeys);
  }

  const getFlattenedTree = useCallback(() => {
    const defaultPath: DataNode[] = [];

    function flatten(item: DataNode) {
      const { children, ...rest } = item;
      defaultPath.push({ ...rest, children: [] });
      if (children && children.length > 0) children.forEach(flatten);
    }

    treeData.forEach(flatten);
    return defaultPath;
  }, [treeData]);

  const filterTree = useCallback(
    (nodes: DataNode[], filteredIds: string[]): DataNode[] =>
      nodes
        .map((node) => {
          if (node.children) {
            node.children = filterTree(node.children, filteredIds);
          }
          return node;
        })
        .filter((node) => filteredIds.includes(String(node.key)) || (node.children && node.children.length > 0)),
    [],
  );

  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const memoizedTreeData = useMemo(() => {
    let filteredTreeData = treeData;

    if (treeData && searchQuery) {
      const flattenedLocationRuleTree = getFlattenedTree();
      const filteredKeys = fuzzyFilter(flattenedLocationRuleTree, debouncedSearchQuery, {
        fields: ['key'],
      })
        .map((result) => result.item)
        .map((n) => String(n.key));
      filteredTreeData = filterTree(treeData, filteredKeys);
    }

    return filteredTreeData;
  }, [debouncedSearchQuery, filterTree, getFlattenedTree, searchQuery, treeData]);

  return (
    <>
      {showExpandCollapseButton && (
        <Button type="link" onClick={expandOrCollapseAllKeys}>
          {expandedKeys.length > 0 ? 'Collapse' : 'Expand'} all
        </Button>
      )}
      <AntTree
        onExpand={setExpandedKeys}
        expandedKeys={expandedKeys}
        switcherIcon={<CaretDownFilled />}
        showLine={{ showLeafIcon: false }}
        draggable={draggable}
        blockNode
        onDrop={draggable ? onDrop : undefined}
        treeData={memoizedTreeData}
        selectable={false}
        {...props}
      />
    </>
  );
};

export { Tree };
