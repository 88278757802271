import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TargetLineStatusToSalesOrderLineType } from '@recurrency/core-api-schema/dist/common/enums';
import { LinkedSalesOrderLineDTO } from '@recurrency/core-api-schema/dist/v2/salesOrderLines/getLinkedSalesOrderLines';
import { ColumnType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';

import { Button } from 'components/Button';
import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { Modal } from 'components/Modal';
import { Table } from 'components/Table';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useCoreApi } from 'hooks/useApi';

import { optArrFromVal } from 'utils/array';
import { routes } from 'utils/routes';
import { asKeyOf, sortableDateColumn, sortableIdColumn, sortableNumberColumn } from 'utils/tables';

import { AllocateOrderModalProps, AllocationRowWithKey } from './types';
import { getInitialAllocationState, createAllocationPayload } from './utils';

export function AllocateOrderModal({ targetLine, onClose, customTitle }: AllocateOrderModalProps) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  // Reasoning behind not using pagination: https://github.com/recurrency/frontend/pull/4434#discussion_r1876778269
  const { data, isLoading } = useCoreApi(schemas.salesOrderLines.getLinkedSalesOrderLines, {
    queryParams: {
      filter: {
        locationId: targetLine.locationId,
        itemIds: [targetLine.itemId],
        lineType: targetLine.status as keyof typeof TargetLineStatusToSalesOrderLineType,
        companyIds: optArrFromVal(targetLine.companyId),
      },
      limit: 50,
      offset: 0,
    },
  });

  const itemsWithKeys: AllocationRowWithKey[] = useMemo(
    () =>
      data?.items.map((item) => ({
        ...item,
        key: `${item.orderNumber}-${item.lineNumber}`,
      })) || [],
    [data?.items],
  );

  const selectedQuantitySum = useMemo(
    () =>
      itemsWithKeys
        .filter((item) => selectedRowKeys.includes(item.key))
        .reduce((acc, item) => acc + item.linkQuantity, 0),
    [selectedRowKeys, itemsWithKeys],
  );

  const title = useMemo(
    () => (
      <ConditionalWrapper
        condition
        wrapper={(children: React.ReactElement) => (
          <InfoTooltip title="Combine special orders with stock PO">{children}</InfoTooltip>
        )}
      >
        {/* Fragment is here for typing */}
        <>
          {customTitle ||
            `Special Orders for Item #${targetLine.itemId} at ${targetLine.locationId}: (${targetLine.locationName})`}
        </>
      </ConditionalWrapper>
    ),
    [customTitle, targetLine.itemId, targetLine.locationId, targetLine.locationName],
  );

  // Ref is used to ensure we don't overwrite the previous selection when the api call is complete
  const hasLoadedInitialSelections = useRef(false);
  useEffect(() => {
    // Only want to this to run once, used to select rows
    if (!hasLoadedInitialSelections.current && itemsWithKeys?.length > 0) {
      setSelectedRowKeys(getInitialAllocationState(targetLine, itemsWithKeys));
      hasLoadedInitialSelections.current = true;
    }
  }, [itemsWithKeys, targetLine]);

  // Row selection logic

  const rowSelection: TableRowSelection<LinkedSalesOrderLineDTO> = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys as string[]),
    preserveSelectedRowKeys: true,
  };

  return (
    <Modal
      onCancel={() => onClose({ changesMade: false })}
      footer={
        <>
          <Button key="cancel" onClick={() => onClose({ changesMade: false })}>
            Cancel
          </Button>
          <Button
            key="save"
            type="primary"
            htmlType="submit"
            onClick={() =>
              onClose(createAllocationPayload(itemsWithKeys, selectedRowKeys, targetLine, selectedQuantitySum))
            }
          >
            Save
          </Button>
        </>
      }
      visible
      title={title}
      centered
      width="85%"
      bodyStyle={{ height: '85%' }}
      className={css`
        max-width: 1020px;
        min-width: 400px;
      `}
    >
      <div
        className={css`
          margin: 0 0 14px 10px;
          display: flex;
          flex-direction: row;
          font-weight: 500;
        `}
      >
        Selected Quantity Sum:{' '}
        <span
          className={css`
            font-weight: bolder;
            margin-left: 3px;
          `}
        >
          {selectedQuantitySum}
        </span>
      </div>
      <Table
        isLoading={isLoading}
        columns={tableColumns}
        data={itemsWithKeys}
        rowSelection={rowSelection}
        rowKey="key"
      />
    </Modal>
  );
}

const tableColumns: ColumnType<LinkedSalesOrderLineDTO>[] = [
  sortableIdColumn({
    title: 'Order No',
    dataIndex: asKeyOf<LinkedSalesOrderLineDTO>('orderNumber'),
    key: 'orderNumber',
    render: (orderNumber) => (
      <Link to={routes.orders.orderDetails(orderNumber)} target="_blank" rel="noreferrer">
        {orderNumber}
      </Link>
    ),
  }),
  sortableIdColumn({
    title: 'Customer',
    dataIndex: asKeyOf<LinkedSalesOrderLineDTO>('customerId'),
    key: 'customerId',
    render: (customerId, record) => (
      <Link to={routes.sales.customerDetails(customerId)} target="_blank" rel="noreferrer">
        {customerId}: {record.customerName}
      </Link>
    ),
  }),
  sortableNumberColumn({
    title: 'Quantity',
    dataIndex: asKeyOf<LinkedSalesOrderLineDTO>('linkQuantity'),
    key: 'linkQuantity',
  }),
  sortableDateColumn({
    title: 'Order Date',
    dataIndex: asKeyOf<LinkedSalesOrderLineDTO>('orderDate'),
    key: 'orderDate',
  }),
];
