import React from 'react';

import ErraticDemand from 'assets/svgs/erratic-demand.svg';
import NewDemand from 'assets/svgs/new-demand.svg';
import NoDemand from 'assets/svgs/no-demand.svg';
import SmoothDemand from 'assets/svgs/smooth-demand.svg';
import SparseDemand from 'assets/svgs/sparse-demand.svg';
import SporadicDemand from 'assets/svgs/sporadic-demand.svg';
import UnstableDemand from 'assets/svgs/unstable-demand.svg';

import { Tooltip } from './Tooltip';

export enum DemandPattern {
  NEW_ITEM = 'New Item',
  NO_DEMAND = 'No Demand',
  SPARSE = 'Sparse',
  SMOOTH = 'Smooth',
  SPORADIC = 'Sporadic',
  ERRATIC = 'Erratic',
  UNSTABLE = 'Unstable',
}

const demandPatternIconMapping = {
  [DemandPattern.NEW_ITEM]: <NewDemand style={{ verticalAlign: 'middle' }} />,
  [DemandPattern.NO_DEMAND]: <NoDemand style={{ verticalAlign: 'middle' }} />,
  [DemandPattern.SPARSE]: <SparseDemand style={{ verticalAlign: 'middle' }} />,
  [DemandPattern.SMOOTH]: <SmoothDemand style={{ verticalAlign: 'middle' }} />,
  [DemandPattern.SPORADIC]: <SporadicDemand style={{ verticalAlign: 'middle' }} />,
  [DemandPattern.ERRATIC]: <ErraticDemand style={{ verticalAlign: 'middle' }} />,
  [DemandPattern.UNSTABLE]: <UnstableDemand style={{ verticalAlign: 'middle' }} />,
};

const demandPatternTooltipMapping = {
  [DemandPattern.NEW_ITEM]: 'Item first had usage fewer than 6 months ago',
  [DemandPattern.NO_DEMAND]: 'Item has no demand over the last 12 months',
  [DemandPattern.SPARSE]: 'Item has demand in 6 or fewer of the last 12 months',
  [DemandPattern.SMOOTH]: 'Small changes in quantity ordered, regular time between orders',
  [DemandPattern.SPORADIC]: 'Small changes in quantity ordered, irregular time between orders',
  [DemandPattern.ERRATIC]: 'Large changes in quantity ordered, regular time between orders',
  [DemandPattern.UNSTABLE]: 'Large changes in quantity ordered, irregular time between orders',
};

export type Props = {
  demandPattern: DemandPattern;
};

export const DemandPatternTooltip = (props: Props) => (
  <Tooltip title={demandPatternTooltipMapping[props.demandPattern]}>
    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
      <span style={{ verticalAlign: 'middle' }}>{props.demandPattern}</span>
      <span style={{ verticalAlign: 'middle', marginLeft: '5px' }}>
        {demandPatternIconMapping[props.demandPattern]}
      </span>
    </span>
  </Tooltip>
);
