import React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { GetReplenishmentRulesLocationDTO } from '@recurrency/core-api-schema/dist/replenishmentRules/getReplenishmentRules';

import { ViewReplenishmentPathsTree } from 'pages/planning/ReplenishmentPathsPage/ViewReplenishmentPathsTree';

import { Alert } from 'components/Alert';
import { MiniTableMessage, MiniTableSkeleton } from 'components/MiniTable';
import { ViewAllModal } from 'components/recipes/sidePane/ViewAllModal';

import { useCoreApi } from 'hooks/useApi';

import { showAsyncModal } from 'utils/asyncModal';

import { buildTreeWithDownstream } from './utils';

interface ReplenishmentPathsPanelContentProps {
  itemUid: string;
  itemId: string;
  locationId: string;
}

export function ReplenishmentPathsPanelContent({ itemUid, itemId, locationId }: ReplenishmentPathsPanelContentProps) {
  const { data: replenishmentPathData, isLoading } = useCoreApi(schemas.itemLocations.getFullReplenishmentPath, {
    queryParams: {
      itemId: itemUid,
      locationId,
    },
  });

  if (isLoading) {
    return <MiniTableSkeleton />;
  }

  // Transform data for tree view
  const treeData: GetReplenishmentRulesLocationDTO[] = replenishmentPathData?.upstreamTree
    ? [buildTreeWithDownstream(replenishmentPathData.upstreamTree, replenishmentPathData)]
    : [];

  const viewModal = () => {
    const isDesktopView = window.innerWidth > 1024;
    const responsiveModalWidth = isDesktopView ? '50vw' : '95vw';

    showAsyncModal(ViewAllModal, {
      title: `Replenishment Path for Item #${itemId}`,
      extraStyles: css`
        .ant-modal-content {
          width: ${responsiveModalWidth};
          margin: 0 auto;
        }
      `,
      content: (
        <div>
          <Alert
            icon={<InfoCircleOutlined />}
            message="The full replenishment path for this item shows the upstream locations it replenishes from and the downstream locations it supplies."
            type="info"
            banner
          />
          <ViewReplenishmentPathsTree
            replenishmentPathTree={treeData}
            requirementLocationId={locationId}
            defaultExpandAll
          />
        </div>
      ),
    });
  };
  if (treeData.length === 0) {
    return <MiniTableMessage message="No replenishment path found." />;
  }
  return (
    <ViewReplenishmentPathsTree
      replenishmentPathTree={treeData}
      requirementLocationId={locationId}
      defaultExpandAll
      showExpandCollapseButton={false}
      onViewAll={viewModal}
      truncate
    />
  );
}
